import useSaveTableViews, {
	type SavedTable,
} from "@metronome/api/useSaveTableViews";
import { useUpdateUserPreferences } from "@metronome/api/useUserPreference";
import useWorkspaceViews from "@metronome/api/useWorkspaceViews";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "@metronome/components/ui/select";
import { useActiveViewData } from "@metronome/context/ActiveViewData";
import type { ViewType } from "@metronome/types/UserPreference";
import { useCallback } from "react";
import { useIntl } from "react-intl";

type SelectCustomViewProps = {
	preferenceKey: string;
};

export const SelectCustomView: React.FC<SelectCustomViewProps> = ({
	preferenceKey,
}) => {
	const intl = useIntl();
	const { activeView, setActiveView } = useActiveViewData();
	const { data: savedViews } = useSaveTableViews(preferenceKey);
	const { data: workspaceViews } = useWorkspaceViews(preferenceKey);
	const { mutate: mutateUserPreferences } =
		useUpdateUserPreferences(preferenceKey);
	const onActiveViewChange = useCallback(
		(data: SavedTable | undefined, isWorkspaceWideView: boolean) => {
			const viewType: ViewType = isWorkspaceWideView
				? "workspaceView"
				: "userView";

			if (data?.id && viewType) {
				setActiveView({
					id: data.id,
					type: viewType,
				});
				mutateUserPreferences({
					data: { lastSavedViewType: viewType, lastSavedViewSelected: data.id },
				});
			} else {
				setActiveView(undefined);
				mutateUserPreferences({
					data: {
						lastSavedViewType: undefined,
						lastSavedViewSelected: undefined,
					},
				});
			}
		},
		[setActiveView, mutateUserPreferences],
	);

	const selectedValue = activeView?.id
		? `${activeView?.type}-${activeView?.id}`
		: "unselect";

	if (!savedViews?.length && !workspaceViews?.length) {
		return null;
	}
	return (
		<Select
			value={selectedValue}
			onValueChange={(value: string) => {
				if (value === undefined) {
					return;
				}
				if (value === "unselect") {
					onActiveViewChange(undefined, true);
					return;
				}
				const s = value.split("-");
				if (Array.isArray(s) && s.length === 2) {
					let selectedView: SavedTable | undefined;
					if (s.length && s[0] === "userView") {
						selectedView = savedViews?.find((view) => view.id === s[1]);
						onActiveViewChange(selectedView, false);
					} else if (s.length && s[0] === "workspaceView") {
						selectedView = workspaceViews?.find((view) => view.id === s[1]);
						onActiveViewChange(selectedView, true);
					}
				} else {
					onActiveViewChange(undefined, true);
				}
			}}
		>
			<SelectTrigger className="w-[inherit]">
				<SelectValue
					placeholder={intl.formatMessage({
						id: "NO_VIEW_SELECTED",
					})}
				/>
			</SelectTrigger>
			<SelectContent>
				<SelectItem value={"unselect"}>
					{intl.formatMessage({
						id: activeView?.id ? "UNSELECT_VIEW" : "NO_VIEW_SELECTED",
					})}
				</SelectItem>
				{!!savedViews?.length && (
					<SelectGroup>
						<SelectLabel>
							{intl.formatMessage({ id: "USER_VIEWS" })}
						</SelectLabel>
						{savedViews
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((view) => (
								<SelectItem value={`userView-${view.id}`} key={view.id}>
									{view.name}
								</SelectItem>
							))}
					</SelectGroup>
				)}
				{!!workspaceViews?.length && (
					<SelectGroup>
						<SelectLabel>
							{intl.formatMessage({ id: "COMPANY_WIDE_VIEWS" })}
						</SelectLabel>
						{workspaceViews
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((view) => (
								<SelectItem value={`workspaceView-${view.id}`} key={view.id}>
									{view.name}
								</SelectItem>
							))}
					</SelectGroup>
				)}
			</SelectContent>
		</Select>
	);
};
