import { useIntl } from "react-intl";
import { z } from "zod";

const NOT_STARTED = "notStarted";
const IN_PROGRESS = "inProgress";
const ON_HOLD = "onHold";
const DONE = "done";
const CANCELLED = "cancelled";

export const EResolution = z.enum([
	NOT_STARTED,
	IN_PROGRESS,
	ON_HOLD,
	DONE,
	CANCELLED,
]);

export type EResolution = z.infer<typeof EResolution>;

export const EEventResolution = z.enum([
	NOT_STARTED,
	IN_PROGRESS,
	DONE,
	CANCELLED,
]);

export type EEventResolution = z.infer<typeof EEventResolution>;

export const EMilestoneResolution = z.enum([NOT_STARTED, DONE, CANCELLED]);
export type EMilestoneResolution = z.infer<typeof EMilestoneResolution>;

export const ResolutionParam = z.object({
	resolution: EResolution,
	stepInstanceId: z.string().min(1),
	processInstanceId: z.string().min(1),
	isForced: z.boolean().optional(),
});

export type ResolutionParam = z.infer<typeof ResolutionParam>;

export function useTranslatedStatusResolution() {
	const intl = useIntl();
	return EResolution.options.map((status) => {
		switch (status) {
			case EResolution.enum.notStarted:
				return {
					name: intl.formatList(
						[
							intl.formatMessage({ id: `RESOLUTION.event.${status}` }),
							intl.formatMessage({ id: `RESOLUTION.task.${status}` }),
							intl.formatMessage({ id: `RESOLUTION.milestone.${status}` }),
						],
						{
							type: "disjunction",
						},
					),
					id: status,
				};
			case EResolution.enum.cancelled:
			case EResolution.enum.onHold:
			case EResolution.enum.inProgress:
				return {
					name: intl.formatList([
						intl.formatMessage({ id: `RESOLUTION.${status}` }),
					]),
					id: status,
				};

			case EResolution.enum.done:
				return {
					name: intl.formatList(
						[
							intl.formatMessage({ id: `RESOLUTION.${status}` }),
							intl.formatMessage({ id: `RESOLUTION.milestone.${status}` }),
						],
						{
							type: "disjunction",
						},
					),
					id: status,
				};
		}
	});
}
