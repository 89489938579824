import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles, { APPEARANCES } from "@metronome/constants/theme";

import sectionStyles from "./sectionMessage.module.scss";

type Props = {
	children: React.ReactNode;
	appearance?: APPEARANCES;
	title?: string;
	links?: JSX.Element[];
	onClose?: () => void;
};

export const SectionMessage: React.FC<Props> = ({
	children,
	appearance = APPEARANCES.default,
	title,
	links,
	onClose,
}): JSX.Element => (
	<div style={styles[appearance]} className={sectionStyles.container}>
		<div className={sectionStyles.iconContainer}>
			<FontAwesomeIcon icon={["fas", "circle-info"]} />
		</div>
		<div className={sectionStyles.content}>
			<span className={sectionStyles.title}>{title}</span>
			<div>{children}</div>
			{!!links?.length && <div className={sectionStyles.links}>{links}</div>}
		</div>
		{!!onClose && (
			<button
				onClick={onClose}
				type="button"
				className={sectionStyles.closeIconButton}
			>
				<FontAwesomeIcon icon={["fas", "times"]} />
			</button>
		)}
	</div>
);

export default SectionMessage;
