import clsx from "clsx";
import type React from "react";

import { DateTimePicker } from "../ui/dateTimePickerInput";
import styles from "./ColumnList.module.scss";

export interface IList {
	title: string | JSX.Element;
	value?: number | string | JSX.Element;
	dateValue?: Date;
	id: string;
	editable?: boolean;
	inputType?: string;
	isLoading?: boolean;
	tag?: JSX.Element;
	onValidate?: (value?: Date) => void;
	showTime?: boolean;
}

export const ColumnList: React.FC<{
	list: IList[];
}> = ({ list }) => {
	const row1 = list
		.filter((item) => item.value)
		.map((item) => {
			const textClass = typeof item.value === "string" ? "truncate" : "";
			return (
				<div className="w-full flex flex-col" key={`column-${item.id}`}>
					<div className="flex flex-row gap-2 items-center min-w-125px">
						<span
							className={styles.title}
							title={typeof item.title === "string" ? item.title : ""}
						>
							{item.title}
						</span>
						<div>{item.tag}</div>
					</div>
					<div
						title={typeof item.value === "string" ? item.value : ""}
						className={clsx(
							"font-medium text-base overflow-visible",
							textClass,
							styles.dateWrapper,
						)}
					>
						{item.dateValue ? (
							<DateTimePicker
								key={item.id}
								initialDate={item.dateValue}
								onValidate={item.onValidate}
							/>
						) : (
							<span>{item.value}</span>
						)}
					</div>
				</div>
			);
		});

	return (
		<div className="column-list flex align-items-strech flex-wrap gap-4">
			{row1}
		</div>
	);
};
