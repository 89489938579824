import React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import styles from "./avatar.module.scss";

type AvatarProps = {
	src?: string;
	alt?: string;
	size?: number;
	isLogo?: boolean;
};

export const Avatar = React.forwardRef<HTMLSpanElement, AvatarProps>(
	({ src, alt, size = 32, isLogo = false }, forwardRef) => (
		<AvatarPrimitive.Root
			ref={forwardRef}
			style={{ width: size, height: size, minWidth: size, minHeight: size }}
			className={styles.rootStyle}
		>
			<AvatarPrimitive.Image
				className={isLogo ? styles.logoStyle : styles.imageStyle}
				src={src}
				alt={alt ?? "N/A"}
			/>
			<AvatarPrimitive.Fallback delayMs={600} className={styles.fallbackStyle}>
				{alt ?? "N/A"}
			</AvatarPrimitive.Fallback>
		</AvatarPrimitive.Root>
	),
);

Avatar.displayName = "Avatar";

