import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { apiGet } from "./api";
import type { ICluster } from "@metronome/types/Clusters";

const clustersOptions = (
	workspaceId: string,
	streamId: string,
	from: string,
	countNext = 3,
	countPrevious = 1,
) =>
	queryOptions({
		queryKey: [
			workspaceId,
			streamId,
			"clusters",
			from,
			countNext,
			countPrevious,
		],
		queryFn: () =>
			apiGet<ICluster[]>(
				`/ws/${workspaceId}/process-streams/${streamId}/clusters`,
				{
					params: {
						from,
						countNext,
						countPrevious,
					},
				},
			),
	});

export function useClusters(
	streamId: string,
	from?: string,
	countNext?: number,
	countPrevious?: number,
) {
	const workspaceId = useWorkspaceId();
	const today = new Date().toISOString().slice(0, 10);

	if (!workspaceId) {
		throw new Error("useClusters: workspaceId is not defined");
	}
	return useQuery(
		clustersOptions(
			workspaceId,
			streamId,
			from ?? today,
			countNext,
			countPrevious,
		),
	);
}
