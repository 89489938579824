import { useSingleProcessInstance } from "@metronome/api/useProcessInstance";
import { useUpdateResolution } from "@metronome/api/useStepInstance";
import { ConfirmationModal } from "@metronome/components/ConfirmationModal";
import { EResolution } from "@metronome/types/Resolution";
import clsx from "clsx";
import { useState } from "react";
import { useIntl } from "react-intl";

type Props = {
	resolution: EResolution;
	processInstanceId: string;
	stepInstanceId: string;
};

export const ToggleResolution: React.FC<Props> = ({
	resolution,
	processInstanceId,
	stepInstanceId,
}) => {
	const intl = useIntl();
	const { data: processInstance } = useSingleProcessInstance(processInstanceId);
	const { mutateAsync: updateResolution, isPending } = useUpdateResolution();
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [selectedAction, setSelectedAction] = useState<EResolution | undefined>(
		resolution,
	);

	const onActionSelected = async (done: boolean): Promise<void> => {
		const action = done ? EResolution.enum.done : EResolution.enum.notStarted;
		setSelectedAction(action);
		try {
			await updateResolution({
				resolution: action,
				isForced: false,
				processInstanceId,
				stepInstanceId,
			});
		} catch (e) {
			setShowConfirmation(true);
		}
	};

	const handleConfirmation = (): void => {
		updateResolution({
			resolution: selectedAction as EResolution,
			isForced: true,
			processInstanceId,
			stepInstanceId,
		});
		setShowConfirmation(false);
	};

	return (
		<>
			<ConfirmationModal
				loading={isPending}
				show={showConfirmation}
				onClose={() => setShowConfirmation(false)}
				onConfirm={handleConfirmation}
				message={intl.formatMessage(
					{ id: "RESOLUTION.CONFIRM_MESSAGE" },
					{
						resolution: intl.formatMessage({
							id: `RESOLUTION.${selectedAction}`,
						}),
					},
				)}
			/>
			<input
				className={clsx({
					"absolute left-2": true,
					"invisible group-hover/instance:visible":
						resolution !== EResolution.enum.done,
				})}
				type="checkbox"
				onChange={(event) => {
					onActionSelected(event.target.checked);
				}}
				disabled={processInstance?.state === "closed"}
				onClick={(event) => {
					event.stopPropagation();
					event.preventDefault();
				}}
				checked={resolution === EResolution.enum.done}
			/>
		</>
	);
};
