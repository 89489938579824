import {
	keepPreviousData,
	queryOptions,
	useInfiniteQuery,
	useQuery,
} from "@tanstack/react-query";
import { apiGet } from "./api";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { IParty, PartyAvailability } from "@metronome/types/Party";
import { toISOStringWithTimezone } from "@metronome/utils/dateHelpers";
import type { ICursorPaginatedResults } from "@metronome/types/PaginatedResponse";

export const partyOptions = (workspaceId: string, partyId: string) =>
	queryOptions({
		queryKey: [workspaceId, "party", partyId],
		queryFn: () => apiGet<IParty>(`ws/${workspaceId}/parties/${partyId}`),
	});

export function useParty(partyId: string) {
	const workspaceId = useWorkspaceId();
	return useQuery(partyOptions(workspaceId, partyId));
}

export const partyAssignmentsOptions = (
	workspaceId: string,
	partyId: string,
	atFormatted: string | undefined,
) =>
	queryOptions({
		queryKey: [workspaceId, "party", partyId, "assignments"],
		queryFn: () =>
			apiGet<unknown>(`ws/${workspaceId}/parties/${partyId}/assignments`, {
				params: {
					at: atFormatted,
				},
			}),
	});

export function usePartyAssignments(partyId: string, at?: Date) {
	const workspaceId = useWorkspaceId();
	const today = new Date(2024, 10, 25);
	let atFormatted: string | undefined;

	try {
		atFormatted = toISOStringWithTimezone(at ?? today).slice(0, -1);
	} catch (e) {
		atFormatted = undefined;
	}
	return useQuery(partyAssignmentsOptions(workspaceId, partyId, atFormatted));
}

export const partyAvailabilityOptions = (
	workspaceId: string,
	partyId: string,
	atFormatted?: string,
) =>
	queryOptions({
		queryKey: [workspaceId, "party-availability", partyId],
		queryFn: () =>
			apiGet<PartyAvailability>(
				`ws/${workspaceId}/parties/${partyId}/availability`,
				{
					params: {
						at: atFormatted,
					},
				},
			),
	});

export function usePartyAvailability(partyId: string, at?: Date) {
	const workspaceId = useWorkspaceId();
	const today = new Date();
	let atFormatted: string | undefined;

	try {
		atFormatted = toISOStringWithTimezone(at ?? today).slice(0, -1);
	} catch (e) {
		atFormatted = undefined;
	}
	return useQuery(partyAvailabilityOptions(workspaceId, partyId, atFormatted));
}

export function useGetAvailablePartiesInfinite(
	roleId: string,
	stepInstanceId: string,
	search?: string,
) {
	const workspaceId = useWorkspaceId();
	return useInfiniteQuery({
		queryKey: [workspaceId, "available-parties", roleId],
		queryFn: () =>
			apiGet<ICursorPaginatedResults<IParty>>(
				`ws/${workspaceId}/step-instances/${stepInstanceId}/roles/${roleId}/available-parties`,
				{
					search,
				},
			),
		initialPageParam: undefined,
		getNextPageParam: (lastPage) => lastPage?.nextCursor,
		placeholderData: keepPreviousData,
	});
}
