import { useMetadataDefinitions } from "@metronome/api/useMetadataDefinitions";
import useSaveTableViews from "@metronome/api/useSaveTableViews";
import useWorkspaceViews from "@metronome/api/useWorkspaceViews";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { useActiveViewData } from "@metronome/context/ActiveViewData";
import type {
	ProcessContext,
	ProcessState,
	Time,
} from "@metronome/types/ProcessInstance";
import type React from "react";
import { ProcessInstancesTab } from "./ProcessInstancesTab";
import type { UserPreference } from "@metronome/types/UserPreference";
import { useGetStepInstancesFilters } from "@metronome/api/useStepInstance";

type Props = {
	context: ProcessContext;
	processStreamId: string;
	states: ProcessState[];
	times?: Time[];
	preferences?: UserPreference;
	preferenceKey: string;
};

export const UserProcessConfigLoader: React.FC<Props> = ({
	context,
	states,
	times,
	processStreamId,
	preferences,
	preferenceKey,
}) => {
	const { activeView } = useActiveViewData();

	const { data: savedViews } = useSaveTableViews(preferenceKey);
	const { data: workspaceViews } = useWorkspaceViews(preferenceKey);
	const { data: metadataDefinitions, isFetched } = useMetadataDefinitions({
		processStreamId,
	});
	// we're using the stepInstancesFilters in processes because Labels are common to both
	// endpoint should probably be refactored to be more generic.
	const { data: filters } = useGetStepInstancesFilters();

	if (isFetched) {
		return (
			<ProcessInstancesTab
				processStreamId={processStreamId}
				key={preferenceKey + activeView?.id}
				states={states}
				times={times}
				context={context}
				preferenceKey={preferenceKey}
				preferences={preferences}
				metadataDefinitions={metadataDefinitions}
				savedViews={savedViews}
				workspaceViews={workspaceViews}
				labelsFilter={filters?.labels ?? []}
			/>
		);
	}
	return <LoadingMetronome />;
};

