import { StepInstanceIcon } from "@metronome/components/IconStepInstance";
import { Button } from "@metronome/components/ui/button";
import { DatePicker } from "@metronome/components/ui/datePicker";
import { CustomItemSchedule } from "@metronome/features/UpdateStepInstanceSchedule";
import type { IStepInstance } from "@metronome/types/StepInstance";
import { toISOStringWithTimezone } from "@metronome/utils/dateHelpers";
import { formatDurationToString } from "@metronome/utils/durationHelpers";
import { calcTillDate } from "@metronome/utils/planby";
import type { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

type StepSummaryInfoProps = {
	stepInstance: IStepInstance;
};

export const StepSummaryInfo: FC<StepSummaryInfoProps> = ({
	stepInstance,
}): JSX.Element => {
	const intl = useIntl();

	const { schedule } = stepInstance;

	return (
		<>
			<div className="inline-flex flex-col items-start gap-4">
				<div className="flex flex-row gap-2 items-center">
					<StepInstanceIcon type={stepInstance.type} />
					{intl.formatMessage({
						id: `STEP_INSTANCE.TYPE.${stepInstance.type}`,
					})}
				</div>
				<div>
					<div className="uppercase font-bold text-xs">
						<FormattedMessage id="DATE" />
					</div>
					<DatePicker
						initialDate={
							new Date(schedule.plannedAt ?? schedule.scheduleLowerBand)
						}
					/>
				</div>
				<div>
					<div className="uppercase font-bold text-xs">
						<FormattedMessage id="TIME" />
					</div>
					<CustomItemSchedule
						stepId={stepInstance.id}
						since={schedule.plannedAt ?? schedule.scheduleLowerBand}
						till={calcTillDate(schedule)}
						schedule={schedule}
						childrenAsChild
					>
						<Button variant={"outline"}>
							{toISOStringWithTimezone(
								new Date(schedule.plannedAt ?? schedule.scheduleLowerBand),
							).slice(11, 16)}
						</Button>
					</CustomItemSchedule>
				</div>
				<div>
					<div className="uppercase font-bold text-xs">
						<FormattedMessage id="DURATION" />
					</div>
					{formatDurationToString(schedule.estimatedDuration) ||
						formatDurationToString(schedule?.plannedDuration)}
				</div>
			</div>
		</>
	);
};
