import type { FC } from "react";
import { useDebounceValue } from "usehooks-ts";
import { Popover, PopoverTrigger, PopoverContent } from "./ui/popover";

type HoverPopover = {
	children: React.ReactNode;
	header: React.ReactNode;
};
const HoverPopover: FC<HoverPopover> = ({ children, header }) => {
	const [open, setOpen] = useDebounceValue(false, 500);

	const handleMouseEnter = () => {
		setOpen(true);
	};

	const handleMouseLeave = () => {
		setOpen(false);
	};

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{header}
			</PopoverTrigger>
			<PopoverContent
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{children}
			</PopoverContent>
		</Popover>
	);
};

export default HoverPopover;