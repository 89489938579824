import { z } from "zod";
import { IModelReference } from "./ModelReference";
import { IParty } from "@metronome/types/Party";

export type Assignment = { partyId: string; roleId: string };

export interface StepInstanceAssignment {
	assignments: Array<Assignment>;
}

export const PartyAssignment = z.object({
	id: z.string(),
	origin: z.enum(["undefined", "dispatch", "planning", "regulation"]),
	status: z.enum(["undefined", "acknowledged", "waitlist", "rejected"]),
	ownershipLevel: z.enum(["process", "step"]),
	party: IParty,
});

export type PartyAssignment = z.infer<typeof PartyAssignment>;

export const RoleAndPartyAssignments = z.object({
	role: IModelReference,
	targetCount: z.number(),
	definitionLevel: z.enum(["process", "step"]),
	applicationLevel: z.enum(["process", "step"]),
	assignments: z.array(PartyAssignment),
});
export type RoleAndPartyAssignments = z.infer<typeof RoleAndPartyAssignments>;

export const ArrayOfRoleAndPartyAssignments = z.array(RoleAndPartyAssignments);

export type ArrayOfRoleAndPartyAssignments = z.infer<
	typeof ArrayOfRoleAndPartyAssignments
>;
