import React from "react";
import type { IPagination } from "@metronome/types/PaginatedResponse";
import {
	Pagination,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationButton,
} from "@metronome/components/ui/pagination";

// Returns the page buttons as an array depending on the current page and the total number of pages
const getPagesModel = (page: number, totalPages: number): number[] => {
	const modelSet = new Set([1, page, totalPages]);
	if (page > 1) {
		modelSet.add(page - 1);
	}

	if (page < totalPages) {
		modelSet.add(page + 1);
	}

	if (page - 2 === 2) {
		modelSet.add(page - 2);
	}

	if (page + 2 === totalPages - 1) {
		modelSet.add(page + 2);
	}

	return Array.from(modelSet).sort((a, b) => a - b);
};

export const PaginatedList: React.FC<{
	pagination: IPagination;
	totalPages?: number;
	children?: React.ReactNode;
	onPageChange: (pagination: IPagination) => void;
}> = ({ pagination, totalPages, onPageChange, children }) => {
	if (!totalPages || totalPages <= 1) {
		return <div>{children}</div>;
	}
	const handlePageClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		page: number,
	): void => {
		event.preventDefault();
		onPageChange({ ...pagination, page });
	};

	const model = getPagesModel(pagination.page, totalPages);
	const pageElements = model.map((element, index) => {
		let elipsis: React.ReactElement | null = null;
		if (index !== 0 && element - 1 !== model[index - 1]) {
			elipsis = <PaginationEllipsis />;
		}
		return (
			<React.Fragment key={`page-${element}`}>
				{elipsis}
				<PaginationItem>
					<PaginationButton onClick={(e) => handlePageClick(e, element)}>
						{element}
					</PaginationButton>
				</PaginationItem>
			</React.Fragment>
		);
	});

	return (
		<div>
			{children || undefined}
			<Pagination>
				<PaginationContent>{pageElements}</PaginationContent>
			</Pagination>
		</div>
	);
};
