import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./icon.module.scss";

type Props = { times: string };
export const XTimeIcon: React.FC<Props> = ({ times }) => (
	<div className="relative">
		<span className={styles.icon}>{times}</span>
		<FontAwesomeIcon icon={["far", "clock"]} />
	</div>
);
