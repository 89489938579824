import {
	ArrowRightIcon,
	Cross1Icon,
	GearIcon,
	GridIcon,
	InfoCircledIcon,
	ListBulletIcon,
	PlusIcon,
} from "@radix-ui/react-icons";
import clsx from "clsx";
import type React from "react";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, createFileRoute } from "@tanstack/react-router";
import { useLocalStorage } from "usehooks-ts";

import { processCategoriesOptions } from "@metronome/api/useProcessCategories";
import { useInfiniteProcessStreams } from "@metronome/api/useProcessStreamInstances";
import useWorkspaceViews, {
	useDeleteWorkspaceViews,
	workspaceViewsOptions,
} from "@metronome/api/useWorkspaceViews";
// @ts-ignore
import LogoTypePicture from "@metronome/assets/logotype.svg?react";
import {
	CollapsibleContent,
	CollapsibleRoot,
	CollapsibleTrigger,
} from "@metronome/components/Collapsible";
import FallbackView from "@metronome/components/FallbackView";
import { Lozenge } from "@metronome/components/Lozenge";
import { NameOrLogo } from "@metronome/components/NameOrLogo";
import { RecurrenceIcon } from "@metronome/components/RecurrenceIcon";
import { Button } from "@metronome/components/ui/button";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "@metronome/components/ui/card";
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuPortal,
	DropdownMenuRadioGroup,
	DropdownMenuRadioItem,
	DropdownMenuSeparator,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger,
	DropdownMenuTrigger,
} from "@metronome/components/ui/dropdown-menu";
import { useProcessCategoriesData } from "@metronome/context/ProcessCategoriesData";
import { CreateFilterStreams } from "@metronome/features/CreateFilterStreams";
import useSetCurrentOrganization from "@metronome/hooks/useSetCurrentOrganization";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { IModelReference } from "@metronome/types/ModelReference";
import type { IProcessStream } from "@metronome/types/ProcessStream";
import { ThirdApps } from "@metronome/types/ThirdApp";
import { groupBy } from "@metronome/utils/arrayUtils";
import { useQuery } from "@tanstack/react-query";
import { PageTitle } from "@metronome/context/PageData";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { isAxiosError } from "axios";

function stringToNumbers(s: string): number {
	return s.split("").reduce((a, b) => a + b.charCodeAt(0), 0);
}

const getIconColor = (s: string): string => {
	const hueMax = 360;
	const hue = stringToNumbers(s) % hueMax;
	return `hsl(${hue}, 30%, 60%)`;
};

const EMPTY_ARRAY: Array<never> = [];

type ViewType = "list" | "grid";

type ProcessStreamProps = {
	stream: IProcessStream;
	workspaceId: string;
	setSelectedStreams: React.Dispatch<React.SetStateAction<string[]>>;
	filterCreation?: boolean;
};

const NeedModeProcesses: React.FC = () => (
	<div className="relative mt-8 py-8 mb-12 px-2">
		<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 pt-4">
			<Card className="bg-white">
				<CardHeader>
					<CardTitle>
						<FormattedMessage id="NEED_MORE_PROCESSES" />
					</CardTitle>
				</CardHeader>
				<CardContent>
					<p>
						<FormattedMessage id="NEED_MORE_PROCESSES_DESC" />
					</p>
				</CardContent>
				<CardFooter className="flex flex-row justify-between items-center gap-1">
					<Button className="truncate" variant="secondary" asChild>
						<a
							href="https://www.process-metronome.com/new-use-cases"
							rel="noopener noreferrer"
							target="_blank"
						>
							<FormattedMessage id="VIEW_LIBRARY" />
						</a>
					</Button>
					<Button className="truncate">
						<a
							href="mailto:contact@process-metronome.com"
							rel="noopener noreferrer"
							target="_blank"
						>
							<FormattedMessage id="GET_IN_TOUCH" />
						</a>
					</Button>
				</CardFooter>
			</Card>
		</div>
		<div className="flex flex-col gap-2">
			<div className="flex bg-white flex-col rounded opacity-50">
				<div className="flex flex-row items-center ps-2 gap-2">
					<PlusIcon className="text-gray-400" />
					<div className="flex flex-row gap-2 items-center py-2 pe-2 w-full">
						<strong className="text-medium">
							<FormattedMessage id="DYNAMIC_CLEANING" />
						</strong>
						<div className="ms-auto">
							<Lozenge appearance="success">
								<FormattedMessage id="ACTIVE" />
							</Lozenge>
						</div>
					</div>
				</div>
			</div>
			<div className="flex bg-white flex-col rounded opacity-30">
				<div className="flex flex-row items-center ps-2 gap-2">
					<PlusIcon className="text-gray-400" />
					<div className="flex flex-row gap-2 items-center py-2 pe-2 w-full">
						<strong className="text-medium">
							<FormattedMessage id="DYNAMIC_PORT" />
						</strong>
						<div className="ms-auto">
							<Lozenge appearance="success">
								<FormattedMessage id="ACTIVE" />
							</Lozenge>
						</div>
					</div>
				</div>
			</div>
			<div className="flex bg-white flex-col rounded opacity-20">
				<div className="flex flex-row items-center ps-2 gap-2">
					<PlusIcon className="text-gray-400" />
					<div className="flex flex-row gap-2 items-center py-2 pe-2 w-full">
						<strong className="text-medium">
							<FormattedMessage id="OPTIMIZED_FLIGHTS" />
						</strong>
						<div className="ms-auto">
							<Lozenge appearance="success">
								<FormattedMessage id="ACTIVE" />
							</Lozenge>
						</div>
					</div>
				</div>
			</div>
			<div className="flex bg-white flex-col rounded opacity-10">
				<div className="flex flex-row items-center ps-2 gap-2">
					<PlusIcon className="text-gray-400" />
					<div className="flex flex-row gap-2 items-center py-2 pe-2 w-full">
						<strong className="text-medium">
							<FormattedMessage id="DYNAMIC_SCHEDULING" />
						</strong>
						<div className="ms-auto">
							<Lozenge appearance="success">
								<FormattedMessage id="ACTIVE" />
							</Lozenge>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

const ProcessStream: React.FC<ProcessStreamProps> = ({
	stream,
	filterCreation,
	setSelectedStreams,
	workspaceId,
}) => (
	<CollapsibleRoot asChild>
		<div className="relative">
			<input
				type="checkbox"
				onChange={(e) => {
					setSelectedStreams((prev) => {
						if (e.target.checked) {
							return [...prev, stream.id];
						}
						return prev.filter((id) => id !== stream.id);
					});
				}}
				className={`absolute -left-4 top-1/2 transform -translate-x-1/2 -translate-y-1/2 ${
					filterCreation ? "opacity-100" : "opacity-0"
				}`}
			/>
			<div className="flex flex-col w-full bg-white shadow rounded-sm">
				<div className="flex flex-row items-center">
					<CollapsibleTrigger className="p-2">
						<PlusIcon className="text-gray-400" />
					</CollapsibleTrigger>
					<Link
						to={
							stream.type === "routine"
								? "/$workspaceId/stream/$streamId/routine"
								: stream.type === "portfolio"
									? "/$workspaceId/stream/$streamId/portfolio"
									: "/$workspaceId/stream/$streamId/clustered"
						}
						params={{
							workspaceId,
							streamId: stream.id,
						}}
						className="w-full"
					>
						<div className="flex flex-row gap-2 items-center py-2 pe-2">
							<strong className="text-medium">{stream.name}</strong>
							<div className="ms-auto flex gap-2">
								{stream.origins
									?.filter((o) => o !== ThirdApps.Enum.Default)
									.map((o) => (
										<NameOrLogo key={o} name={o} />
									))}
								{stream.isActive ? (
									<Lozenge appearance="success">
										<FormattedMessage id="ACTIVE" />
									</Lozenge>
								) : (
									<Lozenge appearance="danger">
										<FormattedMessage id="INACTIVE" />
									</Lozenge>
								)}
							</div>
						</div>
					</Link>
				</div>
				<CollapsibleContent>
					<div className="flex flex-col gap-2 ps-8 py-2">
						<div className="flex flex-col">
							<span className="uppercase font-medium text-xs text-slate-600">
								<FormattedMessage id="TEMPLATE" />
							</span>
							<span className="font-semibold">
								{stream.currentProcessTemplateVersion?.name}
							</span>
						</div>
						{stream.currentProcessTemplateVersion?.description && (
							<div className="flex flex-col">
								<span className="uppercase font-medium text-xs text-slate-600">
									<FormattedMessage id="TEMPLATE_DESCRIPTION" />
								</span>
								<span className="font-semibold">
									{stream.currentProcessTemplateVersion.description}
								</span>
							</div>
						)}
						<div className="flex flex-col">
							<span className="uppercase font-medium text-xs text-slate-600">
								<FormattedMessage id="TYPE" />
							</span>
							<div className="flex flex-row gap-2 items-center ms-2">
								<RecurrenceIcon recurrence={stream.recurrence} />
								<span className="font-semibold">{stream.type}</span>
							</div>
						</div>
						<div className="flex flex-col">
							<span className="uppercase font-medium text-xs text-slate-600">
								<FormattedMessage id="TIMEZONE" />
							</span>
							<span className="font-semibold">{stream.timeZone}</span>
						</div>
					</div>
				</CollapsibleContent>
			</div>
		</div>
	</CollapsibleRoot>
);

const ProcessStreamGrid: React.FC<ProcessStreamProps> = ({
	stream,
	workspaceId,
}) => {
	const [showOverlay, setShowOverlay] = useState(false);

	return (
		<div className="relative overflow-hidden">
			<div className="flex flex-col bg-white group shadow relative rounded-sm">
				<Button
					onClick={() => setShowOverlay(!showOverlay)}
					className="absolute z-20 top-1 left-1 rounded-full opacity-0 group-hover:opacity-100"
					variant="outline"
					size="icon-sm"
				>
					<InfoCircledIcon />
				</Button>
				<span className="absolute z-10 top-1 right-1">
					{stream.isActive ? (
						<Lozenge appearance="success">
							<FormattedMessage id="ACTIVE" />
						</Lozenge>
					) : (
						<Lozenge appearance="danger">
							<FormattedMessage id="INACTIVE" />
						</Lozenge>
					)}
				</span>
				<Link
					to={
						stream.type === "routine"
							? "/$workspaceId/stream/$streamId/routine"
							: stream.type === "portfolio"
								? "/$workspaceId/stream/$streamId/portfolio"
								: "/$workspaceId/stream/$streamId/clustered"
					}
					params={{
						workspaceId,
						streamId: stream.id,
					}}
					className="w-full"
				>
					<div className="flex shadow-inner rounded-t-sm bg-primary h-[210px]">
						<img
							src={stream.pictureUri ?? LogoTypePicture}
							alt="process stream cover"
							className={
								stream.pictureUri
									? "w-full h-full object-cover object-center rounded-t-sm opacity-70"
									: "rounded-t-sm w-16 m-auto aspect-square"
							}
						/>
					</div>
				</Link>
				<div className="z-50 flex flex-col items-center bg-white">
					<Link
						to={
							stream.type === "routine"
								? "/$workspaceId/stream/$streamId/routine"
								: "/$workspaceId/stream/$streamId/portfolio"
						}
						params={{ workspaceId, streamId: stream.id }}
						className="w-full"
					>
						<div className="flex flex-row gap-2 justify-between items-center p-2">
							<strong className="text-medium line-clamp-1">
								{stream.name}
							</strong>
							{!!stream.origins?.length && (
								<div className="ms-auto flex gap-2">
									{stream.origins
										?.filter((o) => o !== ThirdApps.Enum.Default)
										.map((o) => (
											<NameOrLogo key={o} name={o} />
										))}
								</div>
							)}
							<Button variant="ghost" size="icon">
								<ArrowRightIcon />
							</Button>
						</div>
					</Link>
				</div>
			</div>
			<div
				className={clsx({
					"translate-y-72 transition-transform absolute inset-0 z-30": true,
					"!translate-y-0 ": showOverlay,
				})}
			>
				<div className="flex flex-col gap-2 p-2 z-10 h-full bg-white">
					<Button
						onClick={() => setShowOverlay(!showOverlay)}
						className="z-20 rounded-full"
						variant="outline"
						size="icon-sm"
					>
						<InfoCircledIcon />
					</Button>
					<div className="flex flex-col">
						<span className="uppercase font-medium text-xs text-slate-600">
							<FormattedMessage id="TEMPLATE" />
						</span>
						<span className="font-semibold">
							{stream.currentProcessTemplateVersion?.name}
						</span>
					</div>
					<div className="flex flex-col">
						<span className="uppercase font-medium text-xs text-slate-600">
							<FormattedMessage id="TYPE" />
						</span>
						<div className="flex flex-row gap-2 items-center ms-2">
							<RecurrenceIcon recurrence={stream.recurrence} />
							<span className="font-semibold">{stream.recurrence}</span>
						</div>
					</div>
					<div className="flex flex-col">
						<span className="uppercase font-medium text-xs text-slate-600">
							<FormattedMessage id="TIMEZONE" />
						</span>
						<span className="font-semibold">{stream.timeZone}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

type ViewProcessStreamProps = {
	processStreams: Array<IProcessStream>;
	filterActiveOnly: boolean;
	workspaceId: string;
	setSelectedStreams: React.Dispatch<React.SetStateAction<string[]>>;
	filterCreation?: boolean;
	customFilter?: string[];
};
const ListProcessStream: React.FC<ViewProcessStreamProps> = ({
	processStreams,
	filterActiveOnly,
	filterCreation,
	setSelectedStreams,
	workspaceId,
	customFilter,
}) => (
	<div className="flex flex-col gap-2">
		{processStreams.map((stream) => {
			if (customFilter?.length && !customFilter.includes(stream.id)) return;
			if (filterActiveOnly && !stream.isActive) return;
			return (
				<ProcessStream
					key={stream.id}
					workspaceId={workspaceId}
					stream={stream}
					filterCreation={filterCreation}
					setSelectedStreams={setSelectedStreams}
				/>
			);
		})}
		{!processStreams.length && (
			<span>
				<FormattedMessage id="NOTHING_HERE" />
			</span>
		)}
	</div>
);

const GridProcessStream: React.FC<ViewProcessStreamProps> = ({
	processStreams,
	filterActiveOnly,
	setSelectedStreams,
	workspaceId,
	customFilter,
}) => (
	<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
		{processStreams.map((stream) => {
			if (customFilter?.length && !customFilter.includes(stream.id)) return;
			if (filterActiveOnly && !stream.isActive) return;
			return (
				<ProcessStreamGrid
					key={stream.id}
					workspaceId={workspaceId}
					stream={stream}
					setSelectedStreams={setSelectedStreams}
				/>
			);
		})}
		{!processStreams.length && (
			<span>
				<FormattedMessage id="NOTHING_HERE" />
			</span>
		)}
	</div>
);

type ProcessCategoryOverviewProps = {
	processStreams: Array<IProcessStream>;
	filterActiveOnly: boolean;
	filterCreation?: boolean;
	setSelectedStreams: React.Dispatch<React.SetStateAction<string[]>>;
	viewType: ViewType;
	category?: IModelReference;
	customFilter?: string[];
};
const ProcessCategoryOverview: React.FC<ProcessCategoryOverviewProps> = ({
	processStreams,
	filterActiveOnly,
	filterCreation,
	setSelectedStreams,
	viewType,
	category,
	customFilter,
}) => {
	const workspaceId = useWorkspaceId();
	const categoryName = category?.name ?? "No category";

	return (
		<div key={categoryName}>
			<div className="flex items-center pb-2 ps-2.5 gap-2">
				<span
					style={{ backgroundColor: getIconColor(categoryName) }}
					className="h-3 w-3 rounded-full"
				/>
				<h4 className="font-medium text-lg">{categoryName}</h4>
			</div>
			{viewType === "list" ? (
				<ListProcessStream
					processStreams={processStreams}
					workspaceId={workspaceId}
					filterActiveOnly={filterActiveOnly}
					filterCreation={filterCreation}
					setSelectedStreams={setSelectedStreams}
					customFilter={customFilter}
				/>
			) : (
				<GridProcessStream
					processStreams={processStreams}
					workspaceId={workspaceId}
					setSelectedStreams={setSelectedStreams}
					filterActiveOnly={filterActiveOnly}
					customFilter={customFilter}
				/>
			)}
		</div>
	);
};

const activateGridToggle = true;
const preferenceKey = "process_streams_filters";

/**
 *
 * beware lots of props drilling here.
 * would probably benefits from a refactor to use Context
 * .filter() logic should be extracted and tested
 */
const ProcessesOverviewPage: React.FC = () => {
	useSetCurrentOrganization(undefined);

	const intl = useIntl();

	const { workspaceId } = Route.useParams();
	const { activeProcessCategories, setActiveProcessCategories } =
		useProcessCategoriesData();

	const { mutate: deleteFilterView } = useDeleteWorkspaceViews(preferenceKey);

	const { data: processCategories } = useQuery(
		processCategoriesOptions(workspaceId),
	);
	const { data: processStreams } = useInfiniteProcessStreams(
		activeProcessCategories,
		200,
	);
	const { data: streamsCustomFilter } = useWorkspaceViews(preferenceKey);

	const [selectedCustomFilter, setSelectedCustomFilter] = useState<string>();
	const [filterActiveOnly, setFilterActiveOnly] = useState(true);
	const [viewType, setViewType] = useLocalStorage<ViewType>(
		"streamViewType",
		"list",
	);
	const [filterCreation, setFilterCreation] = useState(false);
	const [filterDeletion, setFilterDeletion] = useState(false);
	const [selectedStreams, setSelectedStreams] = useState<string[]>([]);

	const resetFilterSelection = useCallback(() => {
		setSelectedStreams([]);
		setFilterCreation(false);
	}, []);

	const processStreamsPerCategories = groupBy(
		processStreams?.pages.flatMap((page) =>
			page?.results.sort((a, b) => {
				if (!a.category || !b.category) return 0;
				return a.category.name.localeCompare(b.category.name);
			}),
		) ?? [],
		(stream) => stream?.category?.id ?? "",
	);

	const emptyProcessCategories = processCategories?.filter(
		(category) =>
			Object.keys(processStreamsPerCategories).findIndex(
				(c) => category.id === c,
			) < 0,
	);

	const valuesSelect =
		processCategories?.filter((category) =>
			activeProcessCategories.includes(category.id),
		) ?? EMPTY_ARRAY;

	const processCategoriesWithStreams = Object.entries(
		processStreamsPerCategories,
	).filter((categoryWithStreams) => {
		if (selectedCustomFilter) {
			const streamIds = streamsCustomFilter?.find(
				(stream) => stream.id === selectedCustomFilter,
			)?.settings?.streamsIds;
			const atLeastOnStream = categoryWithStreams[1].some((stream) =>
				streamIds?.includes(stream?.id ?? ""),
			);
			return atLeastOnStream;
		}
		if (valuesSelect.length === 0) return true;
		// if category is not found findIndex returns -1
		return (
			valuesSelect.findIndex((value) => value.id === categoryWithStreams[0]) >=
			0
		);
	});

	const handleCategoryChange = useCallback(
		(isChecked: boolean, categoryId: string): void => {
			if (isChecked) {
				setActiveProcessCategories((prev) => [...prev, categoryId]);
			} else {
				setActiveProcessCategories((prev) =>
					prev.filter((cat) => cat !== categoryId),
				);
			}
		},
		[setActiveProcessCategories],
	);

	const handleSelectFilter = useCallback(
		(id: string) =>
			setSelectedCustomFilter(selectedCustomFilter === id ? undefined : id),
		[selectedCustomFilter],
	);

	const handleDeleteFilter = useCallback(
		(id: string) => deleteFilterView({ key: id }),
		[deleteFilterView],
	);

	if (processStreams) {
		return (
			<>
				<PageTitle>{intl.formatMessage({ id: "PLANNING" })}</PageTitle>
				<div className="flex flex-col gap-4 pt-4">
					<div className="m-auto relative flex flex-col gap-4 w-[clamp(320px,48vw,1000px)]">
						<div>
							<div className="flex flex-row gap-2 justify-between items-center">
								<h2 className="text-2xl font-medium">
									<FormattedMessage id="PLANNING" />
								</h2>
								<DropdownMenu>
									<DropdownMenuTrigger asChild>
										<Button className="relative" variant="secondary">
											<GearIcon className="mr-2 h-4 w-4" />
											<FormattedMessage id="DISPLAY" />
											{activeProcessCategories.length ? (
												<span className="absolute -top-2 -right-2 w-4 h-4 text-center text-xs shadow rounded-full bg-primary text-white">
													{activeProcessCategories.length}
												</span>
											) : null}
										</Button>
									</DropdownMenuTrigger>
									<DropdownMenuContent align="end">
										{!!activateGridToggle && (
											<>
												<DropdownMenuLabel>
													<FormattedMessage id="APPEARANCE" />
												</DropdownMenuLabel>
												<DropdownMenuRadioGroup
													value={viewType}
													onValueChange={(value) =>
														setViewType(value as ViewType)
													}
												>
													<DropdownMenuRadioItem value="list">
														<ListBulletIcon className="mr-2 h-4 w-4" />
														<FormattedMessage id="LIST" />
													</DropdownMenuRadioItem>
													<DropdownMenuRadioItem value="grid">
														<GridIcon className="mr-2 h-4 w-4" />
														<FormattedMessage id="GRID" />
													</DropdownMenuRadioItem>
												</DropdownMenuRadioGroup>
												<DropdownMenuSeparator />
											</>
										)}
										<DropdownMenuLabel>
											<FormattedMessage id="SHOW_ACTIVE_ONLY" />
										</DropdownMenuLabel>
										<DropdownMenuRadioGroup
											value={filterActiveOnly ? "yes" : "no"}
											onValueChange={(value: string) =>
												setFilterActiveOnly(value === "yes")
											}
										>
											<DropdownMenuRadioItem value="yes">
												<FormattedMessage id="YES" />
											</DropdownMenuRadioItem>
											<DropdownMenuRadioItem value="no">
												<FormattedMessage id="NO" />
											</DropdownMenuRadioItem>
										</DropdownMenuRadioGroup>
										<DropdownMenuSeparator />
										<DropdownMenuSub>
											<DropdownMenuSubTrigger>
												{activeProcessCategories.length ? (
													<span className="font-semibold text-primary">
														{activeProcessCategories.length}{" "}
														<FormattedMessage
															id={
																activeProcessCategories.length > 1
																	? "CATEGORIES_SELECTED"
																	: "CATEGORY_SELECTED"
															}
														/>
													</span>
												) : (
													<FormattedMessage id="FILTER_BY_PROCESS_CATEGORY" />
												)}
											</DropdownMenuSubTrigger>
											<DropdownMenuPortal>
												<DropdownMenuSubContent>
													{processCategories?.map((category) => (
														<DropdownMenuCheckboxItem
															key={category.id}
															checked={
																activeProcessCategories.indexOf(category.id) >=
																0
															}
															onCheckedChange={(isChecked: boolean) =>
																handleCategoryChange(isChecked, category.id)
															}
														>
															{category.name}
														</DropdownMenuCheckboxItem>
													))}
												</DropdownMenuSubContent>
											</DropdownMenuPortal>
										</DropdownMenuSub>
										<DropdownMenuItem
											onSelect={() => setFilterCreation(!filterCreation)}
										>
											<FormattedMessage id="CREATE_A_FILTER" />
										</DropdownMenuItem>
										{!!streamsCustomFilter?.length && (
											<DropdownMenuItem
												onSelect={() => setFilterDeletion(!filterCreation)}
											>
												<FormattedMessage id="DELETE_A_FILTER" />
											</DropdownMenuItem>
										)}
									</DropdownMenuContent>
								</DropdownMenu>
							</div>
							<span>
								<FormattedMessage id="PLANNING.SUBTITLE" />
							</span>
							<div className="flex items-center gap-2 pt-4 overflow-x-auto">
								{streamsCustomFilter?.map((filter) => {
									return (
										<Button
											key={filter.id}
											onClick={() => {
												filterDeletion
													? handleDeleteFilter(filter.id)
													: handleSelectFilter(filter.id);
												setFilterDeletion(false);
											}}
											variant={
												selectedCustomFilter === filter.id
													? "default"
													: "secondary"
											}
										>
											{filterDeletion && (
												<Cross1Icon className="h-4 w-4 mr-2" />
											)}
											{filter.name}
										</Button>
									);
								})}
							</div>
						</div>
						{processCategoriesWithStreams.map((categoryWithStreams) => {
							return (
								<ProcessCategoryOverview
									key={categoryWithStreams[0]}
									processStreams={categoryWithStreams[1] as IProcessStream[]}
									filterActiveOnly={filterActiveOnly}
									filterCreation={filterCreation}
									setSelectedStreams={setSelectedStreams}
									viewType={viewType}
									category={categoryWithStreams[1][0]?.category}
									customFilter={
										streamsCustomFilter?.find(
											(filters) => filters.id === selectedCustomFilter,
										)?.settings?.streamsIds
									}
								/>
							);
						})}
						{emptyProcessCategories
							?.filter((categories) => {
								if (valuesSelect.length === 0) return true;
								// if category is not found findIndex returns -1
								return (
									valuesSelect.findIndex(
										(value) => value.id === categories.id,
									) >= 0
								);
							})
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((category) => (
								<ProcessCategoryOverview
									key={category.id}
									processStreams={[]}
									filterActiveOnly={filterActiveOnly}
									viewType={viewType}
									category={category}
									filterCreation={filterCreation}
									setSelectedStreams={setSelectedStreams}
								/>
							))}
						<CreateFilterStreams
							filterCreation={filterCreation}
							selectedStreams={selectedStreams}
							resetFilterSelection={resetFilterSelection}
							setSelectedCustomFilter={setSelectedCustomFilter}
							preferenceKey={preferenceKey}
						/>
						<NeedModeProcesses />
					</div>
				</div>
			</>
		);
	}
	return <FallbackView />;
};

export const Route = createFileRoute("/$workspaceId/streams")({
	component: ProcessesOverviewPage,
	errorComponent: ({ error }) => {
		if (isAxiosError(error)) {
			// Render an error message
			return <div>{error.message}</div>;
		}
		return null;
	},
	pendingComponent: () => {
		return <LoadingMetronome />;
	},
	loader: async ({ context, params }) => {
		const { queryClient } = context;
		const { workspaceId } = params;

		const promises: Array<Promise<unknown>> = [];

		promises.push(
			queryClient.ensureQueryData(processCategoriesOptions(workspaceId)),
		);

		promises.push(
			queryClient.ensureQueryData(
				workspaceViewsOptions(workspaceId, preferenceKey),
			),
		);

		await Promise.all(promises);
	},
});
