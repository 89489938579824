import {
	useMutation,
	type UseMutationResult,
	useQuery,
	useQueryClient,
	type UseQueryResult,
} from "@tanstack/react-query";
import { toast } from "sonner";
import { z } from "zod";
import type { AxiosError, AxiosResponse } from "axios";

import { NoteSchema } from "@metronome/types/Note";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { NoteType } from "@metronome/types/NoteType";

import api, {
	apiGet,
	apiPost,
	type ExpectedErrorResponseType,
	HTTPMethod,
} from "./api";

export const BaseParams = z.object({
	workspaceId: z.string(),
	context: z.enum(["step-instances", "process-streams", "process-instances"]),
	contextId: z.string(),
});

export type BaseParams = z.infer<typeof BaseParams>;

export default function useNotes(
	context: BaseParams["context"],
	contextId: BaseParams["contextId"],
): UseQueryResult<NoteSchema[] | undefined, Error> {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useNotes: workspaceId is not defined");
	}
	return useQuery({
		queryKey: [workspaceId, context, contextId, "notes"],

		queryFn: () =>
			apiGet<NoteSchema[]>(`ws/${workspaceId}/${context}/${contextId}/notes`),
	});
}

export function useNoteMutation(): UseMutationResult<
	AxiosResponse<unknown> | { data: undefined },
	Error,
	{
		content: string;
		noteType: NoteType;
		context: BaseParams["context"];
		id: string;
	}
> {
	const queryClient = useQueryClient();
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useNoteMutation: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: ({ content, noteType, context, id }) =>
			apiPost(`ws/${workspaceId}/${context}/${id}/notes`, {
				content,
				noteType,
			}),

		onSuccess: (_, { id, context }) => {
			queryClient.invalidateQueries({
				queryKey: [workspaceId, context, id, "notes"],
			});
			queryClient.invalidateQueries({
				queryKey: [workspaceId, context, id, "histories"],
			});
		},
	});
}

const PutNoteRequest = z.object({
	content: z.string().min(1),
});
const PutNoteResponse = NoteSchema;

const updateNote = api<
	z.infer<typeof PutNoteRequest>,
	z.infer<typeof PutNoteResponse>
>({
	method: HTTPMethod.enum.PUT,
	requestSchema: PutNoteRequest,
	responseSchema: PutNoteResponse,
});

export function useUpdateNoteMutation(): UseMutationResult<
	NoteSchema | { data: undefined },
	AxiosError<ExpectedErrorResponseType>,
	{
		content: string;
		context: BaseParams["context"];
		contextId: string;
		noteId: string;
	}
> {
	const queryClient = useQueryClient();
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useUpdateNoteMutation: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: ({ content, context, contextId, noteId }) =>
			updateNote(`ws/${workspaceId}/${context}/${contextId}/notes/${noteId}`, {
				content,
			}),

		onSuccess: (_, { contextId, context }) => {
			queryClient.invalidateQueries({
				queryKey: [workspaceId, context, contextId, "notes"],
			});
			queryClient.invalidateQueries({
				queryKey: [workspaceId, context, contextId, "histories"],
			});
		},

		onError: (error) => {
			if (error?.response?.data?.message) {
				toast.error(`Error: ${error?.response?.data?.message}`);
			}
		},
	});
}
