import type { IWorkspace } from "@metronome/types/Workspace";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";

import { apiGet } from "./api";

export default function useWorkspaces(): UseQueryResult<
	IWorkspace[] | undefined,
	Error
> {
	return useQuery({
		queryKey: ["workspaces"],
		queryFn: () => apiGet<IWorkspace[]>("/workspaces"),
		staleTime: Number.POSITIVE_INFINITY,
		gcTime: Number.POSITIVE_INFINITY,
		placeholderData: [],
	});
}
