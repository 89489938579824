import type React from "react";
import { useIntl } from "react-intl";
import { Dialog, DialogContent } from "./Dialog";
import { Button } from "./ui/button";
import { DialogHeader } from "./ui/dialog";
import { DialogTitle } from "@radix-ui/react-dialog";

export const ConfirmationModal: React.FC<{
	show: boolean;
	onConfirm: () => void;
	loading: boolean;
	onClose: () => void;
	title?: string;
	message?: string;
	trigger?: React.ReactNode;
}> = ({ show, onConfirm, loading, onClose, title, message }) => {
	const intl = useIntl();

	return (
		<Dialog modal open={show} onOpenChange={onClose}>
			<DialogContent size="small" data-testid="confirmation-modal">
				<DialogHeader className="flex flex-col gap-2">
					<DialogTitle className="font-semibold">
						{title || intl.formatMessage({ id: "WARNING" })}
					</DialogTitle>
					<span>
						{message || intl.formatMessage({ id: "ACTION_CANNOT_UNDONE" })}
					</span>
					<div className="flex flex-row gap-2 items-center self-end">
						<Button variant="ghost" onClick={onClose}>
							{intl.formatMessage({ id: "NO" })}
						</Button>
						<Button
							disabled={loading}
							onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
								e.stopPropagation();
								e.preventDefault();
								onConfirm();
							}}
						>
							{intl.formatMessage({ id: "YES" })}
						</Button>
					</div>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};
