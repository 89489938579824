import { useRef, useEffect } from "react";

export const useCanvas = (
	draw: (context: CanvasRenderingContext2D, frameCount: number) => void,
) => {
	const canvasRef = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas?.getContext("2d");
		if (context == null) throw new Error("Could not get context");
		let frameCount = 0;

		const render = () => {
			frameCount++;
			draw(context, frameCount);
		};
		render();
	}, [draw]);

	return canvasRef;
};
