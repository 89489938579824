import React from "react";
import styles from "./button.module.scss";

type Appearance = "default" | "link" | "danger";

type Props = {
	children: React.ReactNode;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
	appearance?: Appearance;
	disabled?: boolean;
};

type AppearanceStyles = {
	[key in Appearance]: string;
};
const appearanceStyles: AppearanceStyles = {
	link: styles.buttonLink,
	default: styles.button,
	danger: styles.buttonDanger,
};

export const Button: React.FC<Props> = React.forwardRef<
	HTMLButtonElement,
	Props
>(({ children, appearance, onClick, disabled, ...props }, ref) => (
	<button
		ref={ref}
		onClick={onClick}
		className={`${appearanceStyles[appearance ?? "default"]} ${
			disabled ? styles.disabled : ""
		}`}
		type="button"
		disabled={disabled}
		{...props}
	>
		<span>{children}</span>
	</button>
));

Button.displayName = "Button";

export default Button;
