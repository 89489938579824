import type React from "react";
import { type ChangeEvent, useState } from "react";
import { Dialog, DialogContent } from "@metronome/components/Dialog";
import { useIntl } from "react-intl";
import { Button } from "../ui/button";

type FlagSteps = "flag" | "note";

export const ConfirmationModalForFlag: React.FC<{
	show: boolean;
	isFlagged: boolean;
	onUpdateFlag: () => void;
	onCreateNote: () => void;
	onClose: () => void;
	message?: string;
	content: string;
	onChange: (value: string) => void;
}> = ({
	show,
	isFlagged,
	onUpdateFlag,
	onCreateNote,
	onClose,
	message,
	content,
	onChange,
}) => {
	const intl = useIntl();

	const [step, setStep] = useState<FlagSteps>("flag");

	return (
		<Dialog open={show} onOpenChange={onClose}>
			<DialogContent size="small">
				<div className="flex flex-col gap-2">
					<strong>
						{step === "flag"
							? intl.formatMessage({
									id: isFlagged ? "REMOVE_FLAG" : "ADD_FLAG",
								})
							: intl.formatMessage({ id: "ADD_NOTE" })}
					</strong>

					{step === "note" ? (
						<>
							<p>{intl.formatMessage({ id: "ADD_NOTE_MESSAGE" })}</p>
							<div
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
								}}
								className="note rounded-1 bg-white shadow border-dark"
							>
								<textarea
									className="w-full border-0 p-2"
									value={content || ""}
									onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
										onChange(e.target.value)
									}
									placeholder={intl.formatMessage({
										id: "NOTES.PLACEHOLDER",
									})}
								/>
							</div>
						</>
					) : (
						<p>{message}</p>
					)}
					<div className="flex flex-row gap-2 items-center self-end">
						<Button
							variant="ghost"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								onClose();
							}}
						>
							{intl.formatMessage({ id: "NO" })}
						</Button>
						<Button
							disabled={step === "note" && !content}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								if (step === "flag") {
									onUpdateFlag();
									setStep("note");
								} else {
									onCreateNote();
								}
							}}
						>
							{intl.formatMessage({ id: "YES" })}
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};
