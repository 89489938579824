import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSelf from "@metronome/api/useSelf";
import type React from "react";
import { useIntl } from "react-intl";
import { useMediaQuery } from "react-responsive";

import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/Dialog";
import { HeaderUserMenu } from "@metronome/components/HeaderUserMenu";
import { Notifications } from "@metronome/features/Notification";
import { useIsMobile } from "@metronome/hooks/useResponsive";
import type { IUser } from "@metronome/types/User";
import type { IWorkspace } from "@metronome/types/Workspace";

import { useWebsocketContext } from "@metronome/context/WebSocketContext";
import { isDev, isLocalhost } from "@metronome/utils/env";
// @ts-ignore
import Logo from "../../assets/logotype.svg?react";
import SwitchOrganization from "../SwitchOrganization";
import { DefaultTitle } from "./DefaultTitle";
import styles from "./header.module.scss";
import { Link } from "@tanstack/react-router";

const LogoWithLink = (): JSX.Element => (
	<Link to="/">
		<div className={styles.logo_container}>
			<Logo />
		</div>
	</Link>
);

const MobileMenu: React.FC = () => {
	const { data, isSuccess } = useSelf();
	const intl = useIntl();
	if (isSuccess) {
		return (
			<Dialog key={location.pathname}>
				<DialogContent className="!z-[100]" size="large">
					<Link
						to="/$workspaceId/streams"
						params={{ workspaceId: data.defaultWorkspaceId }}
						className={styles.menu_link}
					>
						<div className={styles.menu_item}>
							<div>
								<FontAwesomeIcon icon="code-branch" />
							</div>
							<div className={styles.menu_title}>
								{intl.formatMessage({ id: "MENU.PROCESS" })}
							</div>
						</div>
					</Link>
				</DialogContent>
				<DialogTrigger className={styles.button_reset}>
					<FontAwesomeIcon className="text-white" icon={["fas", "bars"]} />
				</DialogTrigger>
			</Dialog>
		);
	}
	return null;
};

type Props = {
	user?: IUser;
	workspaces?: IWorkspace[];
};

const WebSocketNotifier = () => {
	const [subscribe, unsubscribe, state] = useWebsocketContext();
	return (
		<div
			className={`absolute z-10 right-0 bottom-0 ms-auto h-2 w-2 rounded-full ${
				state === 1 ? "bg-green-600" : "bg-red-600"
			}`}
		/>
	);
};

export const Header: React.FC<Props> = ({ user, workspaces }) => {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
	const isMobile = useIsMobile();

	return (
		<div className={styles.container}>
			<div className="w-full px-6 flex items-stretch justify-between">
				<div className="flex items-center justify-between grow">
					{/* begin::Navbar */}

					{isTabletOrMobile ? <MobileMenu /> : <LogoWithLink />}
					<div className={styles.title}>
						<DefaultTitle />
					</div>

					<div className="flex items-center shrink gap-4 relative">
						{!!(isDev || isLocalhost) && <WebSocketNotifier />}
						{isMobile ? <span /> : <SwitchOrganization />}
						<Notifications />
						<HeaderUserMenu user={user} workspaces={workspaces} />
					</div>
				</div>
				{/* end::Wrapper */}
			</div>
		</div>
	);
};

export default Header;
