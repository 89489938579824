import { formatDuration } from "date-fns/formatDuration";
import { parse } from "duration-fns/dist/parse";
import { isZero } from "duration-fns/dist/isZero";

export function durationToString(durationString: string): string {
	return durationString === "0:00:00" ? "" : durationString;
}

export function formatDurationToString(
	durationString: string | undefined,
): string {
	if (!durationString || durationString === "P") return "";
	const parsedDuration = parse(durationString);
	if (isZero(parsedDuration)) return "";
	return formatDuration(parsedDuration);
}
