export const getInitials = (
	firstName?: string,
	lastName?: string,
): string | undefined => {
	if (firstName || lastName) {
		return `${firstName?.[0] || ""}${lastName?.[0] || ""}`;
	}
	return undefined;
};

export const getInitialsByName = (name: string): string | undefined => {
	if (!name) return undefined;
	return name.match(/[A-Z]/g)?.join("");
};
