import * as React from "react";

import { Button } from "@metronome/components/ui/button";
import { Calendar } from "@metronome/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@metronome/components/ui/popover";
import { cn } from "@metronome/utils";
import { formatObjectDate } from "@metronome/utils/dateHelpers";
import { CalendarIcon } from "@radix-ui/react-icons";
import { FormattedMessage, useIntl } from "react-intl";

type Props = {
	initialDate?: Date;
	onValidate?: (date: Date | undefined) => void;
	showTime?: boolean;
};

export function DatePicker({
	initialDate,
	onValidate,
	showTime = false,
}: Props): JSX.Element {
	const [date, setDate] = React.useState<Date | undefined>(initialDate);
	const intl = useIntl();
	const onSelect = React.useCallback(
		(date: Date | undefined) => {
			setDate(date);
			onValidate?.(date);
		},
		[onValidate],
	);

	const displayDate = date ? formatObjectDate(date, intl) : undefined;

	return (
		<Popover modal>
			<PopoverTrigger asChild>
				<Button
					variant={"outline"}
					className={cn(
						"justify-start text-left font-normal",
						!date && "text-muted-foreground",
					)}
				>
					<CalendarIcon className="mr-2 h-4 w-4" />
					{displayDate ? (
						<span>{`${displayDate.date} ${
							showTime ? displayDate.time : ""
						}`}</span>
					) : (
						<span>
							<FormattedMessage id="PICK_A_DATE" />
						</span>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0">
				<Calendar
					mode="single"
					selected={date}
					onSelect={onSelect}
					defaultMonth={date}
					initialFocus
				/>
			</PopoverContent>
		</Popover>
	);
}
