import { singleProcessOptions } from "@metronome/api/useProcessInstance";
import { createFileRoute } from "@tanstack/react-router";
import * as v from "valibot";

export const Route = createFileRoute("/$workspaceId/process/$processId")({
	parseParams: ({ workspaceId, processId }) => ({
		workspaceId: v.parse(v.string(), workspaceId),
		processId: v.parse(v.string(), processId),
	}),

	loader: ({ context, params }) =>
		context?.queryClient.ensureQueryData(
			singleProcessOptions(params.workspaceId, params.processId),
		),
});
