import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import type { TooltipProps } from "@radix-ui/react-tooltip";
import React from "react";
import styles from "./tooltip.module.scss";

interface Props extends TooltipProps {
	content: string;
}

export const Tooltip: React.FC<Props> = ({ children, content, ...props }) => (
	<TooltipPrimitive.Provider>
		<TooltipPrimitive.Root delayDuration={0}>
			<TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
			<TooltipPrimitive.Portal>
				<TooltipPrimitive.Content
					className={styles.tooltipContent}
					side="top"
					align="center"
					{...props}
				>
					{content}
					<TooltipPrimitive.Arrow
						className={styles.tooltipArrow}
						width={11}
						height={5}
					/>
				</TooltipPrimitive.Content>
			</TooltipPrimitive.Portal>
		</TooltipPrimitive.Root>
	</TooltipPrimitive.Provider>
);
