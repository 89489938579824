import { useQuery } from "@tanstack/react-query";
import { apiGet } from "./api";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { IBusinessDimensionNode } from "@metronome/types/BusinessDimension";

export function useGetNode(id: string) {
	const workspaceId = useWorkspaceId();
	return useQuery({
		queryKey: [workspaceId, "node", id],
		queryFn: () =>
			apiGet<IBusinessDimensionNode>(`ws/${workspaceId}/nodes/${id}`),
	});
}