export type APPEARANCES = typeof APPEARANCES[keyof typeof APPEARANCES];

export const APPEARANCES = {
	default: "default",
	success: "success",
	warning: "warning",
	danger: "danger",
	defaultAccent: "defaultAccent",
} as const;

type StyleType = {
	[key in APPEARANCES]: {
		backgroundColor: string;
		color: string;
	};
};

const style: StyleType = {
	danger: {
		backgroundColor: "var(--background-danger)",
		color: "var(--text-danger)",
	},
	default: {
		backgroundColor: "var(--background-default)",
		color: "var(--text-default)",
	},
	defaultAccent: {
		backgroundColor: "hsla(var(--primary))",
		color: "white",
	},
	success: {
		backgroundColor: "var(--background-success)",
		color: "var(--text-success)",
	},
	warning: {
		backgroundColor: "var(--background-warning)",
		color: "var(--text-warning)",
	},
};

export default style;
