import { APPEARANCES } from "@metronome/constants/theme";
import type { ITimeline } from "@metronome/types/Timeline";
import { ETimeliness } from "@metronome/types/Timeliness";
import { getTimelinessFromTimeline } from "@metronome/utils/timeliness";
import type React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Lozenge } from "../Lozenge";
import styles from "./timelinessTag.module.scss";

type PropTags = {
	timeline: ITimeline;
};

export const TimelinessTag: React.FC<PropTags> = ({ timeline }) => {
	let appearance: string;
	const timeliness = getTimelinessFromTimeline(timeline);
	if (timeliness === ETimeliness.enum.atRisk) {
		appearance = "var(--text-warning)";
	} else if (timeliness === ETimeliness.enum.late) {
		appearance = "var(--text-danger)";
	} else {
		appearance = "var(--text-success)";
	}
	return (
		<>
			<style>{`.${styles.container} {--background: ${appearance};}`}</style>
			<div className={styles.container}>
				<div className={styles.pill} />
				<FormattedMessage id={`TIMELINESS.${timeliness}`} />
			</div>
		</>
	);
};

type PropLozenges = {
	timeliness: ETimeliness | undefined;
};

export const TimelinessLozenge: React.FC<PropLozenges> = ({ timeliness }) => {
	const intl = useIntl();
	let appearance: APPEARANCES;
	if (timeliness === ETimeliness.enum.atRisk) {
		appearance = APPEARANCES.warning;
	} else if (timeliness === ETimeliness.enum.late) {
		appearance = APPEARANCES.danger;
	} else {
		appearance = APPEARANCES.success;
	}
	return (
		<Lozenge appearance={appearance}>
			{intl.formatMessage({
				id: `TIMELINESS.${timeliness}`,
			})}
		</Lozenge>
	);
};
