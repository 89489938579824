import { z } from "zod";
import { IBusinessDimensionNode } from "./BusinessDimension";
import { IModelReference } from "./ModelReference";
import { IRoleRequirement } from "./ProcessStream";
import { IProcessTemplateVersion } from "./ProcessTemplate";
import { EResolution } from "./Resolution";
import { ITimeline } from "./Timeline";

export const ProcessContext = z
	.union([z.literal("active"), z.literal("past"), z.literal("future")])
	.readonly();
export type ProcessContext = z.infer<typeof ProcessContext>;

export const Time = z
	.union([z.literal("past"), z.literal("today"), z.literal("future")])
	.readonly();

export type Time = z.infer<typeof Time>;

export const ProcessState = z.enum([
	"draft",
	"ready",
	"active",
	"closed",
	"archived",
]);

export type ProcessState = z.infer<typeof ProcessState>;

export const IProcessInstance = z.object({
	id: z.string().min(1),
	name: z.string(),
	isFlagged: z.boolean().optional(),
	organizationId: z.string(),
	processStream: IModelReference,
	nextStep: IModelReference,
	processTemplate: IProcessTemplateVersion,
	resolution: EResolution,
	schedule: ITimeline, // was optional ? check api side if it is
	state: ProcessState,
	progress: z.number().min(0).max(100),
	roleRequirements: z.array(IRoleRequirement),
	context: IBusinessDimensionNode,
	startDateModified: z.boolean(),
});
export type IProcessInstance = z.infer<typeof IProcessInstance>;

export type UserSelectedProcessState =
	| "open"
	| "close"
	| "closeAndDone"
	| "closeAndCancel";
