import { AttachmentsTab } from "@metronome/features/AttachmentsTab";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
	"/$workspaceId/process/$processId/attachments",
)({
	component: () => {
		const { processId } = Route.useParams();
		return <Attachments contextId={processId} />;
	},
});

const Attachments: React.FC<{ contextId: string }> = ({ contextId }) => {
	return (
		<div className="flex items-center justify-center">
			<AttachmentsTab context="process-instances" contextId={contextId} />
		</div>
	);
};
