import React, { type FC } from "react";
import { usePageData } from "../../context/PageData";
import { CaretRightIcon } from "@radix-ui/react-icons";

const DefaultTitle: FC = () => {
	const { pageTitle } = usePageData();
	return (
		<h1 className="flex items-center text-white font-bold my-1 gap-2">
			{pageTitle?.map((title, index) => {
				return (
					<React.Fragment key={title}>
						<span className="text-xs sm:text-base">{title}</span>
						{index < pageTitle.length - 1 && <CaretRightIcon />}
					</React.Fragment>
				);
			})}
		</h1>
	);
};

export { DefaultTitle };
