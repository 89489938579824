import { SchedulerError } from "@metronome/components/SchedulerError";
import { Epg, Layout, useEpg } from "@nessprim/planby-pro";
import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from "@metronome/components/ui/select";
import { Slider } from "@metronome/components/ui/slider";
import { FormattedMessage } from "react-intl";
import {
	CustomTimeline,
	TimelineWithNav,
	WeekMonthTimelineWithNav,
} from "./planby/CustomTimeline";
import type {
	Area,
	ChannelWithOmittedUuid,
	FetchZone,
	Mode,
	ProgramWithOmittedUuid,
} from "@nessprim/planby-pro/dist/Epg/helpers";
import {
	durationInDays,
	getAvailableRange,
	getDayWidth,
	getDefaultTimeRange,
	getStartDate,
	globalStyles,
} from "@metronome/utils/planby";
import { type Dispatch, type SetStateAction, useState } from "react";
import {
	EXPANDED_SIZE,
	COMPACT_SIZE,
	planbyLightTheme,
} from "@metronome/constants/planbyTheme";
import { endOfDay } from "date-fns/endOfDay";
import { format } from "date-fns/format";
import SwitchButton from "@metronome/components/Switch";
import { ErrorBoundary } from "@sentry/react";
import { addHours, subHours } from "date-fns";
import type { ChannelWithPosition } from "@nessprim/planby-pro/dist/Epg/helpers/types";

type SelectRangeProps = {
	rangeMode: Mode["type"];
	availableRanges: Array<Mode["type"]>;
	setRangeMode: Dispatch<SetStateAction<Mode["type"]>>;
};
const SelectRange = ({
	rangeMode,
	setRangeMode,
	availableRanges,
}: SelectRangeProps) => {
	return (
		<Select
			value={rangeMode as string}
			onValueChange={(value: Mode["type"]) => setRangeMode(value)}
		>
			<SelectTrigger className="w-fit">
				<SelectValue placeholder="Select range" />
			</SelectTrigger>
			<SelectContent>
				{availableRanges.map((range) => (
					<SelectItem key={range} value={range}>
						<FormattedMessage id={range.toUpperCase()} />
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};

type SelectTimelineRangeProps = {
	setTimelineRangeInHours: (val: number) => void;
	timelineRangeInHours: number;
};
const SelectTimelineRange: React.FC<SelectTimelineRangeProps> = ({
	setTimelineRangeInHours,
	timelineRangeInHours,
}) => {
	return (
		<Select
			onValueChange={(val: string) => setTimelineRangeInHours(Number(val))}
			value={String(timelineRangeInHours)}
		>
			<SelectTrigger className="w-fit">
				<SelectValue placeholder="Select time range" />
			</SelectTrigger>
			<SelectContent>
				<SelectItem value="0">
					<FormattedMessage id="EVERY_HOUR" />
				</SelectItem>
				<SelectItem value="2">
					<FormattedMessage id="EVERY_TWO_HOURS" />
				</SelectItem>
				<SelectItem value="4">
					<FormattedMessage id="EVERY_FOUR_HOURS" />
				</SelectItem>
				<SelectItem value="6">
					<FormattedMessage id="EVERY_SIX_HOURS" />
				</SelectItem>
			</SelectContent>
		</Select>
	);
};

type SelectDisplayModeProps = {
	displayMode: "compact" | "expanded";
	setDisplayMode: (display: "compact" | "expanded") => void;
};
const SelectDisplayMode: React.FC<SelectDisplayModeProps> = ({
	displayMode,
	setDisplayMode,
}) => {
	return (
		<Select
			value={displayMode}
			onValueChange={(value: "compact" | "expanded") => setDisplayMode(value)}
		>
			<SelectTrigger className="w-fit">
				<SelectValue placeholder="Select mode" />
			</SelectTrigger>
			<SelectContent>
				<SelectItem value="compact">
					<FormattedMessage id="COMPACT" />
				</SelectItem>
				<SelectItem value="expanded">
					<FormattedMessage id="EXPANDED" />
				</SelectItem>
			</SelectContent>
		</Select>
	);
};

type StepsTimelineProps = {
	initialPlannedStart: string;
	initialPlannedEnd: string;
	epg: ProgramWithOmittedUuid[];
	channels: ChannelWithOmittedUuid[];
	areas?: Area[];
	CustomChannelItem?: React.ComponentType<{
		channel: ChannelWithPosition;
	}>;
	selectedMetadataDefs?: string[];
	fetchZone?: FetchZone;
};
export const StepsTimeline: React.FC<StepsTimelineProps> = ({
	initialPlannedStart,
	initialPlannedEnd,
	epg,
	areas,
	channels = [],
	CustomChannelItem,
	selectedMetadataDefs,
	fetchZone,
}) => {
	// state
	const [plannedStart, setPlannedStart] = useState(initialPlannedStart);
	const [plannedEnd, setPlannedEnd] = useState(initialPlannedEnd);
	const [displayMode, setDisplayMode] = useState<"compact" | "expanded">(
		"expanded",
	);
	const [dayWidthMultiplier, setDayWidthMultiplier] = useState([1]);
	const [showSchedule, setShowSchedule] = useState(false);

	// getters
	const defaultRange = getDefaultTimeRange(plannedStart, plannedEnd);
	const availableRanges = getAvailableRange(defaultRange);
	const numberOfDays = durationInDays(plannedStart, plannedEnd);
	const startDate = getStartDate(plannedStart);

	const [rangeMode, setRangeMode] = useState<Mode["type"]>(defaultRange);

	const endDate = rangeMode === "day" ? plannedEnd : endOfDay(plannedEnd);
	const endDateFormatted = format(endDate, "yyyy-MM-dd'T'HH:mm:ss");

	// planBy hooks
	const { getEpgProps, getLayoutProps } = useEpg({
		epg,
		areas,
		channels,
		startDate,
		endDate: endDateFormatted,
		mode: { type: rangeMode, style: "modern" },
		sidebarWidth: 180,
		dayWidth: getDayWidth(rangeMode, numberOfDays) * dayWidthMultiplier[0],
		itemHeight: displayMode === "expanded" ? EXPANDED_SIZE : COMPACT_SIZE,
		isSidebar: !!CustomChannelItem,
		isTimeline: true,
		isLine: true,
		isCurrentTime: true,
		theme: planbyLightTheme,
		overlap: {
			enabled: true,
			mode: "stack",
		},
		globalStyles,
		fetchZone,
	});

	const onLoadMoreDays = (timeDirection: "past" | "future", hours = 6) => {
		if (timeDirection === "past") {
			const nextPlannedStart = subHours(plannedStart, hours);
			setPlannedStart(nextPlannedStart.toISOString());
		} else if (timeDirection === "future") {
			const nextPlannedStart = addHours(plannedEnd, hours);
			setPlannedEnd(nextPlannedStart.toISOString());
		}
	};

	if (!(new Date(plannedStart) <= new Date(plannedEnd))) {
		return <SchedulerError startDateAfterEndDate />;
	}

	return (
		<>
			<div className="flex gap-2 items-center p-2">
				{availableRanges.length > 1 && (
					<SelectRange
						rangeMode={rangeMode}
						setRangeMode={setRangeMode}
						availableRanges={availableRanges}
					/>
				)}

				<SelectDisplayMode
					displayMode={displayMode}
					setDisplayMode={setDisplayMode}
				/>

				<SwitchButton
					checked={showSchedule}
					onCheckedChange={setShowSchedule}
				/>
				<span>
					<FormattedMessage id="SHOW_SCHEDULE" />
				</span>

				<Slider
					className="w-52 ms-auto py-2"
					onValueChange={setDayWidthMultiplier}
					value={dayWidthMultiplier}
					min={1}
					max={10}
					step={1}
				/>
			</div>
			<ErrorBoundary fallback={<SchedulerError />}>
				<div className="bg-grid-scheduler">
					<Epg {...getEpgProps()}>
						<Layout
							{...getLayoutProps()}
							renderProgram={({ program, hourWidth, ...rest }) => (
								<CustomTimeline
									key={program.data.id}
									program={program}
									hourWidth={hourWidth}
									showStepType={false}
									showSchedule={showSchedule}
									selectedMetadataDefs={selectedMetadataDefs}
									{...rest}
								/>
							)}
							{...(CustomChannelItem
								? {
										renderChannel: ({ channel, ...rest }) => (
											<CustomChannelItem
												key={channel.uuid}
												channel={channel}
												{...rest}
											/>
										),
									}
								: {})}
							renderTimeline={
								rangeMode === "day"
									? (props) => (
											<TimelineWithNav
												{...props}
												onLoadMoreDays={onLoadMoreDays}
												timelineDividers={2}
											/>
										)
									: (props) => (
											<WeekMonthTimelineWithNav
												{...props}
												onLoadMoreDays={onLoadMoreDays}
											/>
										)
							}
						/>
					</Epg>
				</div>
			</ErrorBoundary>
		</>
	);
};
