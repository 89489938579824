import * as Sentry from "@sentry/react";
import type React from "react";
import { useEffect } from "react";
import { useIntl } from "react-intl";

import useOrganizations from "@metronome/api/useOrganizations";
import useSelf from "@metronome/api/useSelf";
import useWorkspaces from "@metronome/api/useWorkspaces";
import { ErrorWithLogout } from "@metronome/components/Error";
import { FallbackView } from "@metronome/components/FallbackView";
import { Header } from "@metronome/components/Header";
import { AsideMenuMain } from "@metronome/components/MainMenu";
import { PageDataProvider } from "../context/PageData";
import styles from "./masterLayout.module.scss";
import { useNavigate } from "@tanstack/react-router";
import { OrganizationDataProvider } from "@metronome/context/OrganizationData";

type props = {
	title: string;
	message?: string;
};
const ErrorComponent: React.FC<props> = ({ title, message }) => (
	<div className="flex flex-col align-center justify-center mt-8">
		<ErrorWithLogout title={title} message={message} />
	</div>
);

type MasterLayoutProps = {
	children: React.ReactNode;
};

const MasterLayout: React.FC<MasterLayoutProps> = ({ children }) => {
	const intl = useIntl();
	const navigate = useNavigate();

	const { data: user, error, isLoading, isError } = useSelf();
	const {
		data: workspaces,
		error: workspaceError,
		isError: isWorkspaceError,
	} = useWorkspaces();

	useEffect(() => {
		if (isLoading) return;
		if (!user?.defaultWorkspaceId) {
			navigate({ to: "/onboarding" });
		}
	}, [user?.defaultWorkspaceId, isLoading, navigate]);

	useOrganizations();
	Sentry.setUser({ id: user?.id });

	// We're blocking the rendering of the app on purpose here until the /self request has been completed.
	// Every other requests depends on the results of the GET /self
	if (isLoading) {
		return <FallbackView />;
	}

	return (
		<PageDataProvider>
			<OrganizationDataProvider>
				<Header user={user} workspaces={workspaces} />
				<div className="flex flex-row basis-auto">
					{!!user?.defaultWorkspaceId && <AsideMenuMain />}
					<div className={styles.container}>
						{isError || isWorkspaceError ? (
							<ErrorComponent
								title={intl.formatMessage({ id: "ERROR_ENCOUNTERED" })}
								message={error?.message ?? workspaceError?.message}
							/>
						) : (
							children
						)}
					</div>
				</div>
			</OrganizationDataProvider>
		</PageDataProvider>
	);
};

export { MasterLayout };
