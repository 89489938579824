import type React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Select from "@radix-ui/react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useOrganizationData } from "@metronome/context/OrganizationData";
import styles from "./switchOrganization.module.scss";
import useOrganizations from "@metronome/api/useOrganizations";
import { useSelfUpdate } from "@metronome/api/useSelf";
import { UpdateIcon } from "@radix-ui/react-icons";

export const SwitchOrganization: React.FC = () => {
	const intl = useIntl();
	const { activeOrganization, currentOrganization } = useOrganizationData();

	const { data: organizations } = useOrganizations();
	const { mutate, isPending } = useSelfUpdate();

	if (!organizations || organizations?.length === 1) return null;

	if (currentOrganization) {
		return (
			<span className="text-white">
				<FormattedMessage id="ORGANIZATION" />
				:&nbsp;
				<strong>
					{organizations?.find((orga) => orga.id === currentOrganization)?.name}
				</strong>
			</span>
		);
	}

	return (
		<div className="relative">
			<Select.Root
				value={activeOrganization}
				onValueChange={(value) => {
					mutate({ defaultOrganizationId: value });
				}}
			>
				<Select.Trigger className={styles.trigger}>
					<Select.Value
						placeholder={intl.formatMessage({
							id: "ALL_ORGANIZATIONS",
						})}
					>
						{organizations?.find((orga) => orga.id === activeOrganization)
							?.name ?? intl.formatMessage({ id: "ALL_ORGANIZATIONS" })}
						{isPending && (
							<UpdateIcon className="ms-2 inline-block animate-spin" />
						)}
					</Select.Value>
					<Select.Icon>
						<FontAwesomeIcon icon={["fas", "chevron-down"]} />
					</Select.Icon>
				</Select.Trigger>

				<Select.Content className={styles.content}>
					<Select.ScrollUpButton />
					<Select.Viewport className={styles.viewport}>
						<Select.Group>
							<Select.Item
								className={styles.item}
								key="all_organization"
								value={undefined as unknown as string}
							>
								<Select.ItemText>
									{intl.formatMessage({ id: "ALL_ORGANIZATIONS" })}
								</Select.ItemText>
							</Select.Item>
							{organizations?.map((organization) => (
								<Select.Item
									className={styles.item}
									key={organization.id}
									value={organization.id ?? ""}
								>
									<Select.ItemText>{organization.name ?? ""}</Select.ItemText>
									<Select.ItemIndicator className={styles.itemIndicatorStyle}>
										<FontAwesomeIcon
											icon="circle"
											className={styles.itemIndicator}
										/>
									</Select.ItemIndicator>
								</Select.Item>
							))}
						</Select.Group>
					</Select.Viewport>
				</Select.Content>
			</Select.Root>
		</div>
	);
};

export default SwitchOrganization;
