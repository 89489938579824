import { SwitchButton } from "@metronome/components/Switch";
import { UserPopover } from "@metronome/components/UserPopover";
import type { IAgenda } from "@metronome/types/StepInstance";
import { durationToString } from "@metronome/utils/durationHelpers";
import type React from "react";
import type { FC } from "react";
import { FormattedMessage } from "react-intl";

import { useUpdateAgenda } from "@metronome/api/useStepInstance";
import type { IParty } from "@metronome/types/Party";
import { UpdateIcon } from "@radix-ui/react-icons";
import styles from "./agendaActivitiesTemplate.module.scss";

type AgendaSingleItemProps = {
	item: IAgenda;
	stepInstanceId: string;
};

const AgendaSingleItem: FC<AgendaSingleItemProps> = ({
	item,
	stepInstanceId,
}) => {
	const { mutate: updateAgendaElement, isPending } =
		useUpdateAgenda(stepInstanceId);

	const getAvatar = (completedBy?: IParty): JSX.Element => {
		if (completedBy) return <UserPopover user={completedBy} />;
		return <span style={{ width: "44px", height: "34px" }} />;
	};

	return (
		<div
			key={item.id}
			className={`${styles.width} flex justify-between flex-row gap-2`}
		>
			<div className="flex flex-col">
				<strong>{item.name}</strong>
				<span>{item.description}</span>
				<div>
					{!!durationToString(item.duration) && (
						<span className="italic text-sm">
							<FormattedMessage id="DURATION" />{" "}
							{durationToString(item.duration)}
						</span>
					)}
				</div>
			</div>
			<div className="flex flex-row items-center gap-2">
				{!isPending && (
					<SwitchButton
						onCheckedChange={() =>
							updateAgendaElement({
								agendaId: item.id,
								completed: !item.completed,
							})
						}
						disabled={isPending}
						checked={item.completed}
					/>
				)}
				{isPending && <UpdateIcon className="inline-block animate-spin" />}
				{getAvatar(item.completedBy)}
			</div>
		</div>
	);
};

export const AgendaActivitiesTemplate: React.FC<{
	agenda: IAgenda[];
	stepInstanceId: string;
}> = ({ agenda, stepInstanceId }) => {
	const agendaElements = agenda.map((item) => (
		<AgendaSingleItem
			key={item.id}
			item={item}
			stepInstanceId={stepInstanceId}
		/>
	));
	return <div className="m-4 flex flex-col gap-4">{agendaElements}</div>;
};
