import {
	isDateControl,
	isDateTimeControl,
	isEnumControl,
	isTimeControl,
	rankWith,
	schemaTypeIs,
} from "@jsonforms/core";
import { IFormDefinition } from "@metronome/types/FormDefinition";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { JsonForms } from "@jsonforms/react";
import { vanillaCells, vanillaRenderers } from "@jsonforms/vanilla-renderers";
import CheckBoxControl from "@metronome/components/BootstrapJsonForms/CheckBoxControl";
import DateControl from "@metronome/components/BootstrapJsonForms/DateControl";
import DateTimeControl from "@metronome/components/BootstrapJsonForms/DateTimeControl";
import InputControl from "@metronome/components/BootstrapJsonForms/InputControl";
import IntegerControl from "@metronome/components/BootstrapJsonForms/IntegerControl";
import SelectControl from "@metronome/components/BootstrapJsonForms/SelectControl";
import TimeControl from "@metronome/components/BootstrapJsonForms/TimeControl";
import { Dialog, DialogContent } from "@metronome/components/Dialog";
import { Button } from "@metronome/components/ui/button";

const inputRendererTester = rankWith(3, schemaTypeIs("string"));
const checkBoxControlTester = rankWith(3, schemaTypeIs("boolean"));
const dateControlTester = rankWith(3, isDateControl);
const timeControlTester = rankWith(3, isTimeControl);
const dateTimeControlTester = rankWith(3, isDateTimeControl);
const integerControlTester = rankWith(3, schemaTypeIs("integer"));
const selectControlTester = rankWith(10, isEnumControl);

type FormData = { [name: string]: object } | undefined;

export const FormSubmitModal: React.FC<{
	show: boolean;
	onConfirm?: (formData: FormData) => Promise<void>;
	onClose: () => void;
	title: string;
	form: IFormDefinition;
	initialFormData?: FormData;
}> = ({ show, onConfirm, onClose, title, form, initialFormData }) => {
	const intl = useIntl();
	const [formData, setFormData] = useState(initialFormData);
	const [formErrors, setFormErrors] = useState<number>(0);

	const renderers = [
		...vanillaRenderers,
		{ tester: dateControlTester, renderer: DateControl },
		{ tester: timeControlTester, renderer: TimeControl },
		{ tester: dateTimeControlTester, renderer: DateTimeControl },
		{ tester: inputRendererTester, renderer: InputControl },
		{ tester: checkBoxControlTester, renderer: CheckBoxControl },
		{ tester: integerControlTester, renderer: IntegerControl },
		{ tester: selectControlTester, renderer: SelectControl },
	];

	const onOpenChange = (open: boolean): void => {
		if (!open) {
			onClose();
		}
	};

	return (
		<Dialog open={show} onOpenChange={onOpenChange}>
			<DialogContent size="small">
				<strong>{title}</strong>
				<JsonForms
					schema={form.jsonSchema}
					uischema={form.uiSchema}
					data={formData}
					renderers={renderers}
					cells={vanillaCells}
					onChange={({ data, errors }) => {
						setFormErrors(errors?.length ?? 0);
						setFormData(data);
					}}
				/>
				<div className="flex flex-row gap-2 items-center justify-end mt-8">
					<Button variant="ghost" onClick={onClose}>
						{intl.formatMessage({ id: "CANCEL" })}
					</Button>
					{!!onConfirm && (
						<Button
							disabled={!formData || formErrors > 0}
							onClick={() => {
								onConfirm(formData);
							}}
						>
							{intl.formatMessage({ id: "FORM_MODAL.FOOTER" })}
						</Button>
					)}
				</div>
			</DialogContent>
		</Dialog>
	);
};
