import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateFileAttachment } from "@metronome/api/useAttachments";
import type { BaseParams } from "@metronome/api/useAttachments";
import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/Dialog";
import {
	ImgThumbnail,
	PdfThumbnail,
	useObjectUrls,
} from "@metronome/components/FileThumbnail";
import FloatingInput from "@metronome/components/FloatingInput";
import { Button } from "@metronome/components/ui/button";
import { AttachmentTypeAPI } from "@metronome/types/Attachment";
import { canvasToFile, urlToFile } from "@metronome/utils/fileHelpers";
import type React from "react";
import { useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import styles from "./sendFileAttachment.module.scss";

type FilePlaceholderProps = {
	children: React.ReactNode;
};
const FilePlaceholder: React.FC<FilePlaceholderProps> = ({ children }) => (
	<div className="h-52 overflow-clip p-2 border-dashed border-green-700 border rounded-md flex flex-col items-center justify-center gap-2">
		{children}
	</div>
);

const ATTACHMENT_MAX_SIZE = 1_000_000; // Size 1MB should be 1*1024*1024
const ALLOWED_IMAGE_FILE_TYPES = ["image/png", "image/jpeg"];
const PDF = "application/pdf";

const schema = z.object({
	file: z
		.instanceof(FileList)
		.refine((file) => file.length !== 0, {
			message: "ERRORS.FILE_REQUIRED",
		})
		.refine(
			(file) => {
				const fileSize = file.item?.(0)?.size || 0;
				return fileSize <= ATTACHMENT_MAX_SIZE;
			},
			{ message: "ERRORS.FILE_LIMIT" },
		),
	name: z.string(),
	description: z.string().optional(),
});

type Inputs = z.infer<typeof schema>;

type SendFileAttachmentProps = {
	context: BaseParams["context"];
	contextId: string;
	onSuccess?: () => void;
	specId?: string;
	children?: React.ReactNode;
};

export const SendFileAttachment: React.FC<SendFileAttachmentProps> = ({
	context,
	contextId,
	onSuccess,
	specId,
}) => {
	const intl = useIntl();

	const [file, setFile] = useState<File>();
	const [canvas, setCanvas] = useState<HTMLCanvasElement>();
	const [previewUri, setPreviewUri] = useState("");

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>({
		mode: "onSubmit",
		resolver: zodResolver(schema),
	});

	const { mutateAsync: createAttachment, isPending } = useCreateFileAttachment(
		context,
		contextId,
		onSuccess,
	);

	const hasErrors = Object.keys(errors).length;

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		let previewFile = undefined;
		const fileName = file?.name.replace(/\.[^/.]+$/, "") ?? "undefined";
		if (canvas && file?.type === PDF) {
			previewFile = await canvasToFile(canvas, fileName);
		} else if (
			previewUri &&
			ALLOWED_IMAGE_FILE_TYPES.includes(file?.type ?? "")
		) {
			previewFile = await urlToFile(previewUri, fileName);
		}
		createAttachment({
			type: AttachmentTypeAPI.enum.Document,
			name: data.name,
			description: data.description ?? "",
			file: data.file,
			previewFile: previewFile,
			specId: specId ?? undefined,
		});
	};

	const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newFile = e.target.files?.[0];
		if (
			newFile &&
			(newFile as File).size <= ATTACHMENT_MAX_SIZE &&
			(newFile.type === PDF || ALLOWED_IMAGE_FILE_TYPES.includes(newFile.type))
		) {
			setFile(newFile);
		}
	};

	const getObjectUrl = useObjectUrls();

	return (
		<div className="flex flex-col gap-2 items-center">
			<form
				className="flex flex-col gap-2 p-4"
				onSubmit={handleSubmit(onSubmit)}
			>
				<strong>
					<FormattedMessage id="SEND_FILE_ATTACHMENT" />
				</strong>
				<FloatingInput required label="name" {...register("name")} />
				<FloatingInput
					required={false}
					label="description"
					{...register("description")}
				/>
				<input
					className={styles.upload}
					type="file"
					{...register("file")}
					onChange={onFileChange}
				/>
				{errors.file?.message && (
					<p className={styles.error}>
						<FormattedMessage
							id={errors.file.message}
							values={{ size: ATTACHMENT_MAX_SIZE / 1000000 }}
						/>
					</p>
				)}
				{!!file && (
					<FilePlaceholder>
						{!!file && ALLOWED_IMAGE_FILE_TYPES.includes(file.type) && (
							<ImgThumbnail
								file={file}
								onChange={(previewUri) => setPreviewUri(previewUri)}
							/>
						)}
						{!!file && file.type === PDF && (
							<PdfThumbnail
								pdfUrl={getObjectUrl(file)}
								fileName={file.name}
								onChange={(previewFile) => setCanvas(previewFile)}
							/>
						)}
					</FilePlaceholder>
				)}

				<input
					type="submit"
					disabled={!!hasErrors || isPending}
					className={styles.submit}
					value={
						isPending
							? intl.formatMessage({ id: "LOADING" })
							: intl.formatMessage({ id: "CREATE_NODE.SUBMIT" })
					}
				/>
			</form>
		</div>
	);
};

export const SendFileAttachmentAsDialog: React.FC<SendFileAttachmentProps> = ({
	context,
	contextId,
	children,
	onSuccess,
	specId,
}): JSX.Element => {
	const [open, setOpen] = useState(false);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button>
					{children || <FontAwesomeIcon fixedWidth icon={["fas", "upload"]} />}
				</Button>
			</DialogTrigger>
			<DialogContent size="medium">
				<SendFileAttachment
					context={context}
					contextId={contextId}
					specId={specId}
					onSuccess={() => {
						setOpen(false);
						onSuccess?.();
					}}
				/>
			</DialogContent>
		</Dialog>
	);
};
