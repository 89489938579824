import React from "react";
import * as RadixCollapsible from "@radix-ui/react-collapsible";
import type {
	CollapsibleProps,
	CollapsibleContentProps,
	CollapsibleTriggerProps,
} from "@radix-ui/react-collapsible";
import style from "./collapsible.module.scss";

export const CollapsibleRoot = React.forwardRef<
	HTMLDivElement,
	CollapsibleProps
>(({ children, ...props }, forwardRef) => (
	<RadixCollapsible.Root ref={forwardRef} {...props}>
		{children}
	</RadixCollapsible.Root>
));
CollapsibleRoot.displayName = "CollapsibleRoot";

export const CollapsibleTrigger = React.forwardRef<
	HTMLButtonElement,
	CollapsibleTriggerProps
>(({ children, ...props }, forwardRef) => (
	<RadixCollapsible.Trigger ref={forwardRef} {...props}>
		{children}
	</RadixCollapsible.Trigger>
));
CollapsibleTrigger.displayName = "CollapsibleRoot";

export const CollapsibleContent = React.forwardRef<
	HTMLDivElement,
	CollapsibleContentProps
>(({ children, ...props }, forwardRef) => (
	<RadixCollapsible.Content
		className={style.content}
		ref={forwardRef}
		{...props}
	>
		{children}
	</RadixCollapsible.Content>
));
CollapsibleContent.displayName = "CollapsibleRoot";
