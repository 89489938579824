import type React from "react";
import { useIntl } from "react-intl";

import { useLogout } from "../hooks/useLogout";
import { Button } from "./ui/button";

export const ErrorWithLogout: React.FC<{
	title?: string;
	message?: string | JSX.Element;
	children?: React.ReactNode;
}> = ({ title, message, children }) => {
	const intl = useIntl();
	const logout = useLogout();

	const handleLogout = (): void => {
		logout();
	};

	return (
		<div className="flex flex-col items-center justify-center h-full">
			<h1 className="text-2xl font-bold text-slate-700">{title}</h1>
			<p className="mt-4 text-lg text-slate-600">{message}</p>
			{children}
			<p className="my-4 text-lg text-slate-600">
				{intl.formatMessage({ id: "YOU_CAN_REQUEST_SUPPORT_AT" })}{" "}
				<a href="mailto:support@process-metronome.com">
					support@process-metronome.com
				</a>
			</p>
			<Button onClick={handleLogout}>
				{intl.formatMessage({ id: "AUTH.LOGOUT" })}
			</Button>
		</div>
	);
};
