import { useDeleteAttachment } from "@metronome/api/useAttachments";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@metronome/components/ui/dropdown-menu";
import { EditLinkAttachment } from "@metronome/features/EditLinkAttachment";
import {
	type AttachmentAPI,
	AttachmentTypeAPI,
} from "@metronome/types/Attachment";
import type { Context } from "@metronome/types/Context";
import {
	DotsVerticalIcon,
	FileIcon,
	InfoCircledIcon,
	Link2Icon,
} from "@radix-ui/react-icons";
import type React from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ConfirmationModal } from "./ConfirmationModal";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "./ui/tooltip";

type AttachmentsActionsProps = {
	onDelete: () => void;
	onEdit?: () => void;
};
const AttachmentsActions: React.FC<AttachmentsActionsProps> = ({
	onDelete,
	onEdit,
}) => (
	<DropdownMenu modal={false}>
		<DropdownMenuTrigger>
			<DotsVerticalIcon />
		</DropdownMenuTrigger>
		<DropdownMenuContent align="end">
			{onEdit && (
				<DropdownMenuItem onSelect={onEdit}>
					<FormattedMessage id="EDIT" />
				</DropdownMenuItem>
			)}
			<DropdownMenuItem onSelect={onDelete}>
				<FormattedMessage id="DELETE" />
			</DropdownMenuItem>
		</DropdownMenuContent>
	</DropdownMenu>
);

type Props = {
	attachment: AttachmentAPI;
	context: Context;
	contextId: string;
	preloaded?: boolean;
	isARequiredAttachment?: boolean;
};

export const Attachment: React.FC<Props> = ({
	attachment,
	context,
	contextId,
	preloaded,
	isARequiredAttachment = false,
}) => {
	const [selectedId, setSelectedId] = useState<string>("");
	const [open, setOpen] = useState(false);

	const deleteAttachment = useDeleteAttachment();

	const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
		useState<boolean>(false);

	const onDeleteAttachment = (id: string): void => {
		setSelectedId(id);
		setIsConfirmationModalOpen(true);
	};

	const onEditAttachment = (id: string): void => {
		setSelectedId(id);
		setOpen(true);
	};

	return (
		<>
			<EditLinkAttachment
				context={context}
				contextId={contextId}
				attachment={attachment}
				open={open}
				setOpen={setOpen}
			/>
			<ConfirmationModal
				loading={deleteAttachment.isPending}
				show={isConfirmationModalOpen}
				onClose={() => setIsConfirmationModalOpen(false)}
				onConfirm={() => {
					deleteAttachment.mutate({
						attachmentId: selectedId,
						context,
						contextId,
					});
					setIsConfirmationModalOpen(false);
				}}
			/>
			<div
				key={attachment.id}
				className="flex flex-row items-center justify-between py-1 px-2 rounded w-96  max-w-xs md:max-w-xl shadow bg-secondary hover:bg-slate-200 transition-colors"
			>
				<button
					type="button"
					onClick={() =>
						window.open(attachment.content, "_blank", "noopener, noreferrer")
					}
					className="group flex flex-row items-center gap-2 cursor-pointer truncate	grow"
				>
					{attachment.type === AttachmentTypeAPI.enum.Link ? (
						<Link2Icon className="group-hover:text-blue-600" />
					) : (
						<FileIcon />
					)}
					<span className="font-semibold text-left truncate">
						{attachment.name}
					</span>
				</button>
				<div className="flex flex-row items-center gap-1">
					{isARequiredAttachment && (
						<span className="text-xs text-primary">
							<FormattedMessage id="REQUIRED" />
						</span>
					)}
					{attachment.description && (
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger>
									<InfoCircledIcon className="text-slate-500" />
								</TooltipTrigger>
								<TooltipContent>
									<p className="font-bold">{attachment.description}</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					)}
					{!!attachment.uploadedBy && (
						<span className="text-xs truncate">{attachment.uploadedBy}</span>
					)}
					{!preloaded && (
						<AttachmentsActions
							onDelete={() => onDeleteAttachment(attachment.id)}
							onEdit={
								attachment.type === "Link"
									? () => onEditAttachment(attachment.id)
									: undefined
							}
						/>
					)}
				</div>
			</div>
		</>
	);
};
