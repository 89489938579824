import { z } from "zod";
import { IModelReference } from "./ModelReference";

export const IProcessTemplateVersion = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string().optional(),
	versionName: z.union([z.string(), z.number()]).optional(),
	category: z.string().optional(),
});
export type IProcessTemplateVersion = z.infer<typeof IProcessTemplateVersion>;
