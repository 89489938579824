import { createFileRoute } from "@tanstack/react-router";
import * as v from "valibot";

const ContentPageParams = v.object({
	from: v.optional(v.string()),
	to: v.optional(v.string()),
	sortBy: v.optional(v.string()),
	sortOrder: v.optional(v.string()),
});

export const Route = createFileRoute("/$workspaceId/data")({
	validateSearch: (searchParams) => v.parse(ContentPageParams, searchParams),
});
