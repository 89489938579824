import { gotoQueryOptions } from "@metronome/api/useGoto";
import type { Params } from "@metronome/api/useGoto";
import { useQuery } from "@tanstack/react-query";
import { Navigate, createFileRoute } from "@tanstack/react-router";
import * as v from "valibot";

function getNavigateTo(
	stepInstanceId?: string,
	processInstanceId?: string,
): Params {
	if (stepInstanceId) {
		return {
			type: "step-instances" as const,
			id: stepInstanceId,
		};
	}
	return {
		type: "process-instances" as const,
		id: processInstanceId ?? "",
	};
}

const GotoSearchParams = v.object({
	stepInstanceId: v.string(),
	processInstanceId: v.string(),
});

export const Route = createFileRoute("/goto")({
	validateSearch: (searchParams) => v.parse(GotoSearchParams, searchParams),
	loaderDeps: ({ search: { stepInstanceId, processInstanceId } }) => ({
		stepInstanceId,
		processInstanceId,
	}),
	loader: async ({ context, deps }) => {
		const { stepInstanceId, processInstanceId } = deps;
		const navigateTo: Params = getNavigateTo(stepInstanceId, processInstanceId);
		await context.queryClient.ensureQueryData(gotoQueryOptions(navigateTo));
	},
	component: () => {
		const { stepInstanceId, processInstanceId } = Route.useSearch();
		const navigateTo: Params = getNavigateTo(stepInstanceId, processInstanceId);
		const { data } = useQuery(gotoQueryOptions(navigateTo));
		if (stepInstanceId && data) {
			return (
				<Navigate
					to="/$workspaceId/process/$processId/gates-and-steps/$stepId"
					params={{
						workspaceId: data.workspaceId,
						processId: data.processInstanceId,
						stepId: data.stepInstanceId,
					}}
				/>
			);
		}
		if (data) {
			return (
				<Navigate
					to="/$workspaceId/process/$processId"
					params={{
						workspaceId: data.workspaceId,
						processId: data.processInstanceId,
					}}
				/>
			);
		}
		return null;
	},
});
