import { Button } from "@metronome/components/ui/button";
import {
	CurrentTime,
	type Timeline,
	TimelineWrapper,
	useTimeline,
	TimelineWeekMonthBox,
	TimelineWeekMonthDate,
	TimelineBox,
	TimelineDivider,
	TimelineDividers,
	TimelineTime,
} from "@nessprim/planby-pro";
import { CaretLeftIcon, CaretRightIcon } from "@radix-ui/react-icons";

type TimelineExtraProps = {
	timelineRangeInHours?: number; // 4 for every 4 hours
};

export function CustomTimeline(props: Timeline & TimelineExtraProps) {
	const { isWeekMonthMode, isMonthMode, time, ...rest } = useTimeline(props);
	const { timelineHeight, weekDayWidth, monthsWidth } = rest;
	const {
		formatWeekMonthDate,
		getDayMonthName,
		getTimelineProps,
		getCurrentTimeProps,
	} = rest;

	const {
		isToday,
		isCurrentTime,
		isRTL,
		isTimelineVisible,
		timelineRangeInHours = 4,
		hourWidth,
	} = props;
	const timeWithRange = time.filter(
		(t) => Number(t) % timelineRangeInHours === 0,
	);

	const { mode } = props;

	const renderWeekMonth = (item: string, index: number) => {
		const width = isMonthMode ? monthsWidth[index].width : weekDayWidth;
		const left = isMonthMode ? monthsWidth[index].left : width * index;
		const position = {
			left,
			width,
		};
		const isVisible = isTimelineVisible(position);
		if (!isVisible) return null;
		const isModernStyle = mode.style === "modern";
		return (
			<TimelineWeekMonthBox
				className="planby-timeline-box-alt"
				data-testid="timeline-item"
				key={index}
				isToday={isToday}
				isWeekMonthMode={isWeekMonthMode}
				isCurrentTime={isCurrentTime}
				timelineHeight={timelineHeight}
				styleType={mode.style}
				{...position}
			>
				<TimelineWeekMonthDate
					className="planby-timeline-week-month-date"
					styleType={mode.style}
				>
					{isModernStyle && <span>{getDayMonthName(item)}</span>}
					<span>{formatWeekMonthDate(item)}</span>
				</TimelineWeekMonthDate>
			</TimelineWeekMonthBox>
		);
	};

	const renderCustomTimeRange = (item: string, index: number) => {
		const width = hourWidth * timelineRangeInHours;
		const left = width * index;
		const position = {
			left,
			width,
		};
		const isVisible = isTimelineVisible(position);
		if (!isVisible) return null;
		return (
			<TimelineWeekMonthBox
				className="planby-timeline-box-alt"
				data-testid="timeline-item"
				key={index}
				isToday={isToday}
				isWeekMonthMode={isWeekMonthMode}
				isCurrentTime={isCurrentTime}
				timelineHeight={timelineHeight}
				styleType={mode.style}
				{...position}
			>
				<TimelineWeekMonthDate
					className="planby-timeline-week-month-date"
					styleType={mode.style}
				>
					<span>{`${item}h - ${item + timelineRangeInHours}h`}</span>
				</TimelineWeekMonthDate>
			</TimelineWeekMonthBox>
		);
	};

	return (
		<TimelineWrapper
			className="planby-timeline-wrapper"
			data-testid="timeline"
			{...getTimelineProps()}
		>
			{isCurrentTime && isToday && <CurrentTime {...getCurrentTimeProps()} />}
			{timeWithRange.map((item, index) =>
				renderCustomTimeRange(item as string, index),
			)}
		</TimelineWrapper>
	);
}

type TimelineNavProps = {
	onLoadMoreDays: (timeDirection: "past" | "future", hours?: number) => void;
};
export function TimelineWithNav(props: Timeline & TimelineNavProps) {
	const {
		time,
		dividers,
		timelineHeight,
		timelineDividers,
		getTime,
		getTimelineProps,
		getCurrentTimeProps,
		isWeekMonthMode,
	} = useTimeline(props);

	const { isToday, isBaseTimeFormat, isCurrentTime, isTimelineVisible } = props;

	const { hourWidth, dayWidth, onLoadMoreDays } = props;

	const width = isWeekMonthMode ? dayWidth : hourWidth;
	const renderTime = (item: string | number, index: number) => {
		const { isNewDay, time } = getTime(item);
		const position = { left: width * index, width: width };
		const isVisible = isTimelineVisible(position);
		if (!isVisible) return null;
		return (
			<TimelineBox
				key={index}
				isToday={isToday}
				isCurrentTime={isCurrentTime}
				timelineHeight={timelineHeight}
				{...position}
			>
				<TimelineTime isNewDay={isNewDay} isBaseTimeFormat={isBaseTimeFormat}>
					{time}
				</TimelineTime>
				<TimelineDividers>{renderDividers(isNewDay)}</TimelineDividers>
			</TimelineBox>
		);
	};

	const renderDividers = (isNewDay: boolean) =>
		dividers.map((_, index) => (
			<TimelineDivider
				// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
				key={index}
				isNewDay={isNewDay}
				width={width / timelineDividers}
				left={index * (width / timelineDividers)}
			/>
		));

	return (
		<TimelineWrapper className="relative" {...getTimelineProps()}>
			<Button
				variant="outline"
				size="icon"
				onClick={() => onLoadMoreDays("past")}
				className="absolute top-0 left-0 z-50"
			>
				<CaretLeftIcon />
			</Button>
			<Button
				variant="outline"
				size="icon"
				onClick={() => onLoadMoreDays("future")}
				className="absolute top-0 right-0 z-50"
			>
				<CaretRightIcon />
			</Button>
			{isCurrentTime && isToday && <CurrentTime {...getCurrentTimeProps()} />}
			{time.map((item, index) => renderTime(item, index))}
		</TimelineWrapper>
	);
}

export function WeekMonthTimelineWithNav(props: Timeline & TimelineNavProps) {
	const { isWeekMonthMode, isMonthMode, time, ...rest } = useTimeline(props);
	const { timelineHeight, weekDayWidth, monthsWidth } = rest;
	const {
		formatWeekMonthDate,
		getDayMonthName,
		getTimelineProps,
		getCurrentTimeProps,
	} = rest;

	const { isToday, isCurrentTime, isRTL, isTimelineVisible, onLoadMoreDays } =
		props;
	const { mode } = props;

	const renderWeekMonth = (item: string, index: number) => {
		const width = isMonthMode ? monthsWidth[index].width : weekDayWidth;
		const left = isMonthMode ? monthsWidth[index].left : width * index;
		const position = {
			left,
			width,
		};
		const isVisible = isTimelineVisible(position);
		if (!isVisible) return null;
		const isModernStyle = mode.style === "modern";
		return (
			<TimelineWeekMonthBox
				className="planby-timeline-box"
				data-testid="timeline-item"
				key={index}
				isToday={isToday}
				isWeekMonthMode={isWeekMonthMode}
				isCurrentTime={isCurrentTime}
				timelineHeight={timelineHeight}
				styleType={mode.style}
				{...position}
			>
				<TimelineWeekMonthDate
					className="planby-timeline-week-month-date"
					styleType={mode.style}
				>
					{isModernStyle && <span>{getDayMonthName(item)}</span>}
					<span>{formatWeekMonthDate(item)}</span>
				</TimelineWeekMonthDate>
			</TimelineWeekMonthBox>
		);
	};

	return (
		<TimelineWrapper
			className="planby-timeline-wrapper relative"
			data-testid="timeline"
			{...getTimelineProps()}
		>
			<Button
				variant="outline"
				size="icon"
				onClick={() => onLoadMoreDays("past", 12)}
				className="absolute top-1/2 left-0 z-50 -translate-y-1/2"
			>
				<CaretLeftIcon />
			</Button>
			<Button
				variant="outline"
				size="icon"
				onClick={() => onLoadMoreDays("future", 12)}
				className="absolute top-1/2 right-0 z-50 -translate-y-1/2"
			>
				<CaretRightIcon />
			</Button>
			{isCurrentTime && isToday && <CurrentTime {...getCurrentTimeProps()} />}
			{time.map((item, index) => renderWeekMonth(item as string, index))}
		</TimelineWrapper>
	);
}
