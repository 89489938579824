import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";

import type { IEntityHistory } from "@metronome/types/History";
import type { IPaginatedResults } from "@metronome/types/PaginatedResponse";
import { apiGet } from "./api";
import type { Context } from "@metronome/types/Context";

type Params = {
	[key: string]: string | string[] | number | undefined;
};

const historyKeys = {
	all: (workspaceId: string, context: Context, entityId?: string) =>
		[workspaceId, context, entityId, "histories"] as const,
	filter: (
		workspaceId: string,
		context: Context,
		params: Params,
		entityId?: string,
	) => [...historyKeys.all(workspaceId, context, entityId), params] as const,
};

export default function useEntityHistories(
	context: Context,
	params: Params,
	entityId?: string,
): UseQueryResult<IPaginatedResults<IEntityHistory> | undefined, Error> {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useEntityHistories: workspaceId is not defined");
	}
	return useQuery({
		queryKey: historyKeys.filter(workspaceId, context, params, entityId),
		queryFn: () =>
			apiGet<IPaginatedResults<IEntityHistory>>(
				`ws/${workspaceId}/${context}/${entityId}/history`,
				{ params },
			),

		enabled: !!entityId,
	});
}
