import type React from "react";
import type { HexColorCouple } from "@metronome/utils/status";
import * as Progress from "@radix-ui/react-progress";
import { cn } from "@metronome/utils";

type Props = {
	progress: number;
	colorCouple: Partial<HexColorCouple>;
	className?: string;
};

export const ProgressBar: React.FC<Props> = ({
	progress = 0,
	colorCouple,
	className,
}) => {
	return (
		<Progress.Root
			max={100}
			className={cn(
				"relative overflow-hidden translate-z-0 rounded-full bg-slate-100 h-2 w-full",
				className,
			)}
			style={{ backgroundColor: colorCouple.background }}
			value={progress}
		>
			<Progress.Indicator
				className="h-full w-full flex-1 bg-primary transition-all"
				style={{
					transform: `translateX(-${100 - (progress || 0)}%)`,
					backgroundColor: colorCouple.foreground,
				}}
			/>
		</Progress.Root>
	);
};
