// @ts-ignore
import Logo from "@metronome/assets/logotype-couleur.svg?react";

export const LoadingMetronome = (): JSX.Element => (
	<div className="m-auto w-8">
		<Logo className="animate-pulse w-8" />
	</div>
);

export default LoadingMetronome;
