import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { AxiosError } from "axios";
import {
	type UseMutationResult,
	type UseQueryResult,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { toast } from "sonner";
import { type ExpectedErrorResponseType, apiGet, apiPut } from "./api";
import type { UserPreference } from "@metronome/types/UserPreference";

const userPreferenceKeys = {
	single: (workspaceId: string, key: string) =>
		[workspaceId, "preferences", key] as const,
};

export default function useUserPreferences(
	key: string,
): UseQueryResult<UserPreference | undefined, Error> {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useUserPreferences: workspaceId is not defined");
	}
	const keyWithWs = `${key}_ws.${workspaceId}`;
	return useQuery({
		queryKey: userPreferenceKeys.single(workspaceId, keyWithWs),
		queryFn: () =>
			apiGet<UserPreference>("self/preferences", {
				params: { key: keyWithWs },
			}),
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});
}

const updateUserPreferences = async ({
	keyWithWs,
	data,
}: {
	keyWithWs: string;
	data: UserPreference;
}): Promise<UserPreference> => {
	await apiPut(`self/preferences?key=${keyWithWs}`, data);
	return data;
};

export function useUpdateUserPreferences(
	key: string,
): UseMutationResult<
	UserPreference,
	AxiosError<ExpectedErrorResponseType>,
	{ data: UserPreference }
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useUpdateUserPreferences: workspaceId is not defined");
	}

	const keyWithWs = `${key}_ws.${workspaceId}`;
	return useMutation({
		mutationFn: ({ data }: { data: UserPreference }) =>
			updateUserPreferences({ keyWithWs, data }),

		onError: (error) => {
			toast.error(`Error: ${error?.response?.data?.message}`);
		},

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: userPreferenceKeys.single(workspaceId, keyWithWs),
			});
		},
	});
}
