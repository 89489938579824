import { useIntl } from "react-intl";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";
import { isValid } from "date-fns/isValid";
import { fr } from "date-fns/locale/fr";
import { enGB } from "date-fns/locale/en-GB";

const locales = { fr, enGB };

export const FormatDistance: React.FC<{ date: string }> = ({ date }) => {
	const intl = useIntl();
	const dueDate: Date | number = new Date(date);
	const isValidDate = isValid(dueDate);
	if (!isValidDate) return null;
	const dateDistance = formatDistanceToNow(new Date(dueDate), {
		addSuffix: true,
		locale: locales[intl.locale as keyof typeof locales],
	})?.replace("ago", "late");
	return <span>{dateDistance}</span>;
};
