import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { AxiosError, AxiosResponse } from "axios";
import { useIntl } from "react-intl";
import {
	type UseMutationResult,
	type UseQueryResult,
	useMutation,
	useQuery,
	useQueryClient,
	keepPreviousData,
} from "@tanstack/react-query";
import { toast } from "sonner";
import {
	type ExpectedErrorResponseType,
	apiDelete,
	apiGet,
	apiPost,
	apiPut,
} from "./api";
import type { UserPreference } from "@metronome/types/UserPreference";

export type SavedTable = {
	name: string;
	id: string;
	settings: UserPreference;
};

const userSavedTableViews = {
	single: (workspaceId: string, key: string) =>
		[workspaceId, "savedTableViews", key] as const,
};

const getKeyWithWs = (workspaceId: string, key: string): string =>
	`${key}_ws.${workspaceId}`;

export default function useSaveTableViews(
	key: string,
): UseQueryResult<SavedTable[] | undefined, Error> {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useSaveTableViews: workspaceId is not defined");
	}

	const keyWithWs = getKeyWithWs(workspaceId, key);

	return useQuery({
		queryKey: userSavedTableViews.single(workspaceId, keyWithWs),
		queryFn: () =>
			apiGet<SavedTable[]>("self/views", {
				params: { key: keyWithWs },
			}),
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		placeholderData: keepPreviousData,
	});
}

type UpdateSaveTable = Omit<SavedTable, "id">;

export function useUpdateSavedTableViews(
	preferenceKey: string,
): UseMutationResult<
	AxiosResponse<UpdateSaveTable> | { data: undefined },
	AxiosError<ExpectedErrorResponseType>,
	{ data: UpdateSaveTable; key: string }
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	const intl = useIntl();

	const keyWithWs = getKeyWithWs(workspaceId, preferenceKey);

	if (!workspaceId) {
		throw new Error("useUpdateSavedTableViews: workspaceId is not defined");
	}

	return useMutation({
		mutationFn: ({ data, key }: { data: UpdateSaveTable; key: string }) =>
			apiPut(`self/views?id=${key}`, {
				name: data.name,
				settings: data.settings,
			}),

		onError: (error) => {
			toast.error(`Error: ${error?.response?.data?.message}`);
		},

		onSuccess: () => {
			toast.success(intl.formatMessage({ id: "SUCCESS_UPDATING_TABLE_VIEW" }));
			queryClient.invalidateQueries({
				queryKey: userSavedTableViews.single(workspaceId, keyWithWs),
			});
		},
	});
}

export function useCreateSavedTableViews(
	preferenceKey: string,
): UseMutationResult<
	AxiosResponse<SavedTable> | { data: undefined },
	AxiosError<ExpectedErrorResponseType>,
	{
		settings: UserPreference;
		name: string;
		successCallback: (data: SavedTable) => void;
	}
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	const intl = useIntl();

	if (!workspaceId) {
		throw new Error("useCreateSavedTableViews: workspaceId is not defined");
	}

	const keyWithWs = getKeyWithWs(workspaceId, preferenceKey);

	return useMutation({
		mutationFn: ({
			settings,
			name,
		}: {
			settings: UserPreference;
			name: string;
			successCallback: (data: SavedTable) => void;
		}) => apiPost(`self/views?key=${keyWithWs}`, { name, settings }),

		onError: (error) => {
			toast.error(`Error: ${error?.response?.data?.message}`);
		},

		onSuccess: (response, { successCallback }) => {
			toast.success(intl.formatMessage({ id: "SUCCESS_CREATING_TABLE_VIEW" }));
			Promise.allSettled([
				queryClient.invalidateQueries({
					queryKey: userSavedTableViews.single(workspaceId, keyWithWs),
				}),
			])
				.then(() => {
					if (successCallback && response.data?.id) {
						successCallback(response.data);
					}
				})
				.catch((error) => {
					toast.error(`Error: ${error?.response?.data?.message}`);
				});
		},
	});
}

export function useDeleteSavedTableViews(
	preferenceKey: string,
): UseMutationResult<
	AxiosResponse<unknown> | { data: undefined },
	AxiosError<ExpectedErrorResponseType>,
	{ key: string; successCallback?: () => void }
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	const intl = useIntl();

	const keyWithWs = getKeyWithWs(workspaceId, preferenceKey);

	if (!workspaceId) {
		throw new Error("useDeleteSavedTableViews: workspaceId is not defined");
	}

	return useMutation({
		mutationFn: ({ key }: { key: string; successCallback?: () => void }) =>
			apiDelete(`self/views?id=${key}`),

		onError: (error) => {
			toast.error(`Error: ${error?.response?.data?.message}`);
		},

		onSuccess: (_, { successCallback }) => {
			toast.success(intl.formatMessage({ id: "SUCCESS_DELETING_TABLE_VIEW" }));
			if (successCallback) {
				successCallback();
			}
			queryClient.invalidateQueries({
				queryKey: userSavedTableViews.single(workspaceId, keyWithWs),
			});
		},
	});
}
