import type {
	ActiveView,
	UserPreference,
	ViewType,
} from "@metronome/types/UserPreference";
import React, { useMemo } from "react";

type ActiveViewModel = {
	activeView: ActiveView | undefined;
	setActiveView: React.Dispatch<React.SetStateAction<ActiveView | undefined>>;
};

const ActiveViewContext = React.createContext<ActiveViewModel>({
	activeView: undefined,
	setActiveView: () => {},
});

type ActiveViewProviderProps = {
	preferences: UserPreference | undefined;
	children: React.ReactNode;
};
export const ActiveViewProvider: React.FC<ActiveViewProviderProps> = ({
	children,
	preferences,
}) => {
	const [activeView, setActiveView] = React.useState<ActiveView | undefined>(
		preferences?.lastSavedViewSelected
			? {
					type: preferences.lastSavedViewType as ViewType,
					id: preferences.lastSavedViewSelected ?? "",
				}
			: undefined,
	);

	const value = useMemo(
		() => ({
			activeView,
			setActiveView,
		}),
		[activeView],
	);

	return (
		<ActiveViewContext.Provider value={value}>
			{children}
		</ActiveViewContext.Provider>
	);
};

export const useActiveViewData = () => React.useContext(ActiveViewContext);
