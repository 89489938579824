import type React from "react";
import { Avatar } from "@metronome/components/Avatar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@metronome/components/ui/popover";
import { getInitials } from "@metronome/utils/formatText";
import { useName } from "@metronome/utils/user";
import type { IParty } from "@metronome/types/Party";
import { CheckCircledIcon, LapTimerIcon } from "@radix-ui/react-icons";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "./ui/select";
import { FormattedMessage } from "react-intl";
import type { PartyAssignment } from "@metronome/types/PartyAssignment";
import clsx from "clsx";
import { usePartyAvailability } from "@metronome/api/useParty";
import LoadingMetronome from "./LoadingMetronome";

function isExtendedUser(user: IParty | ExtendedIParty): user is ExtendedIParty {
	return !!(
		(user as ExtendedIParty).status &&
		(user as ExtendedIParty).origin &&
		(user as ExtendedIParty).roleName
	);
}

type ExtendedIParty = IParty & {
	status: PartyAssignment["status"];
	origin: PartyAssignment["origin"];
	roleName: string;
};

const AvatarRingStatus: React.FC<{
	status: PartyAssignment["status"];
	children: React.ReactNode;
}> = ({ children, status }) => {
	if (status)
		return (
			<div
				className={clsx({
					"w-fit relative rounded-full border-2": true,
					"border-green-600": status === "acknowledged",
					"border-blue-500 border-dashed": status === "waitlist",
					"border-red-700 ": status === "rejected",
					"border-transparent": status === "undefined",
				})}
			>
				{children}
			</div>
		);
	return <>{children}</>;
};

const AvatarStatusIcon: React.FC<{
	status: PartyAssignment["status"];
}> = ({ status }) => {
	return (
		<div className="absolute bg-white bottom-0 -right-1 rounded-full">
			{status === "acknowledged" && (
				<CheckCircledIcon className="text-green-600" />
			)}
			{status === "waitlist" && <LapTimerIcon className="text-blue-500" />}
		</div>
	);
};

const SelectPartyAvailability: React.FC<{ partyId: string }> = ({
	partyId,
}) => {
	const { data, isLoading } = usePartyAvailability(partyId);

	if (isLoading) {
		return <LoadingMetronome />;
	}
	return (
		<Select defaultValue={data?.isAvailable ? "available" : "unavailable"}>
			<SelectTrigger>
				<SelectValue placeholder="Select availability" />
			</SelectTrigger>
			<SelectContent>
				<SelectItem value="unavailable">not available</SelectItem>
				<SelectItem value="available">available</SelectItem>
				<SelectItem value="rejected">Rejected</SelectItem>
			</SelectContent>
		</Select>
	);
};

const UserPopoverComplete: React.FC<{
	party: IParty | ExtendedIParty;
	size?: number;
}> = ({ party, size = 32 }) => {
	const initials = getInitials(party?.firstName, party?.lastName);
	const status = isExtendedUser(party) ? party.status : "undefined";
	return (
		<Popover>
			<PopoverTrigger className="p-0 m-0 bg-transparent">
				<AvatarRingStatus status={status}>
					<Avatar size={size} src={party.profilePictureUri} alt={initials} />
					<AvatarStatusIcon status={status} />
				</AvatarRingStatus>
			</PopoverTrigger>
			<PopoverContent>
				<div className="flex items-center gap-2 text-base">
					<AvatarRingStatus status={status}>
						<Avatar
							size={48}
							src={party.profilePictureUri}
							alt={getInitials(party?.firstName, party?.lastName)}
						/>
						<AvatarStatusIcon status={status} />
					</AvatarRingStatus>
					<div className="truncate">
						<p className="font-bold">{useName(party)}</p>
						<a className="text-xs" href={`mailto:${party.email}`}>
							{party.email}
						</a>
					</div>
				</div>
				<div className="pt-4 pb-2 grid grid-cols-2 gap-2 items-center">
					<SelectPartyAvailability partyId={party.id} />
					{isExtendedUser(party) && party.roleName}
				</div>
				{isExtendedUser(party) && (
					<span className="text-slate-600">
						<FormattedMessage id="SOURCE" />:{" "}
						<FormattedMessage id={party.origin} />
					</span>
				)}
			</PopoverContent>
		</Popover>
	);
};

export const UserPopover: React.FC<{ user: IParty; size?: number }> = ({
	user,
	size,
}) => {
	if (user) return <UserPopoverComplete party={user} size={size} />;
	return <span />;
};
