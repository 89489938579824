import React from "react";
import { useIntl } from "react-intl";
import { ControlProps } from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { InputError } from "./InputError";
import { InputInstructions } from "./InputInstructions";
import { Label } from "../ui/label";
import { Input } from "../ui/input";

const IntegerControl = (props: ControlProps): JSX.Element => {
	const intl = useIntl();
	const { errors, id, label, handleChange, path, data, required, schema } =
		props;
	return (
		<div className="has-validation mb-3">
			<div className="grid w-full max-w-sm items-center gap-1.5">
				<Label htmlFor={`${id}-input`}>
					{label}
					{!!required && "*"}{" "}
					{intl.formatMessage({ id: "INTEGER_PLACEHOLDER" })}
				</Label>
				<Input
					id={`${id}-input`}
					type="number"
					onChange={(ev) => handleChange(path, ev.target.valueAsNumber)}
					placeholder={intl.formatMessage({ id: "INTEGER_PLACEHOLDER" })}
					value={data}
				/>
				<InputInstructions format={schema.format} />{" "}
			</div>
			<span className="text-red-600">
				{!!errors && !!data && <InputError error={errors} />}
			</span>
		</div>
	);
};

export default withJsonFormsControlProps(IntegerControl);
