import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import type { DialogContentProps } from "@radix-ui/react-dialog";
import clsx from "clsx";
import React from "react";

import styles from "./dialog.module.scss";

interface CustomProps extends DialogContentProps {
	size?: "small" | "medium" | "large";
	className?: string;
}

export const DialogContent = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Content>,
	CustomProps
>(({ children, className, size = "medium", ...props }, forwardedRef) => (
	<DialogPrimitive.Portal>
		<DialogPrimitive.Overlay className={styles.overlay} />
		<DialogPrimitive.Content
			{...props}
			className={clsx(
				{
					[styles.contentFullScreen]: size === "large",
					[styles.content]: size === "medium",
					[styles.contentSmall]: size === "small",
				},
				className,
			)}
			ref={forwardedRef}
		>
			{children}
			<DialogPrimitive.Close asChild aria-label="Close">
				<button
					type="button"
					onClick={(e) => e.stopPropagation()}
					className={styles.closeButton}
				>
					<FontAwesomeIcon icon={["fas", "times"]} />
				</button>
			</DialogPrimitive.Close>
		</DialogPrimitive.Content>
	</DialogPrimitive.Portal>
));

DialogContent.displayName = "DialogContent";

export const Dialog = DialogPrimitive.Root;

export const DialogTrigger = DialogPrimitive.Trigger;
