import { useQuery, queryOptions } from "@tanstack/react-query";
import { apiGet } from "./api";

type GoToResponse = {
	workspaceId: string;
	processInstanceId: string;
	stepInstanceId: string;
};

export type Params = {
	id: string;
	type: "step-instances" | "process-instances";
};

export const gotoQueryOptions = ({ type, id }: Params) =>
	queryOptions({
		queryKey: ["goto"],
		queryFn: () => apiGet<GoToResponse>(`/${type}/${id}/path`),
		gcTime: 0,
		staleTime: 0,
	});

export function useGoto({ id, type }: Params) {
	return useQuery(gotoQueryOptions({ type, id }));
}
