import { Avatar } from "@metronome/components/Avatar";
import DateTimeCell from "@metronome/components/DateCell";
import { Lozenge } from "@metronome/components/Lozenge";
import type { IStepInstanceView } from "@metronome/types/StepInstance";
import { groupBy } from "@metronome/utils/arrayUtils";
import type React from "react";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

type Props = {
	views?: IStepInstanceView[];
};

export const PartyViews: React.FC<Props> = ({ views }: Props) => {
	const listItemView = useMemo(
		() =>
			Object.values(
				groupBy(views ?? [], (item) => `${item.viewAt}-${item.party.id}`),
			)?.map((group) => (
				<li key={`${group[0].viewAt}${group[0].party.id}`}>
					<div className="flex flex-row justify-between">
						<Avatar src={group[0].party.profilePictureUri} />
						<DateTimeCell datetime={group[0].viewAt} />
					</div>
					<span className="flex flex-row gap-1 ms-10">
						{group.map((siv) => {
							return (
								<Lozenge
									appearance="default"
									key={`${siv.viewAt}${siv.party.id}`}
								>
									<FormattedMessage id={"ASSIGNEE"} />
								</Lozenge>
							);
						})}
					</span>
				</li>
			)),
		[views],
	);
	return (
		<>
			<strong>
				<FormattedMessage id="VIEW_BY" />
			</strong>
			{listItemView?.length ? (
				<ul className="flex flex-col gap-6 mt-4 ps-0">{listItemView}</ul>
			) : (
				<p>
					<FormattedMessage id="NO_VIEWS" />
				</p>
			)}
		</>
	);
};
