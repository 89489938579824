
import { useMetadataDefinitions } from "@metronome/api/useMetadataDefinitions";
import {
	Table,
	TableHeader,
	TableBody,
	TableCaption,
	TableRow,
	TableHead,
	TableCell,
} from "@metronome/components/ui/table";
import type { IStepInstance } from "@metronome/types/StepInstance";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";

type NodesReferencesProps = {
	streamId: string;
	nodeReferences: IStepInstance["nodeReferences"];
};
export const NodesReferences: React.FC<NodesReferencesProps> = ({
	nodeReferences,
	streamId,
}) => {
	const nodes = useMemo(() => {
		return nodeReferences
			?.map((nr) => nr.referencedNode)
			.sort((a, b) => a.tree.name.localeCompare(b.tree.name));
	}, [nodeReferences]);

	const { data: metaDataDefinitions } = useMetadataDefinitions({
		nodeIds: nodes.map((n) => n.id),
	});

	const values = nodes.flatMap((node) =>
		metaDataDefinitions?.map((def) =>
			node.metadataValues.find((v) => def.id === v.definition.id),
		),
	);

	if (nodes?.length === 0) {
		return null;
	}

	return (
		<Table className="bg-white">
			<TableCaption>
				<FormattedMessage id="RESOURCES_LINKED" />
			</TableCaption>
			<TableHeader>
				<TableRow>
					<TableHead className="w-[100px]">
						<FormattedMessage id="TYPE" />
					</TableHead>
					<TableHead>
						<FormattedMessage id="NAME" />
					</TableHead>
					<TableHead>
						<FormattedMessage id="DESCRIPTION" />
					</TableHead>
					{metaDataDefinitions?.map((def) => (
						<TableHead key={def.id}>{def.name}</TableHead>
					))}
				</TableRow>
			</TableHeader>
			<TableBody>
				{nodes?.map((node) => (
					<TableRow key={node.id}>
						<TableCell>
							{/* <IconNodeCategory category={node.tree.type} /> */}
							{node.tree.type}
						</TableCell>
						<TableCell className="font-medium">{node.name}</TableCell>
						<TableCell>{node.tree.description}</TableCell>
						{values.map((v) =>
							v ? (
								<TableCell key={v.id}>{v.value}</TableCell>
							) : // <EditableMetadataValues key={v.id} context="step-instances" contextId={} />
							null,
						)}
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};