import type React from "react";
import { FormattedMessage } from "react-intl";

import { useMetadataDefinitions } from "@metronome/api/useMetadataDefinitions";
import {
	CollapsibleContent,
	CollapsibleRoot,
	CollapsibleTrigger,
} from "@metronome/components/Collapsible";
import DisplayDoerType from "@metronome/components/DisplayDoerType";
import { Tooltip } from "@metronome/components/Tooltip";
import { Button } from "@metronome/components/ui/button";
import type {
	IBusinessDimensionNode,
	ILightBusinessDimensionNode,
	IMetadataValue,
} from "@metronome/types/BusinessDimension";
import type { Context } from "@metronome/types/Context";
import { type IDoer, doerEquals } from "@metronome/types/Doer";
import type { IMetadataDefinition } from "@metronome/types/MetadataDefinition";

import { DisplayMetadataValue } from "./DisplayMetadataValues";
import { EditableMetadataValues } from "./EditableMetadataValues";
import styles from "./metadatalist.module.scss";
import { Badge } from "@metronome/components/ui/badge";

/**
 * getEditableValues
 * @param node
 * @param definition
 * @returns IMetadataValue[]
 *
 * Matches the definition previously filtered with the metadata values currently in the node.metadata object
 *
 */
const getEditableValues = (
	node: IBusinessDimensionNode | ILightBusinessDimensionNode,
	definition?: IMetadataDefinition,
): IMetadataValue[] =>
	node.metadataValues.filter(
		(v) => v.definition.id === definition?.id && v.createdBy.type === "user",
	);

const getFilteredMetaDataDefinitions = (
	node: IBusinessDimensionNode | ILightBusinessDimensionNode,
	definitions?: IMetadataDefinition[],
): IMetadataDefinition[] =>
	definitions?.filter((def) => def.tree.id === node.tree.id) ?? [];

/**
 * getEmptyDefaultFilteredMetadataDefinition
 * @param node
 * @param definitions
 * @returns IMetadataDefinition[]
 *
 * This returns the metadata definitions for which we don't have a value.
 * => we have the definition, but the value doesn't exist. it hasn't been set by the user
 *
 */
const getEmptyDefaultFilteredMetadataDefinition = (
	node: IBusinessDimensionNode | ILightBusinessDimensionNode,
	definitions?: IMetadataDefinition[],
): IMetadataDefinition[] =>
	getFilteredMetaDataDefinitions(node, definitions).filter(
		(def) =>
			node.metadataValues.filter((v) => v.definition.id === def.id).length ===
			0,
	);

const isBusinessDimensionNode = (
	value: IBusinessDimensionNode | ILightBusinessDimensionNode,
): value is IBusinessDimensionNode => {
	return (value as IBusinessDimensionNode).labels !== undefined;
};

type MetadataListProps = {
	context: Context;
	contextId: string;
	businessDimension: IBusinessDimensionNode | ILightBusinessDimensionNode;
};

export const MetadataList: React.FC<MetadataListProps> = ({
	contextId,
	context,
	businessDimension,
}) => {
	const { data: metaDataDefinitions } = useMetadataDefinitions({
		nodeIds: [businessDimension.id],
	});

	const nodeByDoerAndByMetadata = businessDimension.metadataValues.reduce(
		(acc: { doer: IDoer; values: IMetadataValue[] }[], curr) => {
			const index = acc.findIndex((a) => doerEquals(a.doer, curr.createdBy));
			if (index === -1) {
				acc.push({ doer: curr.createdBy, values: [curr] });
			} else {
				acc[index].values.push(curr);
			}
			return acc;
		},
		[],
	);

	if (!businessDimension) {
		return (
			<span>
				<FormattedMessage id="NO_BUSINESS_DIMENSIONS" />
			</span>
		);
	}

	if (!metaDataDefinitions || !metaDataDefinitions?.length) {
		return (
			<div>
				<strong>
					<FormattedMessage id="METADATA" />
				</strong>
				<br />
				<FormattedMessage id="METADATA_NO_VALUE" />
			</div>
		);
	}

	return (
		<div className="flex flex-col gap-4">
			<div className="p-2 rounded shadow-sm bg-background">
				<div className="flex flex-row items-center">
					{businessDimension?.description ? (
						<Tooltip
							key={businessDimension.id}
							content={businessDimension.description}
						>
							<span className="font-bold text-base">{`${businessDimension.name}`}</span>
						</Tooltip>
					) : (
						<div>
							<span
								title={businessDimension.name}
								className="font-bold text-base truncate me-2"
							>
								{`${businessDimension.name}`}
							</span>
							<span>
								{isBusinessDimensionNode(businessDimension)
									? businessDimension.labels.map((label) => (
											<Badge key={label} variant="secondary" className="me-2">
												{label}
											</Badge>
										))
									: null}
							</span>
						</div>
					)}
				</div>
				<div className={styles.container}>
					{nodeByDoerAndByMetadata?.map((metadataByApp) => (
						<div key={`app-${metadataByApp.doer.type}`}>
							<div className="flex flex-col max-w-[300px] gap-2 overflow-hidden relative mt-4 mx-auto">
								<div className="relative self-center after:content-[''] after:absolute after:w-px after:h-4 after:border-gray-300 after:border-l after:border-dotted after:inset-1/2	after:top-full	">
									<DisplayDoerType doer={metadataByApp.doer} />
								</div>
								<div className="flex flex-col gap-2 py-2 rounded">
									{getFilteredMetaDataDefinitions(
										businessDimension,
										metaDataDefinitions,
									).map((def) => {
										const valuesByDef = metadataByApp.values.filter(
											(metaValue) => metaValue.definition.id === def.id,
										);
										return valuesByDef.length ? (
											<div key={`definition-${def.id}`}>
												{valuesByDef.map((v) => {
													return def.canBeEdited ? (
														<EditableMetadataValues
															nodeId={businessDimension.id}
															key={def.id}
															context={context}
															contextId={contextId}
															definition={def}
															values={v}
														/>
													) : (
														<DisplayMetadataValue
															definition={def}
															key={v.id}
															metadataValue={v}
														/>
													);
												})}
											</div>
										) : undefined;
									})}
								</div>
							</div>
						</div>
					))}
					{!!getEmptyDefaultFilteredMetadataDefinition(
						businessDimension,
						metaDataDefinitions,
					).length && (
						<CollapsibleRoot className="text-center">
							<CollapsibleTrigger asChild>
								<Button onClick={() => null} variant={"link"}>
									<FormattedMessage id="VIEW_EMPTY_METADATA" />
								</Button>
							</CollapsibleTrigger>
							<CollapsibleContent>
								<div
									className={styles.gridContainerMetadata}
									key="app-empty-default"
								>
									{getEmptyDefaultFilteredMetadataDefinition(
										businessDimension,
										metaDataDefinitions,
									).map((def) => {
										const valuesByUsers = getEditableValues(
											businessDimension,
											def,
										);
										if (!valuesByUsers.length)
											return (
												<EditableMetadataValues
													nodeId={businessDimension.id}
													key={def.id}
													context={context}
													contextId={contextId}
													definition={def}
													values={valuesByUsers[0]}
												/>
											);
									})}
								</div>
							</CollapsibleContent>
						</CollapsibleRoot>
					)}
				</div>
			</div>
		</div>
	);
};
