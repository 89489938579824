import {
	EEventResolution,
	EMilestoneResolution,
	EResolution,
} from "@metronome/types/Resolution";
import type { IStepInstance } from "@metronome/types/StepInstance";

export const getResolutionOptions = (
	stepInstance?: IStepInstance,
): EResolution[] => {
	switch (stepInstance?.type) {
		case "task":
			return EResolution.options.filter(
				(resolution) => resolution !== stepInstance?.resolution,
			);
		case "event":
			return EEventResolution.options.filter(
				(resolution) => resolution !== stepInstance?.resolution,
			);
		case "milestone":
			return EMilestoneResolution.options.filter(
				(resolution) => resolution !== stepInstance?.resolution,
			);
		default:
			return EResolution.options.filter(
				(resolution) => resolution !== stepInstance?.resolution,
			);
	}
};

export const getIsResolutionDoneOrCancel = (
	resolution: IStepInstance["resolution"],
): boolean =>
	resolution === EResolution.enum.done ||
	resolution === EResolution.enum.cancelled;
