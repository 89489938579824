import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { AxiosResponse } from "axios";
import {
	useMutation,
	type UseMutationResult,
	useQueryClient,
} from "@tanstack/react-query";
import { apiPut } from "./api";
import { processInstanceKeys } from "./useProcessInstance";
import { stepInstanceKeys } from "./useStepInstance";
import type { Context } from "@metronome/types/Context";
import { useOrganizationData } from "@metronome/context/OrganizationData";

type FlagPayload = {
	isFlagged: boolean;
	id: string;
	context: Context;
	parentId?: string;
};

export function useFlagMutation(): UseMutationResult<
	AxiosResponse<unknown> | { data: undefined },
	Error,
	FlagPayload
> {
	const workspaceId = useWorkspaceId();
	const { activeOrganization } = useOrganizationData();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useFlagMutation: workspaceId is not defined");
	}

	return useMutation({
		mutationFn: ({ isFlagged, id, context }: FlagPayload) =>
			apiPut(`ws/${workspaceId}/${context}/${id}/flag`, {
				isFlagged,
			}),

		onSuccess: (_, { context }) => {
			if (context === "process-instances") {
				queryClient.invalidateQueries({
					queryKey: processInstanceKeys.all(workspaceId),
				});
				const myProcess = processInstanceKeys.myProcessInstancesAll(
					workspaceId,
					activeOrganization ?? null,
				);
				queryClient.invalidateQueries({
					queryKey: myProcess,
				});
			} else {
				queryClient.invalidateQueries({
					queryKey: stepInstanceKeys.all(workspaceId),
				});
			}
		},
	});
}
