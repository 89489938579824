import { useCreateWorkspaceViews } from "@metronome/api/useWorkspaceViews";
import { Button } from "@metronome/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@metronome/components/ui/dialog";
import { Input } from "@metronome/components/ui/input";
import { Label } from "@metronome/components/ui/label";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

type CreateFilterStreamsProps = {
	filterCreation: boolean;
	selectedStreams: string[];
	resetFilterSelection: () => void;
	preferenceKey: string;
	setSelectedCustomFilter: (value: string) => void;
};

export const CreateFilterStreams: React.FC<CreateFilterStreamsProps> = ({
	filterCreation,
	selectedStreams,
	resetFilterSelection,
	preferenceKey,
	setSelectedCustomFilter,
}) => {
	const intl = useIntl();
	const { mutate: createWorkspaceView } =
		useCreateWorkspaceViews(preferenceKey);

	const [viewName, setViewName] = useState<string>("");
	const [open, setOpen] = useState<boolean>(false);

	const saveStreamsSettings = useCallback(
		() =>
			createWorkspaceView({
				settings: {
					streamsIds: selectedStreams,
				},
				name: viewName,
				successCallback: (data) => {
					setViewName("");
					resetFilterSelection();
					setOpen(false);
					setSelectedCustomFilter(data.id);
				},
			}),
		[
			selectedStreams,
			viewName,
			resetFilterSelection,
			createWorkspaceView,
			setSelectedCustomFilter,
		],
	);

	return (
		<div
			className={`z-20 flex flex-row items-center justify-between bg-primary text-white fixed bottom-2 p-4 shadow rounded w-[inherit] transition-transform ${
				filterCreation
					? "opacity-100 translate-y-0"
					: "opacity-0 translate-y-full"
			}`}
		>
			<span className="text-base font-semibold">
				{selectedStreams.length} <FormattedMessage id="STREAMS_SELECTED" />
			</span>
			<Dialog open={open} onOpenChange={setOpen}>
				<DialogTrigger asChild>
					<Button
						variant="secondary"
						disabled={!selectedStreams.length}
						className="ms-auto w-fit"
					>
						<FormattedMessage id="CONTINUE" />
					</Button>
				</DialogTrigger>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							<FormattedMessage id="CREATE_A_FILTER" />
						</DialogTitle>
						<DialogDescription>
							<FormattedMessage id="CREATE_A_FILTER_DESCRIPTION" />
						</DialogDescription>
					</DialogHeader>
					<div className="grid gap-4 py-4">
						<div className="grid grid-cols-4 items-center gap-4">
							<Label htmlFor="name" className="text-right">
								<FormattedMessage id="NAME" />
							</Label>
							<Input
								id="name"
								placeholder={intl.formatMessage({ id: "MY_FILTER_NAME" })}
								className="col-span-3"
								value={viewName}
								onChange={(e) => setViewName(e.target.value)}
							/>
						</div>
						<div>
							{selectedStreams.length}{" "}
							<FormattedMessage id="STREAMS_SELECTED" />
						</div>
					</div>
					<DialogFooter>
						<Button onClick={saveStreamsSettings} type="submit">
							<FormattedMessage id="SAVE" />
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
			<Button onClick={resetFilterSelection} className="ms-4" variant="ghost">
				<FormattedMessage id="CANCEL" />
			</Button>
		</div>
	);
};
