import type { AxiosError, AxiosResponse } from "axios";
import {
	type UseMutationResult,
	type UseQueryResult,
	useMutation,
	useQuery,
	useQueryClient,
	keepPreviousData,
	queryOptions,
} from "@tanstack/react-query";
import { toast } from "sonner";
import { z } from "zod";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { IBusinessDimensionNode } from "@metronome/types/BusinessDimension";
import type { IStage } from "@metronome/types/Gate";
import type {
	IProcessInstance,
	ProcessState,
	Time,
	UserSelectedProcessState,
} from "@metronome/types/ProcessInstance";
import { useIntl } from "react-intl";

import { apiGet, apiPut } from "./api";
import type { ExpectedErrorResponseType } from "./api";
import { useOrganizationData } from "@metronome/context/OrganizationData";
import type { IPaginatedResults } from "@metronome/types/PaginatedResponse";

const processStreamsInstances = "processStreamsInstances";

type ProcessParams = Partial<{
	batchId: string;
	clusterId: string;
	processStreamId: string;
	pathname: string;
	page: number;
	pageSize: number;
	search: string;
	sortBy: string;
	sortOrder: "asc" | "desc";
	labelIds: string[];
	responsibleIds: string[];
	plannedStartFrom: string;
	plannedStartTo: string;
	plannedEndFrom: string;
	plannedEndTo: string;
	states: ProcessState[];
	plannedStartTimes: Time[];
}>;

interface BaseParams {
	workspaceId: string;
	processInstanceId: string;
}

export const processInstanceKeys = {
	all: (workspaceId: string) => [workspaceId, "process-instances"] as const,
	myProcessInstancesAll: (
		workspaceId: string,
		organizationId?: string | null,
	) => [workspaceId, organizationId ?? "", "my-process-instances"] as const,
	processInstancesFromBatch: (
		workspaceId: string,
		batchId?: string,
		organizationId?: string | null,
		params?: ProcessParams,
	) =>
		[
			workspaceId,
			organizationId ?? "",
			"process-instances-from-batch",
			batchId,
			params,
		] as const,
	processInstancesFromCluster: (
		workspaceId: string,
		clusterId?: string,
		organizationId?: string | null,
		params?: ProcessParams,
	) =>
		[
			workspaceId,
			organizationId ?? "",
			"process-instances-from-cluster",
			clusterId,
			params,
		] as const,
	single: (workspaceId: string, processInstanceId?: string) =>
		[...processInstanceKeys.all(workspaceId), processInstanceId] as const,
	stages: (workspaceId: string, processInstanceId?: string) =>
		[
			...processInstanceKeys.single(workspaceId, processInstanceId),
			"stages",
		] as const,
	base: (
		workspaceId: string,
		processStreamsInstances: "processStreamsInstances",
		processStreamId?: string,
	) => [workspaceId, processStreamsInstances, processStreamId] as const,
	params: (
		workspaceId: string,
		processStreamsInstances: "processStreamsInstances",
		processStreamId: string,
		filters: ProcessParams,
	) => [
		...processInstanceKeys.base(
			workspaceId,
			processStreamsInstances,
			processStreamId,
		),
		filters,
	],
};

export const stagesQueryOptions = (
	workspaceId: string,
	processInstanceId?: string,
) =>
	queryOptions({
		queryKey: processInstanceKeys.stages(workspaceId, processInstanceId),
		queryFn: () =>
			apiGet<IStage[]>(
				`ws/${workspaceId}/process-instances/${processInstanceId}/steps`,
			),
		enabled: !!processInstanceId,
	});

export function useStages(
	processInstanceId?: string,
): UseQueryResult<IStage[] | undefined, Error> {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useStages: workspaceId is not defined");
	}
	return useQuery(stagesQueryOptions(workspaceId, processInstanceId));
}

export function useStepBulkResolution(
	processInstanceId: string,
): UseMutationResult<unknown, AxiosError<ExpectedErrorResponseType>, string> {
	const workspaceId = useWorkspaceId();
	const intl = useIntl();
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (stepId: string) =>
			apiPut(
				`ws/${workspaceId}/process-instances/${processInstanceId}/steps/${stepId}/resolve`,
				{},
			),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: processInstanceKeys.stages(workspaceId, processInstanceId),
			});
			toast.success(intl.formatMessage({ id: "SUCCESS" }));
		},

		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}

export const singleProcessOptions = (
	workspaceId: string,
	processInstanceId: string,
) =>
	queryOptions({
		queryKey: processInstanceKeys.single(workspaceId, processInstanceId),
		queryFn: () =>
			apiGet<IProcessInstance>(
				`ws/${workspaceId}/process-instances/${processInstanceId}`,
			),
	});

export function useSingleProcessInstance(processInstanceId: string) {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useProcessInstance: workspaceId is not defined");
	}
	return useQuery(singleProcessOptions(workspaceId, processInstanceId));
	// onError: (error) => {
	// 	if (error.response?.status === 404) {
	// 		toast.info(
	// 			intl.formatMessage({ id: "PROCESS_NOT_FOUND_REDIRECTING" }),
	// 		);
	// 		navigate({ to: "/" });
	// 	}
	// },
}

export function useProcessInstances(
	{
		processStreamId,
		pathname,
		page,
		pageSize,
		search,
		sortBy,
		sortOrder,
		plannedStartTimes,
		plannedStartFrom,
		plannedStartTo,
		plannedEndFrom,
		plannedEndTo,
		states,
	}: ProcessParams,
	enabled = true,
): UseQueryResult<IPaginatedResults<IProcessInstance> | undefined, Error> {
	const workspaceId = useWorkspaceId();
	const { activeOrganization: organizationId } = useOrganizationData();
	if (!workspaceId) {
		throw new Error("useProcessStreamsInstances: workspaceId is not defined");
	}

	const filters = {
		search,
		page,
		pageSize,
		sortBy,
		sortOrder,
		pathname,
		states,
		plannedStartFrom,
		plannedStartTo,
		plannedEndFrom,
		plannedEndTo,
	};
	return useQuery({
		queryKey: processInstanceKeys.params(
			workspaceId,
			processStreamsInstances,
			processStreamId as string,
			filters,
		),
		queryFn: () =>
			apiGet<IPaginatedResults<IProcessInstance> | undefined>(
				`ws/${workspaceId}/process-streams/${processStreamId}/process-instances`,
				{
					params: {
						organizationId,
						states,
						plannedStartTimes,
						plannedStartFrom,
						plannedStartTo,
						plannedEndFrom,
						plannedEndTo,
						page,
						pageSize,
						search,
						sortBy,
						sortOrder,
					},
				},
			),
		enabled,
	});
}

export function useProcessInstancesFromBatch(
	params: ProcessParams,
): UseQueryResult<IPaginatedResults<IProcessInstance> | undefined, Error> {
	const workspaceId = useWorkspaceId();
	const { activeOrganization } = useOrganizationData();

	const { plannedStartTimes, states, ...filtered } = params;
	return useQuery({
		queryKey: processInstanceKeys.processInstancesFromBatch(
			workspaceId,
			params?.batchId,
			activeOrganization,
			filtered,
		),

		queryFn: () =>
			apiGet<IPaginatedResults<IProcessInstance> | undefined>(
				`/ws/${workspaceId}/process-streams/${params?.processStreamId}/batches/${params?.batchId}/process-instances`,
				{
					params: {
						...filtered,
						organizationId: activeOrganization,
					},
				},
			),
		placeholderData: keepPreviousData,
	});
}

export function useProcessInstancesFromCluster(
	params: ProcessParams,
): UseQueryResult<IPaginatedResults<IProcessInstance> | undefined, Error> {
	const workspaceId = useWorkspaceId();
	const { activeOrganization } = useOrganizationData();

	const { plannedStartTimes, states, ...filtered } = params;
	return useQuery({
		queryKey: processInstanceKeys.processInstancesFromCluster(
			workspaceId,
			params?.clusterId,
			activeOrganization,
			filtered,
		),

		queryFn: () =>
			apiGet<IPaginatedResults<IProcessInstance> | undefined>(
				`/ws/${workspaceId}/process-streams/${params?.processStreamId}/process-instances`,
				{
					params: {
						...filtered,
						organizationId: activeOrganization,
					},
				},
			),
		placeholderData: keepPreviousData,
	});
}

interface UpdateStartDateArgs extends BaseParams {
	date: Date;
}

const updateStartDate = async ({
	workspaceId,
	processInstanceId,
	date,
}: UpdateStartDateArgs): Promise<Partial<IProcessInstance>> => {
	const res = await apiPut(
		`ws/${workspaceId}/process-instances/${processInstanceId}/start-date`,
		{
			date: date.toISOString().slice(0, 10),
		},
	);
	return res.data as Partial<IProcessInstance>;
};

export function useUpdateStartDate(
	processInstanceId: string,
): UseMutationResult<Partial<IProcessInstance>, Error, Date> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	const intl = useIntl();
	if (!workspaceId) {
		throw new Error("useUpdateStartDate: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: (date: Date) =>
			updateStartDate({ workspaceId, processInstanceId, date }),

		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},

		onSuccess: (data) => {
			toast.success(intl.formatMessage({ id: "SUCCESS" }));
			queryClient.setQueryData(
				processInstanceKeys.single(workspaceId, processInstanceId),
				{
					...queryClient.getQueryData<IProcessInstance>(
						processInstanceKeys.single(workspaceId, processInstanceId),
					),
					...data,
				},
			);
			queryClient.invalidateQueries({
				queryKey: processInstanceKeys.stages(workspaceId, processInstanceId),
			});
		},
	});
}

const updateNodeName = async ({
	workspaceId,
	nodeId,
	name,
}: {
	workspaceId: string;
	nodeId: string;
	name: string;
}): Promise<IBusinessDimensionNode> => {
	const res = await apiPut(`ws/${workspaceId}/nodes`, {
		id: nodeId,
		name,
	});
	return res.data as IBusinessDimensionNode;
};

export function useUpdateNodeName(
	processInstanceId: string,
): UseMutationResult<
	Partial<IProcessInstance>,
	Error,
	{ nodeId: string; name: string }
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useUpdateNodeName: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: ({ nodeId, name }: { nodeId: string; name: string }) =>
			updateNodeName({ workspaceId, nodeId, name }),

		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: processInstanceKeys.single(workspaceId, processInstanceId),
			});
		},
	});
}

const Behavior = z.enum(["silent", "cancelAll", "resolveAll"]);

export function useCloseProcessInstance(): UseMutationResult<
	AxiosResponse<IProcessInstance> | { data: undefined },
	AxiosError<ExpectedErrorResponseType>,
	{ processInstanceId: string; type: UserSelectedProcessState }
> {
	const closeTypeMap = {
		open: { path: "open", params: {} },
		close: {
			path: "close",
			params: {
				behavior: Behavior.enum.silent,
			},
		},
		closeAndDone: {
			path: "/close",
			params: {
				behavior: Behavior.enum.resolveAll,
			},
		},
		closeAndCancel: {
			path: "close",
			params: {
				behavior: Behavior.enum.cancelAll,
			},
		},
	};
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useUpdateStartDate: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: ({ processInstanceId, type }) =>
			apiPut(
				`ws/${workspaceId}/process-instances/${processInstanceId}/${closeTypeMap[type].path}`,
				closeTypeMap[type].params,
			),

		onError: (error) => {
			toast.error(`Error: ${error?.response?.data?.message}`);
		},

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: processInstanceKeys.all(workspaceId),
			});
			queryClient.invalidateQueries({
				queryKey: [workspaceId, "single-step-instance"],
			});
		},
	});
}
