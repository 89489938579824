import useSelf from "@metronome/api/useSelf";
import WebsocketManager from "@metronome/utils/websocketUtil";
import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";

type Callback = (message?: unknown) => void;
type Subscribe = (channel: string, callback: Callback) => void;
type Unsubscribe = (channel: string) => void;
type WebSocketState = WebSocket["readyState"] | undefined;
const WebSocketContext = createContext<
	[Subscribe, Unsubscribe, WebSocketState] | null
>(null);

type Channel = Record<string, Callback>;
type WebSocketProviderProps = {
	children: React.ReactNode;
};
export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
	children,
}) => {
	const { data: self } = useSelf();
	const ws = useRef<WebsocketManager | null>(null);
	const channels = useRef<Channel>({});
	const [wsState, setWsState] = useState<WebSocketState>(undefined);

	const subscribe = useCallback((channel: string, callback: Callback) => {
		channels.current[channel] = callback;
	}, []);

	const unsubscribe = useCallback((channel: string) => {
		delete channels.current[channel];
	}, []);

	const updateWsState = useCallback((event: Event) => {
		setWsState((event.target as WebSocket)?.readyState);
	}, []);

	const onmessage = useCallback((event: MessageEvent | Event) => {
		let message: { channel: string; data: unknown };
		try {
			message = JSON.parse((event as MessageEvent).data);
		} catch (error) {
			console.error("Error parsing message", error);
			return;
		}
		if (message.channel in channels.current) {
			channels.current[message.channel](message.data);
		}
	}, []);

	useEffect(() => {
		if (self?.id && ws.current === null) {
			ws.current = new WebsocketManager({
				userId: self.id,
				eventListeners: {
					onopen: [updateWsState],
					onmessage: [updateWsState, onmessage],
					onerror: [updateWsState],
					onclose: [updateWsState],
				},
			});
		}
	}, [self?.id, onmessage, updateWsState]);

	const value: [Subscribe, Unsubscribe, WebSocketState] = useMemo(
		() => [subscribe, unsubscribe, wsState],
		[subscribe, unsubscribe, wsState],
	);

	return (
		<WebSocketContext.Provider value={value}>
			{children}
		</WebSocketContext.Provider>
	);
};

export const useWebsocketContext = () => {
	const context = useContext(WebSocketContext);
	if (!context) {
		throw new Error("useWebsocketContext must be used within a provider");
	}
	return context;
};
