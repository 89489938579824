import { useEffect } from "react";
import { useOrganizationData } from "@metronome/context/OrganizationData";

export default function useSetCurrentOrganization(
	organizationId: string | undefined,
): void {
	const { setCurrentOrganization } = useOrganizationData();

	useEffect(() => {
		setCurrentOrganization(organizationId);
	}, [organizationId, setCurrentOrganization]);
}
