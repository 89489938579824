import type { IDoer } from "./Doer";
import type { IModelReference } from "./ModelReference";

export interface IEntityHistory {
	id: string;
	performedBy: IDoer;
	performedAt: number;
	previousValue?: IValue;
	currentValue?: IValue;
	actionType: EActionType;
	reference?: IModelReference;
}

export enum EActionType {
	undefined = "undefined",
	flagAdded = "flagAdded",
	flagRemoved = "flagRemoved",
	resolutionChanged = "resolutionChanged",
	effectiveDateChanged = "effectiveDateChanged",
	dueDateChanged = "dueDateChanged",
	notLaterThanChanged = "notLaterThanChanged",
	startDateChanged = "startDateChanged",
	stateChanged = "stateChanged",
	noteAdded = "noteAdded",
	noteEdited = "noteEdited",
	attachmentAdded = "attachmentAdded",
	attachmentEdited = "attachmentEdited",
	formAdded = "formAdded",
	formEdited = "formEdited",
	agendaCompleted = "agendaCompleted",
	agendaUncompleted = "agendaUncompleted",
}

export interface IValue {
	type: EValueType;
	value: unknown;
	setByUser: boolean;
}

export enum EValueType {
	resolution = "resolution",
	date = "date",
	text = "text",
	party = "party",
	state = "state",
	dateTime = "dateTime",
}
