import { PartyAssignment } from "@metronome/types/PartyAssignment";
import {
	CheckCircledIcon,
	CrossCircledIcon,
	LapTimerIcon,
} from "@radix-ui/react-icons";

export const PartyStatus: React.FC<{ status: PartyAssignment["status"] }> = ({
	status,
}) => {
	switch (status) {
		case "acknowledged":
			return <CheckCircledIcon className="inline text-primary ms-1" />;
		case "waitlist":
			return <LapTimerIcon className="inline text-blue-600 ms-1" />;
		case "rejected":
			return <CrossCircledIcon className="inline ms-1 text-red-600" />;
	}
	return null;
};
