import type { FC } from "react";
import { FormattedMessage } from "react-intl";

type SchedulerErrorProps = {
	startDateAfterEndDate?: boolean;
};
export const SchedulerError: FC<SchedulerErrorProps> = ({
	startDateAfterEndDate,
}) => {
	return (
		<div className="min-h-96 flex flex-col items-center justify-center">
			<h2 className="font-bold text-primary text-lg">
				<FormattedMessage id="ERROR.TITLE.TIMELINE" />
			</h2>
			<p>
				{!!startDateAfterEndDate && (
					<FormattedMessage id="ERROR.DESC.TIMELINE" />
				)}
			</p>
		</div>
	);
};
