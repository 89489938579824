import React from "react";
import lozengStyles, { APPEARANCES } from "@metronome/constants/theme";

import styles from "./lozenge.module.scss";

type LozengeTypes = {
	appearance: APPEARANCES;
	children: React.ReactNode;
};

export const Lozenge = ({
	appearance = "default",
	children,
}: LozengeTypes): JSX.Element => (
	<span style={lozengStyles[appearance]} className={styles.container}>
		{children}
	</span>
);
