import type React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useAttachments from "@metronome/api/useAttachments";
import type { AttachmentRequirementAPI } from "@metronome/types/Attachment";

import { Attachment } from "@metronome/components/Attachment";
import { Button } from "@metronome/components/ui/button";
import { RequiredAttachmentsAsModal } from "@metronome/features/RequiredAttachments";
import { SendFileAttachmentAsDialog } from "@metronome/features/SendFileAttachment";
import { SendLinkAttachmentAsDialog } from "@metronome/features/SendLinkAttachment";
import type {
	Context,
	ProcessInstance,
	StepInstances,
} from "@metronome/types/Context";

type RequiredAttachmentsProps = {
	context: Context;
	contextId: string;
	attachmentRequirements: AttachmentRequirementAPI[];
};

const RequiredAttachments: React.FC<RequiredAttachmentsProps> = ({
	context,
	contextId,
	attachmentRequirements,
}) => {
	const intl = useIntl();
	const requiredAttachments = attachmentRequirements?.filter(
		(attachmentRequirement) => attachmentRequirement.attachment,
	);

	const missingRequiredAttachments = attachmentRequirements?.filter(
		(attachmentRequirement) => !attachmentRequirement.attachment,
	);

	if (!requiredAttachments?.length && !missingRequiredAttachments?.length)
		return null;
	return (
		<>
			{!!missingRequiredAttachments.length && (
				<RequiredAttachmentsAsModal {...{ context, contextId }}>
					<Button className="line-clamp-1 block">
						{missingRequiredAttachments?.length === 1
							? `${intl.formatMessage({
									id: "ONE_REQUIRED_ATTACHMENT_MISSING",
								})}: "${missingRequiredAttachments[0].name}"`
							: intl.formatMessage({ id: "SOME_REQUIRED_ATTACHMENTS_MISSING" })}
					</Button>
				</RequiredAttachmentsAsModal>
			)}
			{requiredAttachments.map((attachment) => (
				<Attachment
					key={attachment.id}
					attachment={attachment.attachment}
					context={context}
					contextId={contextId}
					isARequiredAttachment
				/>
			))}
		</>
	);
};

export const AttachmentsTab: React.FC<{
	context: Context;
	contextId: string;
}> = ({ context, contextId }) => {
	type ActualContext<T> = T extends "process-instances"
		? ProcessInstance
		: StepInstances;

	const { data: attachmentsAPI } = useAttachments<
		ActualContext<typeof context>
	>(context, contextId);

	if (
		(!Array.isArray(attachmentsAPI) &&
			!attachmentsAPI?.additional?.length &&
			!attachmentsAPI?.required?.length) ||
		(Array.isArray(attachmentsAPI) && attachmentsAPI.length === 0)
	)
		return (
			<div className="flex flex-col gap-6 items-center justify-content-center">
				<div className="flex flex-row gap-6">
					<SendFileAttachmentAsDialog
						context="process-instances"
						contextId={contextId}
					>
						<FormattedMessage id="SEND_ATTACHMENT_FILE" />
					</SendFileAttachmentAsDialog>

					<SendLinkAttachmentAsDialog
						context="process-instances"
						contextId={contextId}
						onSuccess={() => null}
					>
						<FormattedMessage id="SEND_ATTACHMENT_LINK" />
					</SendLinkAttachmentAsDialog>
				</div>
				<strong>
					<FormattedMessage id="NO_ATTACHMENTS" />
				</strong>
			</div>
		);

	return (
		<div className="flex flex-col ps-2 py-2 gap-2">
			{context === "process-instances" && (
				<div className="flex flex-row justify-center mb-4 gap-6">
					<SendFileAttachmentAsDialog
						context="process-instances"
						contextId={contextId}
					>
						<FormattedMessage id="SEND_ATTACHMENT_FILE" />
					</SendFileAttachmentAsDialog>
					<SendLinkAttachmentAsDialog
						context="process-instances"
						contextId={contextId}
						onSuccess={() => null}
					>
						<FormattedMessage id="SEND_ATTACHMENT_LINK" />
					</SendLinkAttachmentAsDialog>
				</div>
			)}
			{!Array.isArray(attachmentsAPI) && (
				<RequiredAttachments
					context={context}
					contextId={contextId}
					attachmentRequirements={attachmentsAPI.required}
				/>
			)}
			{!Array.isArray(attachmentsAPI) &&
				!!attachmentsAPI.additional?.length && (
					<div className="flex flex-col gap-2">
						{attachmentsAPI.additional.map((attachment) => (
							<Attachment
								attachment={attachment}
								key={attachment.id}
								context={context}
								contextId={contextId}
							/>
						))}
					</div>
				)}
			{Array.isArray(attachmentsAPI) && (
				<div>
					<div className="flex flex-col gap-2">
						{attachmentsAPI.map((attachment) => (
							<Attachment
								attachment={attachment}
								key={attachment.id}
								context={context}
								contextId={contextId}
							/>
						))}
					</div>
				</div>
			)}
		</div>
	);
};
