import { ErrorWithLogout } from "@metronome/components/Error";
import { Outlet, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/error/_error")({
	component: () => (
		<ErrorWithLogout>
			<Outlet />
		</ErrorWithLogout>
	),
});
