import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { zodResolver } from "@hookform/resolvers/zod";
import type { BaseParams } from "@metronome/api/useAttachments";
import { useCreateLinkAttachment } from "@metronome/api/useAttachments";
import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/Dialog";
import FloatingInput from "@metronome/components/FloatingInput";
import { AttachmentTypeAPI } from "@metronome/types/Attachment";
import type React from "react";
import { useState } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import styles from "./sendLinkAttachment.module.scss";
import { Button } from "@metronome/components/ui/button";

const schema = z.object({
	link: z.string().url(),
	name: z.string(),
	description: z.string().optional(),
});

type Inputs = z.infer<typeof schema>;

type SendLinkAttachmentProps = {
	context: BaseParams["context"];
	contextId: string;
	required?: boolean;
	specId?: string;
	onSuccess: () => void;
	children?: React.ReactNode;
};

export const SendLinkAttachment: React.FC<SendLinkAttachmentProps> = ({
	context,
	contextId,
	required,
	specId,
	onSuccess,
}) => {
	const intl = useIntl();
	const {
		reset,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>({
		mode: "onSubmit",
		resolver: zodResolver(schema),
	});

	const { mutateAsync: createAttachment, isPending } = useCreateLinkAttachment(
		context,
		contextId,
		onSuccess,
	);

	const hasErrors = Object.keys(errors).length;

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		createAttachment({
			type: AttachmentTypeAPI.enum.Link,
			link: data.link,
			name: data.name,
			description: data.description ?? "",
			specId: required ? specId : undefined,
		}).finally(() => {
			// setOpen(false);
			reset();
		});
	};
	return (
		<form className="flex flex-col gap-2 p-4" onSubmit={handleSubmit(onSubmit)}>
			<strong>
				<FormattedMessage id="SEND_LINK_ATTACHMENT" />
			</strong>
			<FloatingInput required label="link" {...register("link")} />
			{errors.link?.message && (
				<p className={styles.error}>{errors.link?.message}</p>
			)}
			<FloatingInput required label="name" {...register("name")} />
			<FloatingInput
				required={false}
				label="description"
				{...register("description")}
			/>
			<input
				type="submit"
				disabled={!!hasErrors || isPending}
				className={styles.submit}
				value={
					isPending
						? intl.formatMessage({ id: "LOADING" })
						: intl.formatMessage({ id: "CREATE_NODE.SUBMIT" })
				}
			/>
		</form>
	);
};

export const SendLinkAttachmentAsDialog: React.FC<SendLinkAttachmentProps> = ({
	context,
	contextId,
	children,
	required = false,
	specId,
	onSuccess,
}) => {
	const [open, setOpen] = useState(false);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button>
					{children || <FontAwesomeIcon fixedWidth icon={["fas", "link"]} />}
				</Button>
			</DialogTrigger>
			<DialogContent size="small">
				<SendLinkAttachment
					context={context}
					contextId={contextId}
					specId={specId}
					required={required}
					onSuccess={() => {
						setOpen(false);
						onSuccess?.();
					}}
				/>
			</DialogContent>
		</Dialog>
	);
};
