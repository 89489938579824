import { CheckCircledIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { useState } from "react";
import { useTimeout } from "usehooks-ts";

export const DoneIcon: React.FC<{ className?: string }> = ({ className }) => {
	const [visible, setVisible] = useState(true);

	const hide = () => setVisible(false);

	useTimeout(hide, 2000);
	return (
		<CheckCircledIcon
			className={clsx({
				"text-green-700 transition-all": true,
				"opacity-0 -translate-x-2": !visible,
				"opacity-100 translate-x-0": visible,
			})}
		/>
	);
};
