import { singleProcessStreamsOptions } from "@metronome/api/useProcessStreamInstances";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import * as v from "valibot";
import { ProcessStreamSummary } from "@metronome/features/ProcessStreamSummary";
import { useClusters } from "@metronome/api/useClusters";
import clsx from "clsx";
import { Badge } from "@metronome/components/ui/badge";
import type React from "react";
import { useCallback, useEffect, useState, type FC } from "react";
import type { IProcessStream } from "@metronome/types/ProcessStream";
import { useStepInstancesFromCluster } from "@metronome/api/useStepInstance";
import LoadingMetronome from "@metronome/components/LoadingMetronome";

import { ActivityLogIcon, CalendarIcon } from "@radix-ui/react-icons";
import { FormattedMessage } from "react-intl";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@metronome/components/ui/tabs";
import { useMetadataDefinitions } from "@metronome/api/useMetadataDefinitions";
import { SelectResource } from "@metronome/features/SelectResource";
import { GroupOfStepInstancesAsList } from "@metronome/features/GroupOfStepInstancesAsList";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@metronome/components/ui/popover";
import { Button } from "@metronome/components/ui/button";
import type { IMetadataDefinition } from "@metronome/types/MetadataDefinition";
import SwitchButton from "@metronome/components/Switch";
import { StepInstanceFromCluster } from "./-stepsTimelineGrouped";
import useUserPreferences from "@metronome/api/useUserPreference";
import { ActiveViewProvider } from "@metronome/context/ActiveViewData";
import { UserProcessConfigLoader } from "@metronome/features/UserProcessConfigLoader";
import type { ProcessContext } from "@metronome/types/ProcessInstance";
import { useResourceAllocationsDefs } from "@metronome/api/useResourceAllocations";

type SelectMetadataProps = {
	metadataDefinitions: IMetadataDefinition[] | undefined;
	selectedMetadataDefs: string[];
	setSelectedMetadataDefs: React.Dispatch<React.SetStateAction<string[]>>;
};

const SelectMetadata: React.FC<SelectMetadataProps> = ({
	metadataDefinitions,
	selectedMetadataDefs,
	setSelectedMetadataDefs,
}) => {
	const onCheckedChange = useCallback(
		(id: string) => {
			setSelectedMetadataDefs((prev) => {
				return selectedMetadataDefs.includes(id)
					? prev.filter((n) => n !== id)
					: [...prev, id];
			});
		},
		[setSelectedMetadataDefs, selectedMetadataDefs],
	);
	return (
		<Popover>
			<PopoverTrigger asChild className="ms-auto">
				<Button variant="secondary">
					<FormattedMessage id="METADATA" />
				</Button>
			</PopoverTrigger>
			<PopoverContent>
				<div>
					<h2 className="font-bold">
						<FormattedMessage id="METADATA" />
					</h2>
					{metadataDefinitions?.map((def) => (
						<div
							key={def.id}
							className="flex items-center justify-between py-2"
						>
							<div>
								<span className="block">{def.name}</span>
							</div>
							<SwitchButton
								checked={selectedMetadataDefs.includes(def.id)}
								onCheckedChange={() => onCheckedChange(def.id)}
							/>
						</div>
					))}
				</div>
			</PopoverContent>
		</Popover>
	);
};

const ClusterHeader = ({ streamId }: { streamId: string }) => {
	const [selectedMetadataDefs, setSelectedMetadataDefs] = useState<string[]>(
		[],
	);
	const { data: resources, isLoading: isResourceLoading } =
		useResourceAllocationsDefs(streamId);
	const { selectedCluster, nodeReferenceId } = Route.useSearch();

	const stepTemplateId = resources?.length
		? resources[0].stepTemplateId
		: undefined;

	const nodeReferenceDefinitionId = resources?.length
		? resources[0].nodeReferenceDefinitionId
		: undefined;

	const { data: metadataDefinitions } = useMetadataDefinitions({
		processStreamId: streamId,
	});

	const { data: clusteredStepInstances, isLoading } =
		useStepInstancesFromCluster(
			streamId,
			selectedCluster,
			stepTemplateId,
			nodeReferenceDefinitionId,
		);
	if (isLoading || isResourceLoading) return <LoadingMetronome />;

	if (resources?.length === 1 && clusteredStepInstances?.groups?.length) {
		return (
			<Tabs defaultValue="timeline" className="">
				<div className="flex items-center gap-2">
					<TabsList className="bg-transparent">
						<TabsTrigger
							className="py-2 data-[state=active]:bg-white"
							value="timeline"
						>
							<CalendarIcon />
						</TabsTrigger>
						<TabsTrigger
							className="py-2 data-[state=active]:bg-white"
							value="List"
						>
							<ActivityLogIcon />
						</TabsTrigger>
					</TabsList>
					<SelectResource
						resources={resources}
						nodeReferenceId={nodeReferenceId}
					/>
					<SelectMetadata
						selectedMetadataDefs={selectedMetadataDefs}
						setSelectedMetadataDefs={setSelectedMetadataDefs}
						metadataDefinitions={metadataDefinitions}
					/>
				</div>
				<TabsContent value="timeline">
					<StepInstanceFromCluster
						clusteredStepInstances={clusteredStepInstances}
						selectedMetadataDefs={selectedMetadataDefs}
					/>
				</TabsContent>
				<TabsContent value="List">
					<GroupOfStepInstancesAsList
						clusteredStepInstances={clusteredStepInstances}
						selectedMetadataDefs={selectedMetadataDefs}
					/>
				</TabsContent>
			</Tabs>
		);
	}
	if (resources?.length === 0)
		return <span>{"no resource allocation found"}</span>;
	return <span>{"no step instances were loaded"}</span>;
};

const ClusterMenu = ({
	streamId,
}: {
	streamId: string;
}) => {
	const { data: clusters } = useClusters(streamId);
	const navigate = useNavigate();
	const { selectedCluster } = Route.useSearch();

	useEffect(() => {
		if (!selectedCluster && clusters?.length) {
			navigate({
				from: "/$workspaceId/stream/$streamId/clustered",
				to: "/$workspaceId/stream/$streamId/clustered",
				search: {
					selectedCluster: clusters[0]?.id,
				},
			});
		}
	}, [selectedCluster, clusters, navigate]);

	if (clusters)
		return (
			<ul className="flex flex-row items-center gap-4">
				{clusters?.map((b) => {
					return (
						<li
							className={clsx({
								"rounded font-semibold border ": true,
								"bg-white text-primary border-primary border-solid":
									selectedCluster === b.id,
								"border-dashed text-slate-500 border-slate-400":
									selectedCluster !== b.id,
							})}
							key={b.id}
						>
							<button
								className="px-2 py-1.5 "
								type="button"
								onClick={() =>
									navigate({
										from: "/$workspaceId/stream/$streamId/clustered",
										to: "/$workspaceId/stream/$streamId/clustered",
										search: {
											selectedCluster:
												selectedCluster === b.id ? undefined : b.id,
										},
									})
								}
							>
								{b.context.name}
								<Badge
									variant={selectedCluster === b.id ? "default" : "outline"}
									className={
										selectedCluster === b.id
											? "text-xs px-1 ms-2 text-white border-primary"
											: "text-xs px-1 ms-2 text-slate-500 bg-white"
									}
								>
									{b.state}
								</Badge>
							</button>
						</li>
					);
				})}
			</ul>
		);
	return null;
};

type ClusterPageProps = {
	children: React.ReactNode;
	isLoading: boolean;
	processStream: IProcessStream;
};
const ClusterPage: FC<ClusterPageProps> = ({
	children,
	isLoading,
	processStream,
}) => {
	const state = "active"; // to refactor
	const PREFERENCE_KEY = `metronome.processStream.instances.active.${processStream.id}`;

	const { data: preferences, isLoading: isUserPrefLoading } =
		useUserPreferences(PREFERENCE_KEY);

	const statesMapper = {
		active: ["active" as const],
		upcoming: ["active" as const, "ready" as const],
		past: ["closed" as const, "archived" as const],
	};

	const timesMapper = {
		active: ["past" as const, "today" as const],
		upcoming: ["future" as const],
		past: ["past" as const],
	};

	if (isUserPrefLoading) {
		return <LoadingMetronome />;
	}
	return (
		<>
			<ProcessStreamSummary
				isLoading={isLoading}
				processStream={processStream}
			/>
			<Tabs defaultValue="clustered">
				<TabsList className="bg-white">
					<TabsTrigger className="font-bold" value="clustered">
						<FormattedMessage id="CLUSTERED" />
					</TabsTrigger>
					<TabsTrigger className="font-bold" value="list">
						<FormattedMessage id="LIST" />
					</TabsTrigger>
				</TabsList>
				<TabsContent value="list">
					<ActiveViewProvider preferences={preferences}>
						<UserProcessConfigLoader
							context={state as ProcessContext}
							key={state}
							states={statesMapper[state as keyof typeof statesMapper]}
							times={timesMapper[state as keyof typeof timesMapper]}
							processStreamId={processStream.id}
							preferenceKey={PREFERENCE_KEY}
							preferences={preferences}
						/>
					</ActiveViewProvider>
				</TabsContent>
				<TabsContent value="clustered">
					<div>
						<ClusterMenu streamId={processStream.id} />
						<div className="pt-8 ">{children}</div>{" "}
					</div>
				</TabsContent>
			</Tabs>
		</>
	);
};

const ClusteredParams = v.object({
	selectedCluster: v.optional(v.string()),
	nodeReferenceId: v.optional(v.string()),
});

export const Route = createFileRoute(
	"/$workspaceId/stream/$streamId/clustered",
)({
	validateSearch: (searchParams) => v.parse(ClusteredParams, searchParams),
	loader: async ({ context, params }) => {
		const { queryClient } = context;
		const { streamId, workspaceId } = params;

		const promises = [];

		promises.push(
			queryClient.ensureQueryData(
				singleProcessStreamsOptions(workspaceId, streamId),
			),
		);

		await Promise.all(promises);
		return;
	},
	component: () => {
		const { streamId, workspaceId } = Route.useParams();
		const { data: processStream, isLoading } = useQuery(
			singleProcessStreamsOptions(workspaceId, streamId),
		);
		if (processStream)
			return (
				<ClusterPage isLoading={isLoading} processStream={processStream}>
					<ClusterHeader streamId={streamId} />
				</ClusterPage>
			);
		return null;
	},
	pendingComponent: LoadingMetronome,
});
