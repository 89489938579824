import React from "react";
import { FormattedMessage } from "react-intl";
import * as changeCase from "change-case";

export const InputError = ({ error }: { error: string }): JSX.Element => {
	const errorKey = changeCase.snakeCase(error)?.toUpperCase();
	return (
		<span>
			<FormattedMessage id={errorKey} />
		</span>
	);
};
