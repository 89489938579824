import type { IMetadataDefinition } from "@metronome/types/MetadataDefinition";

export const getInputType = (
	type: IMetadataDefinition["type"],
): "number" | "text" | "date" | "email" | "url" => {
	if (type === "email") {
		return "email";
	}
	if (type === "url") {
		return "url";
	}
	if (type === "date") {
		return "date";
	}
	if (type === "number" || type === "integer") {
		return "number";
	}
	return "text";
};
