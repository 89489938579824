import React from "react";
import { JsonSchema } from "@jsonforms/core";
import { FormattedMessage } from "react-intl";

const getI18nFormat = (format: JsonSchema["format"]): string => {
	switch (format) {
		case "uri":
			return "FORMS.URI_FORMAT";
		case "date":
			return "FORMS.DATE_FORMAT";
		case "email":
			return "FORMS.EMAIL_FORMAT";
		default:
			return "";
	}
};

export const InputInstructions = ({
	format,
}: {
	format: JsonSchema["format"];
}): JSX.Element => {
	const i18nFormat = getI18nFormat(format);
	return <span>{!!i18nFormat && <FormattedMessage id={i18nFormat} />}</span>;
};
