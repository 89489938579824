import * as RadixSwitch from "@radix-ui/react-switch";
import { forwardRef } from "react";
import clsx from "clsx";
import styles from "./switch.module.scss";

export const SwitchButton = forwardRef<
	React.ElementRef<typeof RadixSwitch.Root>,
	React.ComponentPropsWithoutRef<typeof RadixSwitch.Root>
>((props, ref) => (
	<RadixSwitch.Root
		className={clsx({
			[styles.root]: true,
			[styles.rootChecked]: props.checked,
		})}
		ref={ref}
		{...props}
	>
		<RadixSwitch.Thumb
			className={clsx({
				[styles.thumb]: true,
				[styles.thumbChecked]: props.checked,
			})}
		/>
	</RadixSwitch.Root>
));

SwitchButton.displayName = RadixSwitch.Root.displayName;

export default SwitchButton;
