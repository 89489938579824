import { useStepBulkResolution } from "@metronome/api/useProcessInstance";
import {
	DropdownMenu,
	DropdownMenuTrigger,
	DropdownMenuContent,
	DropdownMenuItem,
} from "@metronome/components/Dropdown";
import { Lozenge } from "@metronome/components/Lozenge";
import clsx from "clsx";
import type React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./bulkUpdate.module.scss";
import { DotsVerticalIcon, UpdateIcon } from "@radix-ui/react-icons";

type Props = {
	processInstanceId: string;
	stepId: string;
};

export const BulkUpdateInstance: React.FC<Props> = ({
	processInstanceId,
	stepId,
}) => {
	const { mutate, isPending } = useStepBulkResolution(processInstanceId);
	return (
		<DropdownMenu modal={false}>
			<DropdownMenuTrigger asChild>
				<button
					type="button"
					onClick={(e) => {
						e.preventDefault();
					}}
					className={clsx({
						[styles.iconButton]: true,
						"group-hover/trigger:text-slate-400": true,
					})}
				>
					{isPending ? (
						<UpdateIcon className="inline-block animate-spin" />
					) : (
						<DotsVerticalIcon />
					)}
				</button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end">
				<DropdownMenuItem
					onSelect={() => mutate(stepId)}
					className={styles.dropdownItem}
				>
					<FormattedMessage id="MOVE" />{" "}
					<Lozenge appearance="default">
						<FormattedMessage id="RESOLUTION.notStarted" />
					</Lozenge>{" "}
					<FormattedMessage id="AND" />{" "}
					<Lozenge appearance="default">
						<FormattedMessage id="RESOLUTION.inProgress" />
					</Lozenge>{" "}
					<FormattedMessage id="STEPS_TOWARDS" />{" "}
					<Lozenge appearance="success">
						<FormattedMessage id="RESOLUTION.done" />
					</Lozenge>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};
