import { format } from "date-fns/format";
import { formatDistanceToNowStrict } from "date-fns/formatDistanceToNowStrict";

import { fr } from "date-fns/locale/fr";

const locales = { fr };

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
// It will default to english
export default function (
	date: number | Date,
	formatStr = "PP",
	options?:
		| {
				locale?: string;
		  }
		| undefined,
): string {
	return format(date, formatStr, {
		...options,
		locale: options?.locale
			? locales[options?.locale as keyof typeof locales]
			: undefined,
	});
}

export const formatDateDistanceToNowStrict = (
	date: number | Date,
	options?:
		| {
				addSuffix?: boolean;
				unit?: "second" | "minute" | "hour" | "day" | "month" | "year";
				roundingMethod?: "floor" | "ceil" | "round";
				locale?: string;
		  }
		| undefined,
): string =>
	formatDistanceToNowStrict(date, {
		...options,
		locale: options?.locale
			? locales[options?.locale as keyof typeof locales]
			: undefined,
	});
