import { zodResolver } from "@hookform/resolvers/zod";
import { useUpdateLinkAttachment } from "@metronome/api/useAttachments";
import { Dialog, DialogContent } from "@metronome/components/Dialog";
import FloatingInput from "@metronome/components/FloatingInput";
import {
	type AttachmentAPI,
	AttachmentTypeAPI,
} from "@metronome/types/Attachment";
import type { ProcessInstance, StepInstances } from "@metronome/types/Context";
import type React from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { z } from "zod";
import styles from "./editLinkAttachment.module.scss";

const schema = z.object({
	link: z.string().url(),
	name: z.string(),
	description: z.string().optional(),
});

type Inputs = z.infer<typeof schema>;

type Props = {
	context: ProcessInstance | StepInstances;
	contextId: string;
	attachment: AttachmentAPI;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EditLinkAttachment = ({
	context,
	contextId,
	attachment,
	open,
	setOpen,
}: Props): JSX.Element => {
	const intl = useIntl();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>({
		mode: "onBlur",
		resolver: zodResolver(schema),
		defaultValues: {
			link: attachment.content,
			name: attachment.name,
			description: attachment.description,
		},
	});

	const { mutateAsync: updateAttachment, isPending } = useUpdateLinkAttachment(
		context,
		contextId,
	);

	const hasErrors = Object.keys(errors).length;

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		updateAttachment({
			id: attachment.id,
			type: AttachmentTypeAPI.enum.Link,
			link: data.link,
			name: data.name,
			description: data.description ?? "",
		}).finally(() => {
			setOpen(false);
		});
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent>
				<form
					className="flex flex-col gap-2 p-4"
					onSubmit={handleSubmit(onSubmit)}
				>
					<strong>
						<FormattedMessage id="EDIT_LINK_ATTACHMENT" />
					</strong>
					<FloatingInput required label="link" {...register("link")} />
					{errors.link?.message && (
						<p className={styles.error}>{errors.link?.message}</p>
					)}
					<FloatingInput required label="name" {...register("name")} />
					<FloatingInput
						required={false}
						label="description"
						{...register("description")}
					/>
					<input
						type="submit"
						disabled={!!hasErrors || isPending}
						className={styles.submit}
						value={
							isPending
								? intl.formatMessage({ id: "LOADING" })
								: intl.formatMessage({ id: "EDIT" })
						}
					/>
				</form>
			</DialogContent>
		</Dialog>
	);
};
