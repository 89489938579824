export function isValidHttpUrl(str: string): boolean {
	let url;
	try {
		url = new URL(str);
	} catch (_) {
		return false;
	}
	return url.protocol === "http:" || url.protocol === "https:";
}

export function isDateAndTime(date: string): boolean {
	return !Number.isNaN(Date.parse(date)) && date.length > 10;
}

export const dataInString = (data: unknown): string => {
	if (typeof data === "boolean") {
		if (data) {
			return "Yes";
		}
		return "No";
	}
	if (!data) {
		return "N/A";
	}
	return `${data}`;
};
