import type { IStepInstance } from "@metronome/types/StepInstance";
import type { FilterFn, Row } from "@tanstack/react-table";
import { parseISO } from "date-fns/parseISO";
import { atEndOfDay, atMidnight } from "./dateHelpers";

export const isWithinRange: FilterFn<any> = (
	row: Row<IStepInstance>,
	columnId: string,
	value: [string, string],
): boolean => {
	const dateString: string = row.getValue(columnId);
	const [startTime, endTime] = value;

	let start;
	let end;
	let date;
	try {
		start = atMidnight(startTime);
		end = atEndOfDay(endTime);
		date = parseISO(dateString);
	} catch (e) {
		return false;
	}

	// if one filter defined and date is null filter it
	if ((start || end) && !date) return false;
	if (date instanceof Date && date.getTime) {
		return (
			date.getTime() >= (start?.getTime() ?? Number.MIN_VALUE) &&
			date.getTime() <= (end?.getTime() ?? Number.MAX_VALUE)
		);
	}
	return true;
};
