import { z } from "zod";
import { JsonSchema, Layout } from "@jsonforms/core";
import { IParty } from "./Party";

const ZodJsonSchema: z.ZodType<JsonSchema> = z.any();
const ZodLayout: z.ZodType<Layout> = z.any();

export const IStepInstanceFormAnswer = z.object({
	id: z.string(),
	stepInstanceId: z.string(),
	formSpecId: z.string(),
	data: z.record(z.string().min(1), z.object({})).optional(),
	createdBy: IParty,
	createdAt: z.number(),
});

export type IStepInstanceFormAnswer = z.infer<typeof IStepInstanceFormAnswer>;

export function isStepInstanceFormAnswer(
	model: unknown,
): model is IStepInstanceFormAnswer {
	return (model as IStepInstanceFormAnswer).createdBy !== undefined;
}

export const IFormDefinition = z.object({
	id: z.string(),
	jsonSchema: ZodJsonSchema,
	uiSchema: ZodLayout,
	answers: z.array(IStepInstanceFormAnswer),
});
export type IFormDefinition = z.infer<typeof IFormDefinition>;
