import {
	Select,
	SelectTrigger,
	SelectValue,
	SelectContent,
	SelectItem,
} from "@metronome/components/ui/select";
import type { ProcessStreamResourceAllocations } from "@metronome/types/ProcessStreamResourceAllocations";
import { useNavigate } from "@tanstack/react-router";
import { useCallback } from "react";

export const SelectResource: React.FC<{
	resources: ProcessStreamResourceAllocations[];
	nodeReferenceId?: string;
}> = ({ resources, nodeReferenceId }) => {
	const navigate = useNavigate();

	const updateResourceParams = useCallback(
		(nodeReferenceId: string) => {
			navigate({
				from: "/$workspaceId/stream/$streamId/routine",
				to: "/$workspaceId/stream/$streamId/routine",
				search: {
					nodeReferenceId,
					stepTemplateId: resources.find(
						(r) => r.nodeReferenceDefinitionId === nodeReferenceId,
					)?.stepTemplateId,
				},
			});
		},
		[navigate, resources],
	);

	return (
		<Select
			onValueChange={updateResourceParams}
			value={nodeReferenceId ?? resources[0].nodeReferenceDefinitionId}
		>
			<SelectTrigger className="w-72">
				<SelectValue />
			</SelectTrigger>
			<SelectContent>
				{resources.map((r) => (
					<SelectItem
						key={r.nodeReferenceDefinitionId}
						value={r.nodeReferenceDefinitionId}
					>
						{r.nodeReferenceDefinitionName}
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};
