import React from "react";
import { Accordion } from "@metronome/components/Accordion";
import { FormDefinition } from "@metronome/features/Form/FormDefinition";
import useForms from "@metronome/api/useForms";
import { EResolution } from "@metronome/types/Resolution";
import { useStepInstanceRequirements } from "@metronome/api/useStepInstance";

type FormsTabProps = {
	stepInstanceId: string;
	resolution: EResolution;
};
const FormsTab: React.FC<FormsTabProps> = ({ stepInstanceId, resolution }) => {
	const { data: forms } = useForms(stepInstanceId);

	const { data: requirements } = useStepInstanceRequirements(
		stepInstanceId,
		resolution,
	);

	const missingRequiredFormsIds = requirements
		?.find((req) => req.name === "forms" && !req.fulfilled)
		?.ref.map((form) => form.id);

	const formElements = forms?.map((form) => {
		const isRequiredAndMissing = missingRequiredFormsIds?.includes(form.id);
		return (
			<FormDefinition
				form={form}
				isRequiredAndMissing={isRequiredAndMissing}
				key={`form-${form.id}`}
			/>
		);
	});

	return (
		<Accordion type="single" collapsible>
			{formElements}
		</Accordion>
	);
};
export default FormsTab;
