import * as duration from "duration-fns";
import { useIntl } from "react-intl";

type FormatDuration = {
	durationAsString: string;
	duration: duration.Duration;
	error?: string;
};

export function useFormatDurationToString(
	start: duration.DateInput,
	end: duration.DateInput,
): FormatDuration {
	const intl = useIntl();
	let error: string | undefined = undefined;

	const durationRaw = duration.between(start, end);
	if (duration.isNegative(durationRaw)) {
		error = "NEGATIVE_DURATION";
	} else if (duration.isZero(durationRaw)) {
		error = "ZERO_DURATION";
	}
	const filteredDuration = Object.entries(durationRaw).filter(
		([_, v]) => v > 0,
	);
	const formattedDuration = filteredDuration
		.map(([k, v]) => `${v} ${intl.formatMessage({ id: k })}`)
		.join(", ");
	return { durationAsString: formattedDuration, duration: durationRaw, error };
}
