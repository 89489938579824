import {
	metricsCountOptions,
	metricsWorkloadOptions,
} from "@metronome/api/useMetrics";
import { formatOffsetDateTime } from "@metronome/utils/dateHelpers";
import { createFileRoute } from "@tanstack/react-router";
import * as v from "valibot";

const Methods = ["count", "duration"] as const;
const WorkloadPageParams = v.object({
	method: v.optional(v.picklist(Methods), "count"),
});

export const Route = createFileRoute("/$workspaceId/workload")({
	beforeLoad: () => {
		const todayMidnight = formatOffsetDateTime(
			new Date(new Date().setHours(0, 0, 0, 0)),
		);
		return {
			todayMidnight,
		};
	},
	validateSearch: (searchParams) => v.parse(WorkloadPageParams, searchParams),
	loaderDeps: ({ search: { method } }) => ({
		method,
	}),
	loader: async ({ context, params, deps: { method } }) => {
		const { queryClient, todayMidnight } = context;
		const { workspaceId } = params;

		const promises = [];

		promises.push(
			queryClient.ensureQueryData(
				metricsWorkloadOptions({
					workspaceId,
					method,
					from: todayMidnight,
					metrics: [
						{
							name: "stepInstanceWorkload",
						} as const,
					],
				}),
			),
		);

		promises.push(
			queryClient.ensureQueryData(
				metricsCountOptions({
					workspaceId,
					method: "count",
					from: todayMidnight,
					metrics: [
						{
							name: "countOfLateStepInstances",
						} as const,
						{
							name: "countOfAtRiskStepInstances",
						} as const,
						{
							name: "countOfActiveStepInstances",
						} as const,
					],
				}),
			),
		);

		await Promise.all(promises);

		return;
	},
});
