import React from "react";
import { useIntl } from "react-intl";

import { ControlProps } from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { InputError } from "./InputError";

const SelectControl = (props: ControlProps): JSX.Element => {
	const intl = useIntl();
	const { errors, id, label, handleChange, path, data, schema, required } =
		props;
	const labelFormatted = required ? `${label}*` : label;
	return (
		<div className="flex flex-col mb-3">
			<label htmlFor={id}>{labelFormatted}</label>
			<select
				id={id}
				onChange={(ev) => handleChange(path, ev.target.value)}
				value={data}
				className="ps-2"
			>
				<option>
					{intl.formatMessage({
						id: "PICK_AN_OPTION",
					})}
				</option>
				{schema?.enum?.map((value) => (
					<option key={value} value={value}>
						{value}
					</option>
				))}
			</select>

			<span className="text-red-600">
				{!!errors && !!data && <InputError error={errors} />}
			</span>
		</div>
	);
};

export default withJsonFormsControlProps(SelectControl);
