import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import { useQuery } from "@tanstack/react-query";
import { apiGet } from "./api";
import type { CursoredBatches } from "@metronome/types/Batches";

type BatchesPayload = {
	streamId: string;
	from?: string;
	countNext?: number;
	countPrevious?: number;
};
export function useBatches({
	streamId,
	from,
	countNext,
	countPrevious,
}: BatchesPayload) {
	const workspaceId = useWorkspaceId();
	const today = new Date().toISOString().slice(0, 10);

	if (!workspaceId) {
		throw new Error("useBatches: workspaceId is not defined");
	}
	return useQuery({
		queryKey: [
			workspaceId,
			streamId,
			"batches",
			from,
			countNext,
			countPrevious,
		],
		queryFn: () =>
			apiGet<CursoredBatches>(
				`/ws/${workspaceId}/process-streams/${streamId}/batches`,
				{
					params: {
						currentDate: from ?? today,
						countNext,
						countPrevious,
						// ...pageParam,
					},
				},
			),
		// getNextPageParam: (lastPage) => lastPage.nextBatches?.nextCursor,
		// getPreviousPageParam: (firstPage) => firstPage.previousBatches?.nextCursor,
		// initialPageParam: {
		// 	cursorNext: undefined,
		// 	cursorPrevious: undefined,
		// },
	});
}
