import { singleProcessStreamsOptions } from "@metronome/api/useProcessStreamInstances";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Navigate, redirect } from "@tanstack/react-router";

/**
 * this route redirects to the proper stream type: routine, onDemand or Clustered
 */

export const Route = createFileRoute("/$workspaceId/stream/$streamId/")({
	loader: async ({ context, params }) => {
		const { queryClient } = context;
		const { streamId, workspaceId } = params;

		const promises = [];

		promises.push(
			queryClient.ensureQueryData(
				singleProcessStreamsOptions(workspaceId, streamId),
			),
		);

		await Promise.all(promises);

		const data = queryClient.getQueryData(
			singleProcessStreamsOptions(workspaceId, streamId).queryKey,
		);

		throw redirect({
			from: "/$workspaceId/stream/$streamId",
			to: `./${data?.type}`,
		});
	},
	component: () => {
		const { workspaceId, streamId } = Route.useParams();
		const { data } = useQuery(
			singleProcessStreamsOptions(workspaceId, streamId),
		);
		return <Navigate to={`/$workspaceId/stream/$streamId/${data?.type}`} />;
	},
});
