import type React from "react";
import {
	formatTimestampToDateString,
	formatTimeZoneToDateString,
} from "@metronome/utils/dateHelpers";
import { useIntl } from "react-intl";

interface DateCellModel {
	datetime: string | number;
	displayDate?: boolean;
	displayTime?: boolean;
}

export const DateTimeCell: React.FC<DateCellModel> = ({
	datetime,
	displayDate = true,
	displayTime = true,
}) => {
	const intl = useIntl();

	const display = Number.isInteger(datetime)
		? formatTimestampToDateString(+datetime, intl)
		: formatTimeZoneToDateString(datetime.toString(), intl);

	return (
		<div>
			{displayTime ? <span>{display.time}</span> : null}
			<span> </span>
			{displayDate ? <span>{display.date}</span> : null}
		</div>
	);
};

export default DateTimeCell;
