import useSelf from "@metronome/api/useSelf";
import { IModelReference } from "@metronome/types/ModelReference";
import { IDispatch } from "@metronome/types/ProcessStream";
import React from "react";
import { useIntl } from "react-intl";

type Props = {
	dispatch: IDispatch;
};

export const DispatchWording: React.FC<Props> = ({ dispatch }) => {
	const intl = useIntl();
	const { data: user } = useSelf();

	const baseMessage = intl.formatMessage({
		id: "PROCESS_STREAM.DISPATCH.BASE",
	});
	const withInMessage = intl.formatMessage({
		id: "PROCESS_STREAM.DISPATCH.WITHIN",
	});
	const baseBackOffice =
		window.VITE_BACKOFFICE_URL || import.meta.env.VITE_BACKOFFICE_URL;

	function getSpanOrLink(node: IModelReference): JSX.Element {
		return (
			<>
				{user?.canAccessStudio ? (
					<a
						href={`${baseBackOffice}/business-dimension-nodes/${node.id}`}
						target="_blank"
						rel="noreferrer"
						className="italic"
					>
						{node.name}
					</a>
				) : (
					<span className="italic underline">{node.name}</span>
				)}
			</>
		);
	}
    

	function wordForLabels(dispatch: IDispatch): JSX.Element {
		return (
			<>
				{dispatch.labels?.length ? (
					<>
						<span>{withInMessage}</span>
						<span>Label(s)</span>
						{dispatch.labels.map((l) => (
							<span key={l.id} className="italic underline">
								{l.name} ({l.operation})
							</span>
						))}
					</>
				) : undefined}
			</>
		);
	}

	function wordForSpanMethod(dispatch: IDispatch): JSX.Element {
		return (
			<>
				<span className="italic underline">
					{dispatch?.level?.name}
				</span>
				{dispatch.node?.name ? (
					<>
						<span>{withInMessage}</span>
						{getSpanOrLink(dispatch.node)}
						{wordForLabels(dispatch)}
					</>
				) : undefined}
			</>
		);
	}
    

	if (dispatch === null) {
		return <p>No available data</p>;
	}
	return (
		<>
			<p className="flex gap-2">
				{baseMessage}
                <>
                    {wordForSpanMethod(dispatch)}
                </>
            </p>
		</>
	);
};
