export async function canvasToFile(
	canvas: HTMLCanvasElement | undefined,
	fileName: string,
): Promise<File | undefined> {
	if (!canvas) return undefined;

	return await new Promise((resolve) =>
		canvas.toBlob((b) => {
			resolve(b ? blobToFile(b, fileName) : undefined);
		}, "image/jpeg"),
	);
}

export async function urlToFile(
	url: string | undefined,
	fileName: string,
): Promise<File | undefined> {
	if (!url) return undefined;
	const blob = await fetch(url).then((r) => r.blob());
	return blobToFile(blob, fileName);
}

function blobToFile(
	blob: Blob | undefined,
	fileName: string,
): File | undefined {
	if (!blob) return undefined;
	return new File([blob], `preview-${fileName}.jpg`, { type: "image/jpeg" });
}
