/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as OnboardingImport } from './routes/onboarding'
import { Route as GotoImport } from './routes/goto'
import { Route as IndexImport } from './routes/index'
import { Route as ErrorErrorImport } from './routes/error/_error'
import { Route as ErrorForbiddenWorkspaceImport } from './routes/_error/forbidden-workspace'
import { Route as WorkspaceIdWorkloadImport } from './routes/$workspaceId/workload'
import { Route as WorkspaceIdStreamsImport } from './routes/$workspaceId/streams'
import { Route as WorkspaceIdDataImport } from './routes/$workspaceId/data'
import { Route as WorkspaceIdActivitiesImport } from './routes/$workspaceId/activities'
import { Route as ErrorErrorNoWorkspaceImport } from './routes/error/_error.no-workspace'
import { Route as ErrorErrorForbiddenWorkspaceImport } from './routes/error/_error.forbidden-workspace'
import { Route as WorkspaceIdPartyPartyIdImport } from './routes/$workspaceId/party/$partyId'
import { Route as WorkspaceIdProcessProcessIdRouteImport } from './routes/$workspaceId/process/$processId/route'
import { Route as WorkspaceIdStreamStreamIdIndexImport } from './routes/$workspaceId/stream/$streamId/index'
import { Route as WorkspaceIdStreamStreamIdRoutineImport } from './routes/$workspaceId/stream/$streamId/routine'
import { Route as WorkspaceIdStreamStreamIdPortfolioImport } from './routes/$workspaceId/stream/$streamId/portfolio'
import { Route as WorkspaceIdStreamStreamIdClusteredImport } from './routes/$workspaceId/stream/$streamId/clustered'
import { Route as WorkspaceIdProcessProcessIdTimelineImport } from './routes/$workspaceId/process/$processId/timeline'
import { Route as WorkspaceIdProcessProcessIdStepsImport } from './routes/$workspaceId/process/$processId/steps'
import { Route as WorkspaceIdProcessProcessIdNotesImport } from './routes/$workspaceId/process/$processId/notes'
import { Route as WorkspaceIdProcessProcessIdHistoryImport } from './routes/$workspaceId/process/$processId/history'
import { Route as WorkspaceIdProcessProcessIdAttachmentsImport } from './routes/$workspaceId/process/$processId/attachments'
import { Route as WorkspaceIdProcessProcessIdGatesAndStepsIndexImport } from './routes/$workspaceId/process/$processId/gates-and-steps/index'
import { Route as WorkspaceIdProcessProcessIdGatesAndStepsStepIdImport } from './routes/$workspaceId/process/$processId/gates-and-steps/$stepId'

// Create Virtual Routes

const ErrorImport = createFileRoute('/error')()
const WorkspaceIdIndexLazyImport = createFileRoute('/$workspaceId/')()

// Create/Update Routes

const ErrorRoute = ErrorImport.update({
  id: '/error',
  path: '/error',
  getParentRoute: () => rootRoute,
} as any)

const OnboardingRoute = OnboardingImport.update({
  id: '/onboarding',
  path: '/onboarding',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/onboarding.lazy').then((d) => d.Route))

const GotoRoute = GotoImport.update({
  id: '/goto',
  path: '/goto',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIdIndexLazyRoute = WorkspaceIdIndexLazyImport.update({
  id: '/$workspaceId/',
  path: '/$workspaceId/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/$workspaceId/index.lazy').then((d) => d.Route),
)

const ErrorErrorRoute = ErrorErrorImport.update({
  id: '/_error',
  getParentRoute: () => ErrorRoute,
} as any)

const ErrorForbiddenWorkspaceRoute = ErrorForbiddenWorkspaceImport.update({
  id: '/_error/forbidden-workspace',
  path: '/forbidden-workspace',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIdWorkloadRoute = WorkspaceIdWorkloadImport.update({
  id: '/$workspaceId/workload',
  path: '/$workspaceId/workload',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/$workspaceId/workload.lazy').then((d) => d.Route),
)

const WorkspaceIdStreamsRoute = WorkspaceIdStreamsImport.update({
  id: '/$workspaceId/streams',
  path: '/$workspaceId/streams',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIdDataRoute = WorkspaceIdDataImport.update({
  id: '/$workspaceId/data',
  path: '/$workspaceId/data',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/$workspaceId/data.lazy').then((d) => d.Route),
)

const WorkspaceIdActivitiesRoute = WorkspaceIdActivitiesImport.update({
  id: '/$workspaceId/activities',
  path: '/$workspaceId/activities',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/$workspaceId/activities.lazy').then((d) => d.Route),
)

const ErrorErrorNoWorkspaceRoute = ErrorErrorNoWorkspaceImport.update({
  id: '/no-workspace',
  path: '/no-workspace',
  getParentRoute: () => ErrorErrorRoute,
} as any)

const ErrorErrorForbiddenWorkspaceRoute =
  ErrorErrorForbiddenWorkspaceImport.update({
    id: '/forbidden-workspace',
    path: '/forbidden-workspace',
    getParentRoute: () => ErrorErrorRoute,
  } as any)

const WorkspaceIdPartyPartyIdRoute = WorkspaceIdPartyPartyIdImport.update({
  id: '/$workspaceId/party/$partyId',
  path: '/$workspaceId/party/$partyId',
  getParentRoute: () => rootRoute,
} as any)

const WorkspaceIdProcessProcessIdRouteRoute =
  WorkspaceIdProcessProcessIdRouteImport.update({
    id: '/$workspaceId/process/$processId',
    path: '/$workspaceId/process/$processId',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/$workspaceId/process/$processId/route.lazy').then(
      (d) => d.Route,
    ),
  )

const WorkspaceIdStreamStreamIdIndexRoute =
  WorkspaceIdStreamStreamIdIndexImport.update({
    id: '/$workspaceId/stream/$streamId/',
    path: '/$workspaceId/stream/$streamId/',
    getParentRoute: () => rootRoute,
  } as any)

const WorkspaceIdStreamStreamIdRoutineRoute =
  WorkspaceIdStreamStreamIdRoutineImport.update({
    id: '/$workspaceId/stream/$streamId/routine',
    path: '/$workspaceId/stream/$streamId/routine',
    getParentRoute: () => rootRoute,
  } as any)

const WorkspaceIdStreamStreamIdPortfolioRoute =
  WorkspaceIdStreamStreamIdPortfolioImport.update({
    id: '/$workspaceId/stream/$streamId/portfolio',
    path: '/$workspaceId/stream/$streamId/portfolio',
    getParentRoute: () => rootRoute,
  } as any)

const WorkspaceIdStreamStreamIdClusteredRoute =
  WorkspaceIdStreamStreamIdClusteredImport.update({
    id: '/$workspaceId/stream/$streamId/clustered',
    path: '/$workspaceId/stream/$streamId/clustered',
    getParentRoute: () => rootRoute,
  } as any)

const WorkspaceIdProcessProcessIdTimelineRoute =
  WorkspaceIdProcessProcessIdTimelineImport.update({
    id: '/timeline',
    path: '/timeline',
    getParentRoute: () => WorkspaceIdProcessProcessIdRouteRoute,
  } as any)

const WorkspaceIdProcessProcessIdStepsRoute =
  WorkspaceIdProcessProcessIdStepsImport.update({
    id: '/steps',
    path: '/steps',
    getParentRoute: () => WorkspaceIdProcessProcessIdRouteRoute,
  } as any)

const WorkspaceIdProcessProcessIdNotesRoute =
  WorkspaceIdProcessProcessIdNotesImport.update({
    id: '/notes',
    path: '/notes',
    getParentRoute: () => WorkspaceIdProcessProcessIdRouteRoute,
  } as any)

const WorkspaceIdProcessProcessIdHistoryRoute =
  WorkspaceIdProcessProcessIdHistoryImport.update({
    id: '/history',
    path: '/history',
    getParentRoute: () => WorkspaceIdProcessProcessIdRouteRoute,
  } as any)

const WorkspaceIdProcessProcessIdAttachmentsRoute =
  WorkspaceIdProcessProcessIdAttachmentsImport.update({
    id: '/attachments',
    path: '/attachments',
    getParentRoute: () => WorkspaceIdProcessProcessIdRouteRoute,
  } as any)

const WorkspaceIdProcessProcessIdGatesAndStepsIndexRoute =
  WorkspaceIdProcessProcessIdGatesAndStepsIndexImport.update({
    id: '/gates-and-steps/',
    path: '/gates-and-steps/',
    getParentRoute: () => WorkspaceIdProcessProcessIdRouteRoute,
  } as any)

const WorkspaceIdProcessProcessIdGatesAndStepsStepIdRoute =
  WorkspaceIdProcessProcessIdGatesAndStepsStepIdImport.update({
    id: '/gates-and-steps/$stepId',
    path: '/gates-and-steps/$stepId',
    getParentRoute: () => WorkspaceIdProcessProcessIdRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/goto': {
      id: '/goto'
      path: '/goto'
      fullPath: '/goto'
      preLoaderRoute: typeof GotoImport
      parentRoute: typeof rootRoute
    }
    '/onboarding': {
      id: '/onboarding'
      path: '/onboarding'
      fullPath: '/onboarding'
      preLoaderRoute: typeof OnboardingImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/activities': {
      id: '/$workspaceId/activities'
      path: '/$workspaceId/activities'
      fullPath: '/$workspaceId/activities'
      preLoaderRoute: typeof WorkspaceIdActivitiesImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/data': {
      id: '/$workspaceId/data'
      path: '/$workspaceId/data'
      fullPath: '/$workspaceId/data'
      preLoaderRoute: typeof WorkspaceIdDataImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/streams': {
      id: '/$workspaceId/streams'
      path: '/$workspaceId/streams'
      fullPath: '/$workspaceId/streams'
      preLoaderRoute: typeof WorkspaceIdStreamsImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/workload': {
      id: '/$workspaceId/workload'
      path: '/$workspaceId/workload'
      fullPath: '/$workspaceId/workload'
      preLoaderRoute: typeof WorkspaceIdWorkloadImport
      parentRoute: typeof rootRoute
    }
    '/_error/forbidden-workspace': {
      id: '/_error/forbidden-workspace'
      path: '/forbidden-workspace'
      fullPath: '/forbidden-workspace'
      preLoaderRoute: typeof ErrorForbiddenWorkspaceImport
      parentRoute: typeof rootRoute
    }
    '/error': {
      id: '/error'
      path: '/error'
      fullPath: '/error'
      preLoaderRoute: typeof ErrorImport
      parentRoute: typeof rootRoute
    }
    '/error/_error': {
      id: '/error/_error'
      path: '/error'
      fullPath: '/error'
      preLoaderRoute: typeof ErrorErrorImport
      parentRoute: typeof ErrorRoute
    }
    '/$workspaceId/': {
      id: '/$workspaceId/'
      path: '/$workspaceId'
      fullPath: '/$workspaceId'
      preLoaderRoute: typeof WorkspaceIdIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/process/$processId': {
      id: '/$workspaceId/process/$processId'
      path: '/$workspaceId/process/$processId'
      fullPath: '/$workspaceId/process/$processId'
      preLoaderRoute: typeof WorkspaceIdProcessProcessIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/party/$partyId': {
      id: '/$workspaceId/party/$partyId'
      path: '/$workspaceId/party/$partyId'
      fullPath: '/$workspaceId/party/$partyId'
      preLoaderRoute: typeof WorkspaceIdPartyPartyIdImport
      parentRoute: typeof rootRoute
    }
    '/error/_error/forbidden-workspace': {
      id: '/error/_error/forbidden-workspace'
      path: '/forbidden-workspace'
      fullPath: '/error/forbidden-workspace'
      preLoaderRoute: typeof ErrorErrorForbiddenWorkspaceImport
      parentRoute: typeof ErrorErrorImport
    }
    '/error/_error/no-workspace': {
      id: '/error/_error/no-workspace'
      path: '/no-workspace'
      fullPath: '/error/no-workspace'
      preLoaderRoute: typeof ErrorErrorNoWorkspaceImport
      parentRoute: typeof ErrorErrorImport
    }
    '/$workspaceId/process/$processId/attachments': {
      id: '/$workspaceId/process/$processId/attachments'
      path: '/attachments'
      fullPath: '/$workspaceId/process/$processId/attachments'
      preLoaderRoute: typeof WorkspaceIdProcessProcessIdAttachmentsImport
      parentRoute: typeof WorkspaceIdProcessProcessIdRouteImport
    }
    '/$workspaceId/process/$processId/history': {
      id: '/$workspaceId/process/$processId/history'
      path: '/history'
      fullPath: '/$workspaceId/process/$processId/history'
      preLoaderRoute: typeof WorkspaceIdProcessProcessIdHistoryImport
      parentRoute: typeof WorkspaceIdProcessProcessIdRouteImport
    }
    '/$workspaceId/process/$processId/notes': {
      id: '/$workspaceId/process/$processId/notes'
      path: '/notes'
      fullPath: '/$workspaceId/process/$processId/notes'
      preLoaderRoute: typeof WorkspaceIdProcessProcessIdNotesImport
      parentRoute: typeof WorkspaceIdProcessProcessIdRouteImport
    }
    '/$workspaceId/process/$processId/steps': {
      id: '/$workspaceId/process/$processId/steps'
      path: '/steps'
      fullPath: '/$workspaceId/process/$processId/steps'
      preLoaderRoute: typeof WorkspaceIdProcessProcessIdStepsImport
      parentRoute: typeof WorkspaceIdProcessProcessIdRouteImport
    }
    '/$workspaceId/process/$processId/timeline': {
      id: '/$workspaceId/process/$processId/timeline'
      path: '/timeline'
      fullPath: '/$workspaceId/process/$processId/timeline'
      preLoaderRoute: typeof WorkspaceIdProcessProcessIdTimelineImport
      parentRoute: typeof WorkspaceIdProcessProcessIdRouteImport
    }
    '/$workspaceId/stream/$streamId/clustered': {
      id: '/$workspaceId/stream/$streamId/clustered'
      path: '/$workspaceId/stream/$streamId/clustered'
      fullPath: '/$workspaceId/stream/$streamId/clustered'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdClusteredImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/stream/$streamId/portfolio': {
      id: '/$workspaceId/stream/$streamId/portfolio'
      path: '/$workspaceId/stream/$streamId/portfolio'
      fullPath: '/$workspaceId/stream/$streamId/portfolio'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdPortfolioImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/stream/$streamId/routine': {
      id: '/$workspaceId/stream/$streamId/routine'
      path: '/$workspaceId/stream/$streamId/routine'
      fullPath: '/$workspaceId/stream/$streamId/routine'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdRoutineImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/stream/$streamId/': {
      id: '/$workspaceId/stream/$streamId/'
      path: '/$workspaceId/stream/$streamId'
      fullPath: '/$workspaceId/stream/$streamId'
      preLoaderRoute: typeof WorkspaceIdStreamStreamIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/$workspaceId/process/$processId/gates-and-steps/$stepId': {
      id: '/$workspaceId/process/$processId/gates-and-steps/$stepId'
      path: '/gates-and-steps/$stepId'
      fullPath: '/$workspaceId/process/$processId/gates-and-steps/$stepId'
      preLoaderRoute: typeof WorkspaceIdProcessProcessIdGatesAndStepsStepIdImport
      parentRoute: typeof WorkspaceIdProcessProcessIdRouteImport
    }
    '/$workspaceId/process/$processId/gates-and-steps/': {
      id: '/$workspaceId/process/$processId/gates-and-steps/'
      path: '/gates-and-steps'
      fullPath: '/$workspaceId/process/$processId/gates-and-steps'
      preLoaderRoute: typeof WorkspaceIdProcessProcessIdGatesAndStepsIndexImport
      parentRoute: typeof WorkspaceIdProcessProcessIdRouteImport
    }
  }
}

// Create and export the route tree

interface ErrorErrorRouteChildren {
  ErrorErrorForbiddenWorkspaceRoute: typeof ErrorErrorForbiddenWorkspaceRoute
  ErrorErrorNoWorkspaceRoute: typeof ErrorErrorNoWorkspaceRoute
}

const ErrorErrorRouteChildren: ErrorErrorRouteChildren = {
  ErrorErrorForbiddenWorkspaceRoute: ErrorErrorForbiddenWorkspaceRoute,
  ErrorErrorNoWorkspaceRoute: ErrorErrorNoWorkspaceRoute,
}

const ErrorErrorRouteWithChildren = ErrorErrorRoute._addFileChildren(
  ErrorErrorRouteChildren,
)

interface ErrorRouteChildren {
  ErrorErrorRoute: typeof ErrorErrorRouteWithChildren
}

const ErrorRouteChildren: ErrorRouteChildren = {
  ErrorErrorRoute: ErrorErrorRouteWithChildren,
}

const ErrorRouteWithChildren = ErrorRoute._addFileChildren(ErrorRouteChildren)

interface WorkspaceIdProcessProcessIdRouteRouteChildren {
  WorkspaceIdProcessProcessIdAttachmentsRoute: typeof WorkspaceIdProcessProcessIdAttachmentsRoute
  WorkspaceIdProcessProcessIdHistoryRoute: typeof WorkspaceIdProcessProcessIdHistoryRoute
  WorkspaceIdProcessProcessIdNotesRoute: typeof WorkspaceIdProcessProcessIdNotesRoute
  WorkspaceIdProcessProcessIdStepsRoute: typeof WorkspaceIdProcessProcessIdStepsRoute
  WorkspaceIdProcessProcessIdTimelineRoute: typeof WorkspaceIdProcessProcessIdTimelineRoute
  WorkspaceIdProcessProcessIdGatesAndStepsStepIdRoute: typeof WorkspaceIdProcessProcessIdGatesAndStepsStepIdRoute
  WorkspaceIdProcessProcessIdGatesAndStepsIndexRoute: typeof WorkspaceIdProcessProcessIdGatesAndStepsIndexRoute
}

const WorkspaceIdProcessProcessIdRouteRouteChildren: WorkspaceIdProcessProcessIdRouteRouteChildren =
  {
    WorkspaceIdProcessProcessIdAttachmentsRoute:
      WorkspaceIdProcessProcessIdAttachmentsRoute,
    WorkspaceIdProcessProcessIdHistoryRoute:
      WorkspaceIdProcessProcessIdHistoryRoute,
    WorkspaceIdProcessProcessIdNotesRoute:
      WorkspaceIdProcessProcessIdNotesRoute,
    WorkspaceIdProcessProcessIdStepsRoute:
      WorkspaceIdProcessProcessIdStepsRoute,
    WorkspaceIdProcessProcessIdTimelineRoute:
      WorkspaceIdProcessProcessIdTimelineRoute,
    WorkspaceIdProcessProcessIdGatesAndStepsStepIdRoute:
      WorkspaceIdProcessProcessIdGatesAndStepsStepIdRoute,
    WorkspaceIdProcessProcessIdGatesAndStepsIndexRoute:
      WorkspaceIdProcessProcessIdGatesAndStepsIndexRoute,
  }

const WorkspaceIdProcessProcessIdRouteRouteWithChildren =
  WorkspaceIdProcessProcessIdRouteRoute._addFileChildren(
    WorkspaceIdProcessProcessIdRouteRouteChildren,
  )

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/goto': typeof GotoRoute
  '/onboarding': typeof OnboardingRoute
  '/$workspaceId/activities': typeof WorkspaceIdActivitiesRoute
  '/$workspaceId/data': typeof WorkspaceIdDataRoute
  '/$workspaceId/streams': typeof WorkspaceIdStreamsRoute
  '/$workspaceId/workload': typeof WorkspaceIdWorkloadRoute
  '/forbidden-workspace': typeof ErrorForbiddenWorkspaceRoute
  '/error': typeof ErrorErrorRouteWithChildren
  '/$workspaceId': typeof WorkspaceIdIndexLazyRoute
  '/$workspaceId/process/$processId': typeof WorkspaceIdProcessProcessIdRouteRouteWithChildren
  '/$workspaceId/party/$partyId': typeof WorkspaceIdPartyPartyIdRoute
  '/error/forbidden-workspace': typeof ErrorErrorForbiddenWorkspaceRoute
  '/error/no-workspace': typeof ErrorErrorNoWorkspaceRoute
  '/$workspaceId/process/$processId/attachments': typeof WorkspaceIdProcessProcessIdAttachmentsRoute
  '/$workspaceId/process/$processId/history': typeof WorkspaceIdProcessProcessIdHistoryRoute
  '/$workspaceId/process/$processId/notes': typeof WorkspaceIdProcessProcessIdNotesRoute
  '/$workspaceId/process/$processId/steps': typeof WorkspaceIdProcessProcessIdStepsRoute
  '/$workspaceId/process/$processId/timeline': typeof WorkspaceIdProcessProcessIdTimelineRoute
  '/$workspaceId/stream/$streamId/clustered': typeof WorkspaceIdStreamStreamIdClusteredRoute
  '/$workspaceId/stream/$streamId/portfolio': typeof WorkspaceIdStreamStreamIdPortfolioRoute
  '/$workspaceId/stream/$streamId/routine': typeof WorkspaceIdStreamStreamIdRoutineRoute
  '/$workspaceId/stream/$streamId': typeof WorkspaceIdStreamStreamIdIndexRoute
  '/$workspaceId/process/$processId/gates-and-steps/$stepId': typeof WorkspaceIdProcessProcessIdGatesAndStepsStepIdRoute
  '/$workspaceId/process/$processId/gates-and-steps': typeof WorkspaceIdProcessProcessIdGatesAndStepsIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/goto': typeof GotoRoute
  '/onboarding': typeof OnboardingRoute
  '/$workspaceId/activities': typeof WorkspaceIdActivitiesRoute
  '/$workspaceId/data': typeof WorkspaceIdDataRoute
  '/$workspaceId/streams': typeof WorkspaceIdStreamsRoute
  '/$workspaceId/workload': typeof WorkspaceIdWorkloadRoute
  '/forbidden-workspace': typeof ErrorForbiddenWorkspaceRoute
  '/error': typeof ErrorErrorRouteWithChildren
  '/$workspaceId': typeof WorkspaceIdIndexLazyRoute
  '/$workspaceId/process/$processId': typeof WorkspaceIdProcessProcessIdRouteRouteWithChildren
  '/$workspaceId/party/$partyId': typeof WorkspaceIdPartyPartyIdRoute
  '/error/forbidden-workspace': typeof ErrorErrorForbiddenWorkspaceRoute
  '/error/no-workspace': typeof ErrorErrorNoWorkspaceRoute
  '/$workspaceId/process/$processId/attachments': typeof WorkspaceIdProcessProcessIdAttachmentsRoute
  '/$workspaceId/process/$processId/history': typeof WorkspaceIdProcessProcessIdHistoryRoute
  '/$workspaceId/process/$processId/notes': typeof WorkspaceIdProcessProcessIdNotesRoute
  '/$workspaceId/process/$processId/steps': typeof WorkspaceIdProcessProcessIdStepsRoute
  '/$workspaceId/process/$processId/timeline': typeof WorkspaceIdProcessProcessIdTimelineRoute
  '/$workspaceId/stream/$streamId/clustered': typeof WorkspaceIdStreamStreamIdClusteredRoute
  '/$workspaceId/stream/$streamId/portfolio': typeof WorkspaceIdStreamStreamIdPortfolioRoute
  '/$workspaceId/stream/$streamId/routine': typeof WorkspaceIdStreamStreamIdRoutineRoute
  '/$workspaceId/stream/$streamId': typeof WorkspaceIdStreamStreamIdIndexRoute
  '/$workspaceId/process/$processId/gates-and-steps/$stepId': typeof WorkspaceIdProcessProcessIdGatesAndStepsStepIdRoute
  '/$workspaceId/process/$processId/gates-and-steps': typeof WorkspaceIdProcessProcessIdGatesAndStepsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/goto': typeof GotoRoute
  '/onboarding': typeof OnboardingRoute
  '/$workspaceId/activities': typeof WorkspaceIdActivitiesRoute
  '/$workspaceId/data': typeof WorkspaceIdDataRoute
  '/$workspaceId/streams': typeof WorkspaceIdStreamsRoute
  '/$workspaceId/workload': typeof WorkspaceIdWorkloadRoute
  '/_error/forbidden-workspace': typeof ErrorForbiddenWorkspaceRoute
  '/error': typeof ErrorRouteWithChildren
  '/error/_error': typeof ErrorErrorRouteWithChildren
  '/$workspaceId/': typeof WorkspaceIdIndexLazyRoute
  '/$workspaceId/process/$processId': typeof WorkspaceIdProcessProcessIdRouteRouteWithChildren
  '/$workspaceId/party/$partyId': typeof WorkspaceIdPartyPartyIdRoute
  '/error/_error/forbidden-workspace': typeof ErrorErrorForbiddenWorkspaceRoute
  '/error/_error/no-workspace': typeof ErrorErrorNoWorkspaceRoute
  '/$workspaceId/process/$processId/attachments': typeof WorkspaceIdProcessProcessIdAttachmentsRoute
  '/$workspaceId/process/$processId/history': typeof WorkspaceIdProcessProcessIdHistoryRoute
  '/$workspaceId/process/$processId/notes': typeof WorkspaceIdProcessProcessIdNotesRoute
  '/$workspaceId/process/$processId/steps': typeof WorkspaceIdProcessProcessIdStepsRoute
  '/$workspaceId/process/$processId/timeline': typeof WorkspaceIdProcessProcessIdTimelineRoute
  '/$workspaceId/stream/$streamId/clustered': typeof WorkspaceIdStreamStreamIdClusteredRoute
  '/$workspaceId/stream/$streamId/portfolio': typeof WorkspaceIdStreamStreamIdPortfolioRoute
  '/$workspaceId/stream/$streamId/routine': typeof WorkspaceIdStreamStreamIdRoutineRoute
  '/$workspaceId/stream/$streamId/': typeof WorkspaceIdStreamStreamIdIndexRoute
  '/$workspaceId/process/$processId/gates-and-steps/$stepId': typeof WorkspaceIdProcessProcessIdGatesAndStepsStepIdRoute
  '/$workspaceId/process/$processId/gates-and-steps/': typeof WorkspaceIdProcessProcessIdGatesAndStepsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/goto'
    | '/onboarding'
    | '/$workspaceId/activities'
    | '/$workspaceId/data'
    | '/$workspaceId/streams'
    | '/$workspaceId/workload'
    | '/forbidden-workspace'
    | '/error'
    | '/$workspaceId'
    | '/$workspaceId/process/$processId'
    | '/$workspaceId/party/$partyId'
    | '/error/forbidden-workspace'
    | '/error/no-workspace'
    | '/$workspaceId/process/$processId/attachments'
    | '/$workspaceId/process/$processId/history'
    | '/$workspaceId/process/$processId/notes'
    | '/$workspaceId/process/$processId/steps'
    | '/$workspaceId/process/$processId/timeline'
    | '/$workspaceId/stream/$streamId/clustered'
    | '/$workspaceId/stream/$streamId/portfolio'
    | '/$workspaceId/stream/$streamId/routine'
    | '/$workspaceId/stream/$streamId'
    | '/$workspaceId/process/$processId/gates-and-steps/$stepId'
    | '/$workspaceId/process/$processId/gates-and-steps'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/goto'
    | '/onboarding'
    | '/$workspaceId/activities'
    | '/$workspaceId/data'
    | '/$workspaceId/streams'
    | '/$workspaceId/workload'
    | '/forbidden-workspace'
    | '/error'
    | '/$workspaceId'
    | '/$workspaceId/process/$processId'
    | '/$workspaceId/party/$partyId'
    | '/error/forbidden-workspace'
    | '/error/no-workspace'
    | '/$workspaceId/process/$processId/attachments'
    | '/$workspaceId/process/$processId/history'
    | '/$workspaceId/process/$processId/notes'
    | '/$workspaceId/process/$processId/steps'
    | '/$workspaceId/process/$processId/timeline'
    | '/$workspaceId/stream/$streamId/clustered'
    | '/$workspaceId/stream/$streamId/portfolio'
    | '/$workspaceId/stream/$streamId/routine'
    | '/$workspaceId/stream/$streamId'
    | '/$workspaceId/process/$processId/gates-and-steps/$stepId'
    | '/$workspaceId/process/$processId/gates-and-steps'
  id:
    | '__root__'
    | '/'
    | '/goto'
    | '/onboarding'
    | '/$workspaceId/activities'
    | '/$workspaceId/data'
    | '/$workspaceId/streams'
    | '/$workspaceId/workload'
    | '/_error/forbidden-workspace'
    | '/error'
    | '/error/_error'
    | '/$workspaceId/'
    | '/$workspaceId/process/$processId'
    | '/$workspaceId/party/$partyId'
    | '/error/_error/forbidden-workspace'
    | '/error/_error/no-workspace'
    | '/$workspaceId/process/$processId/attachments'
    | '/$workspaceId/process/$processId/history'
    | '/$workspaceId/process/$processId/notes'
    | '/$workspaceId/process/$processId/steps'
    | '/$workspaceId/process/$processId/timeline'
    | '/$workspaceId/stream/$streamId/clustered'
    | '/$workspaceId/stream/$streamId/portfolio'
    | '/$workspaceId/stream/$streamId/routine'
    | '/$workspaceId/stream/$streamId/'
    | '/$workspaceId/process/$processId/gates-and-steps/$stepId'
    | '/$workspaceId/process/$processId/gates-and-steps/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  GotoRoute: typeof GotoRoute
  OnboardingRoute: typeof OnboardingRoute
  WorkspaceIdActivitiesRoute: typeof WorkspaceIdActivitiesRoute
  WorkspaceIdDataRoute: typeof WorkspaceIdDataRoute
  WorkspaceIdStreamsRoute: typeof WorkspaceIdStreamsRoute
  WorkspaceIdWorkloadRoute: typeof WorkspaceIdWorkloadRoute
  ErrorForbiddenWorkspaceRoute: typeof ErrorForbiddenWorkspaceRoute
  ErrorRoute: typeof ErrorRouteWithChildren
  WorkspaceIdIndexLazyRoute: typeof WorkspaceIdIndexLazyRoute
  WorkspaceIdProcessProcessIdRouteRoute: typeof WorkspaceIdProcessProcessIdRouteRouteWithChildren
  WorkspaceIdPartyPartyIdRoute: typeof WorkspaceIdPartyPartyIdRoute
  WorkspaceIdStreamStreamIdClusteredRoute: typeof WorkspaceIdStreamStreamIdClusteredRoute
  WorkspaceIdStreamStreamIdPortfolioRoute: typeof WorkspaceIdStreamStreamIdPortfolioRoute
  WorkspaceIdStreamStreamIdRoutineRoute: typeof WorkspaceIdStreamStreamIdRoutineRoute
  WorkspaceIdStreamStreamIdIndexRoute: typeof WorkspaceIdStreamStreamIdIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  GotoRoute: GotoRoute,
  OnboardingRoute: OnboardingRoute,
  WorkspaceIdActivitiesRoute: WorkspaceIdActivitiesRoute,
  WorkspaceIdDataRoute: WorkspaceIdDataRoute,
  WorkspaceIdStreamsRoute: WorkspaceIdStreamsRoute,
  WorkspaceIdWorkloadRoute: WorkspaceIdWorkloadRoute,
  ErrorForbiddenWorkspaceRoute: ErrorForbiddenWorkspaceRoute,
  ErrorRoute: ErrorRouteWithChildren,
  WorkspaceIdIndexLazyRoute: WorkspaceIdIndexLazyRoute,
  WorkspaceIdProcessProcessIdRouteRoute:
    WorkspaceIdProcessProcessIdRouteRouteWithChildren,
  WorkspaceIdPartyPartyIdRoute: WorkspaceIdPartyPartyIdRoute,
  WorkspaceIdStreamStreamIdClusteredRoute:
    WorkspaceIdStreamStreamIdClusteredRoute,
  WorkspaceIdStreamStreamIdPortfolioRoute:
    WorkspaceIdStreamStreamIdPortfolioRoute,
  WorkspaceIdStreamStreamIdRoutineRoute: WorkspaceIdStreamStreamIdRoutineRoute,
  WorkspaceIdStreamStreamIdIndexRoute: WorkspaceIdStreamStreamIdIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/goto",
        "/onboarding",
        "/$workspaceId/activities",
        "/$workspaceId/data",
        "/$workspaceId/streams",
        "/$workspaceId/workload",
        "/_error/forbidden-workspace",
        "/error",
        "/$workspaceId/",
        "/$workspaceId/process/$processId",
        "/$workspaceId/party/$partyId",
        "/$workspaceId/stream/$streamId/clustered",
        "/$workspaceId/stream/$streamId/portfolio",
        "/$workspaceId/stream/$streamId/routine",
        "/$workspaceId/stream/$streamId/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/goto": {
      "filePath": "goto.tsx"
    },
    "/onboarding": {
      "filePath": "onboarding.tsx"
    },
    "/$workspaceId/activities": {
      "filePath": "$workspaceId/activities.tsx"
    },
    "/$workspaceId/data": {
      "filePath": "$workspaceId/data.tsx"
    },
    "/$workspaceId/streams": {
      "filePath": "$workspaceId/streams.tsx"
    },
    "/$workspaceId/workload": {
      "filePath": "$workspaceId/workload.tsx"
    },
    "/_error/forbidden-workspace": {
      "filePath": "_error/forbidden-workspace.tsx"
    },
    "/error": {
      "filePath": "error",
      "children": [
        "/error/_error"
      ]
    },
    "/error/_error": {
      "filePath": "error/_error.tsx",
      "parent": "/error",
      "children": [
        "/error/_error/forbidden-workspace",
        "/error/_error/no-workspace"
      ]
    },
    "/$workspaceId/": {
      "filePath": "$workspaceId/index.lazy.tsx"
    },
    "/$workspaceId/process/$processId": {
      "filePath": "$workspaceId/process/$processId/route.tsx",
      "children": [
        "/$workspaceId/process/$processId/attachments",
        "/$workspaceId/process/$processId/history",
        "/$workspaceId/process/$processId/notes",
        "/$workspaceId/process/$processId/steps",
        "/$workspaceId/process/$processId/timeline",
        "/$workspaceId/process/$processId/gates-and-steps/$stepId",
        "/$workspaceId/process/$processId/gates-and-steps/"
      ]
    },
    "/$workspaceId/party/$partyId": {
      "filePath": "$workspaceId/party/$partyId.tsx"
    },
    "/error/_error/forbidden-workspace": {
      "filePath": "error/_error.forbidden-workspace.tsx",
      "parent": "/error/_error"
    },
    "/error/_error/no-workspace": {
      "filePath": "error/_error.no-workspace.tsx",
      "parent": "/error/_error"
    },
    "/$workspaceId/process/$processId/attachments": {
      "filePath": "$workspaceId/process/$processId/attachments.tsx",
      "parent": "/$workspaceId/process/$processId"
    },
    "/$workspaceId/process/$processId/history": {
      "filePath": "$workspaceId/process/$processId/history.tsx",
      "parent": "/$workspaceId/process/$processId"
    },
    "/$workspaceId/process/$processId/notes": {
      "filePath": "$workspaceId/process/$processId/notes.tsx",
      "parent": "/$workspaceId/process/$processId"
    },
    "/$workspaceId/process/$processId/steps": {
      "filePath": "$workspaceId/process/$processId/steps.tsx",
      "parent": "/$workspaceId/process/$processId"
    },
    "/$workspaceId/process/$processId/timeline": {
      "filePath": "$workspaceId/process/$processId/timeline.tsx",
      "parent": "/$workspaceId/process/$processId"
    },
    "/$workspaceId/stream/$streamId/clustered": {
      "filePath": "$workspaceId/stream/$streamId/clustered.tsx"
    },
    "/$workspaceId/stream/$streamId/portfolio": {
      "filePath": "$workspaceId/stream/$streamId/portfolio.tsx"
    },
    "/$workspaceId/stream/$streamId/routine": {
      "filePath": "$workspaceId/stream/$streamId/routine.tsx"
    },
    "/$workspaceId/stream/$streamId/": {
      "filePath": "$workspaceId/stream/$streamId/index.tsx"
    },
    "/$workspaceId/process/$processId/gates-and-steps/$stepId": {
      "filePath": "$workspaceId/process/$processId/gates-and-steps/$stepId.tsx",
      "parent": "/$workspaceId/process/$processId"
    },
    "/$workspaceId/process/$processId/gates-and-steps/": {
      "filePath": "$workspaceId/process/$processId/gates-and-steps/index.tsx",
      "parent": "/$workspaceId/process/$processId"
    }
  }
}
ROUTE_MANIFEST_END */
