import React, { useEffect } from "react";
import { ControlProps } from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";

const CheckBoxControl = (props: ControlProps): JSX.Element => {
	const { errors, id, label, handleChange, path, data } = props;

	useEffect(() => {
		if (data === undefined) {
			handleChange(path, false);
		}
	}, [data, handleChange, id, path]);

	return (
		<div>
			<input
				type="checkbox"
				value={data}
				onChange={(ev) => handleChange(path, ev.target.checked)}
				placeholder={label}
				{...props}
			/>
			<label className="ms-2" htmlFor={`${id}-input`}>
				{label}
			</label>
			<span className="text-red-600">
				{typeof data !== "undefined" && errors}
			</span>
		</div>
	);
};

export default withJsonFormsControlProps(CheckBoxControl);
