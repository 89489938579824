import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFlagMutation } from "@metronome/api/useFlag";
import { useNoteMutation } from "@metronome/api/useNotes";
import { NoteType } from "@metronome/types/NoteType";
import type React from "react";
import { useState } from "react";
import { useIntl } from "react-intl";

import { ConfirmationModalForFlag } from "./ConfirmationModalForFlag";
import styles from "./flag.module.scss";

export const Flag: React.FC<{
	isFlagged: boolean;
	id: string;
	context: "step-instances" | "process-instances";
	parentId?: string;
}> = ({ isFlagged, id, context, parentId }) => {
	const intl = useIntl();
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [content, setContent] = useState<string>("");

	const updateFlag = useFlagMutation();
	const createNote = useNoteMutation();

	const onCloseConfirmation = (): void => {
		setShowConfirmation(false);
		setContent("");
	};

	const getNoteType = (flag: boolean): NoteType =>
		flag ? NoteType.enum.flagAdded : NoteType.enum.flagRemoved;

	const useHandleFlagConfirmation = (): void => {
		updateFlag.mutate({ isFlagged: !isFlagged, id, context, parentId });
	};

	const handleNoteUpdate = (): void => {
		const futureNoteType = getNoteType(isFlagged);

		createNote.mutate({ noteType: futureNoteType, content, id, context });
		onCloseConfirmation();
	};

	const message = !isFlagged
		? intl.formatMessage({
				id: "FLAG.MESSAGE",
			})
		: intl.formatMessage({
				id: "UNFLAG.MESSAGE",
			});

	return (
		<>
			{showConfirmation && (
				<ConfirmationModalForFlag
					show={Boolean(showConfirmation)}
					isFlagged={isFlagged}
					onClose={onCloseConfirmation}
					onUpdateFlag={useHandleFlagConfirmation}
					onCreateNote={handleNoteUpdate}
					message={message}
					content={content}
					onChange={(value: string) => setContent(value)}
				/>
			)}
			<button
				type="button"
				onClick={(e) => {
					e.stopPropagation();
					e.nativeEvent.stopImmediatePropagation();
					e.preventDefault();
					setShowConfirmation(true);
				}}
				className={styles.flag_button}
			>
				<FontAwesomeIcon
					className={isFlagged ? styles.flag_alert : styles.flag_standby}
					icon={isFlagged ? ["fas", "flag"] : ["far", "flag"]}
					fixedWidth
				/>
			</button>
		</>
	);
};

export default Flag;
