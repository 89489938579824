import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import * as Sentry from "@sentry/react";
// @ts-ignore
import Logo from "@metronome/assets/logotype-couleur.svg?react";
// @ts-ignore
import { loginRequest } from "@metronome/authConfig";

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const Login = (): JSX.Element => {
	const intl = useIntl();
	const [isLoading, setIsLoading] = useState(false);
	const { instance, inProgress, accounts } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	async function handleLogin(): Promise<void> {
		setIsLoading(true);
		/**
		 * loginRedirect must redirect to an msal enabled url
		 */
		if (!isAuthenticated && inProgress === InteractionStatus.None) {
			await instance.handleRedirectPromise();
			const accounts = instance.getAllAccounts();
			if (accounts.length === 0) {
				// No user signed in
				await instance
					.loginRedirect({
						...loginRequest,
					})
					.catch((e) => {
						console.error(e);
						Sentry.captureException(e);
					});
			}
		}
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (inProgress === InteractionStatus.None && !isAuthenticated) {
			instance.handleRedirectPromise().then(() => {
				if (accounts.length === 0) {
					instance.loginRedirect({
						...loginRequest,
					});
				}
			});
		}
	}, [inProgress, accounts.length]);

	return (
		<div className="m-4 bg-white rounded shadow-sm p-5 p-lg-15 flex flex-col items-center">
			<div
				className="flex flex-row justify-content-center items-center"
				style={{ maxWidth: "400px" }}
			>
				<div className="w-16 m-auto">
					<Logo />
				</div>
			</div>
			<p className="text-center font-semibold mt-2">
				{intl.formatMessage({ id: "AUTH.LOGIN_TAGLINE" })}
			</p>
			<h1 className="my-8 text-center text-2xl font-bold">
				{intl.formatMessage({
					id: isAuthenticated ? "AUTH.LOGIN_H1" : "AUTH.LOGIN_REDIRECT",
				})}
			</h1>
			<button
				className="w-56 text-center shadow-sm bg-slate-200 m-auto py-2 px-4 hover:bg-slate-300 transition-colors"
				type="button"
				disabled={inProgress !== InteractionStatus.None}
				onClick={async () => {
					await handleLogin();
				}}
			>
				{intl.formatMessage({ id: "AUTH.LOGIN_OIDC" })}
			</button>

			<div className="mt-12 text-center">
				<a href="mailto:contact@process-metronome.com">
					{intl.formatMessage({ id: "AUTH.LOGIN_HELP" })}
				</a>
				<br />
			</div>
		</div>
	);
};
