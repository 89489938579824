import { ProtocolMode } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import { useQueryClient } from "@tanstack/react-query";

export function useLogout(): () => Promise<void> {
	const { instance } = useMsal();
	const queryClient = useQueryClient();
	const workspaceId = useWorkspaceId();

	const isOIDC =
		instance.getConfiguration().auth.protocolMode === ProtocolMode.OIDC;

	const clearCache = (): void => {
		queryClient.removeQueries({ queryKey: ["self"] });
		queryClient.removeQueries({ queryKey: [workspaceId] });
	};

	const logoutAAD = async (): Promise<void> => {
		await instance.handleRedirectPromise();
		await instance
			.logoutPopup({
				mainWindowRedirectUri: window.location.origin,
			})
			.then(clearCache);
	};

	const logoutOIDC = async (): Promise<void> => {
		await instance.handleRedirectPromise();

		await instance
			.logoutRedirect({
				postLogoutRedirectUri: window.location.origin,
			})
			.then(clearCache);
	};

	const logout = (): Promise<void> => {
		if (isOIDC) return logoutOIDC();
		return logoutAAD();
	};

	return logout;
}
