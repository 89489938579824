import { toAbsoluteUrl } from "@metronome/utils/assetHelpers";
import type React from "react";

const KNOWN_LOGO = ["sellsy", "sap"];
type NameOrLogoProps = {
	name: string;
};
export const NameOrLogo: React.FC<NameOrLogoProps> = ({ name }) => {
	if (KNOWN_LOGO.includes(name?.toLocaleLowerCase())) {
		return (
			<img
				className="object-contain max-h-5"
				src={toAbsoluteUrl(`/media/${name.toLocaleLowerCase()}.png`)}
				alt={name}
			/>
		);
	}
	return <strong>{name}</strong>;
};
