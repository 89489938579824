import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	CollapsibleContent,
	CollapsibleRoot,
	CollapsibleTrigger,
} from "@metronome/components/Collapsible";
import type { IProcessStages } from "@metronome/types/ProcessStages";
import { EResolution } from "@metronome/types/Resolution";
import clsx from "clsx";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { StepOrStepsGroup } from "../StepOrStepsGroup";
import styles from "./stageList.module.scss";

type StepsProps = {
	stage: IProcessStages;
	index: number;
	firstOpenStageId?: string;
	firstOpenStepGroup?: string;
	processInstanceId?: string;
};

export const StageList: React.FC<StepsProps> = ({
	stage,
	index,
	firstOpenStageId,
	firstOpenStepGroup,
	processInstanceId,
}) => {
	const intl = useIntl();
	const [open, setOpen] = useState(stage.id === firstOpenStageId);

	if (!stage?.steps?.length) return null;

	const someStepsNotDone = stage.steps.some((stepGroup) =>
		stepGroup.stepInstances.some(
			(step) => step.resolution !== EResolution.enum.done,
		),
	);

	return (
		<CollapsibleRoot open={open} onOpenChange={setOpen} key={stage.id}>
			<CollapsibleTrigger asChild>
				<div
					className={`flex flex-row items-center justify-between ps-4 pe-4 pt-2 pb-2 ${styles.trigger}`}
				>
					<span
						className={clsx({
							[styles.stageText]: true,
							[styles.completed]: !someStepsNotDone,
							truncate: true,
						})}
					>
						<FormattedMessage id="STAGE" />
						{` ${index} : `}
						{stage.name}
						{` (${stage.steps.length} ${intl.formatMessage({
							id: stage.steps.length > 1 ? "STEPS" : "STEP",
						})})`}
					</span>
					<FontAwesomeIcon
						icon={["fas", open ? "chevron-up" : "chevron-down"]}
					/>
				</div>
			</CollapsibleTrigger>
			<CollapsibleContent>
				<div className="flex flex-col gap-2">
					<StepOrStepsGroup
						key={stage.id}
						stage={stage}
						firstOpenStepGroup={firstOpenStepGroup}
						processInstanceId={processInstanceId}
					/>
				</div>
			</CollapsibleContent>
		</CollapsibleRoot>
	);
};
