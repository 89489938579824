import { LockClosedIcon } from "@radix-ui/react-icons";
import { FormattedMessage } from "react-intl";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "./ui/tooltip";

type BlockedIconProps = {
	tooltipMessage: string;
	side?: "left" | "right" | "top" | "bottom";
};

export const BlockedIcon: React.FC<BlockedIconProps> = ({
	tooltipMessage,
	side = "top",
}) => {
	return (
		<TooltipProvider delayDuration={0}>
			<Tooltip>
				<TooltipTrigger>
					<span className="relative z-50">
						<LockClosedIcon className="inline me-1 mb-1 hover:text-orange-700" />
					</span>
				</TooltipTrigger>
				<TooltipContent side={side}>
					<p className="font-bold">
						<FormattedMessage id={tooltipMessage} />
					</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
};
