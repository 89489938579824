import { z } from "zod";

export const IParty = z.object({
	id: z.string().min(1),
	email: z.string().optional(),
	firstName: z.string().min(1),
	lastName: z.string().min(1),
	userId: z.string().optional(),
	profilePictureUri: z.string().optional(),
});

export type IParty = z.infer<typeof IParty>;

const WorkSchedule = z.object({
	name: z.string(),
	resourceId: z.string(),
	resourceName: z.string(),
});

const PartyAbsence = z.object({
	reason: z.string(),
	from: z.string(),
	to: z.string(),
});

export const PartyAvailability = z.object({
	isAvailable: z.boolean(),
	applicableSchedules: z.array(WorkSchedule),
	plannedAbsences: z.array(PartyAbsence),
});

export type PartyAvailability = z.infer<typeof PartyAvailability>;