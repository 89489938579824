import useNotes, { type BaseParams } from "@metronome/api/useNotes";
import useSelf from "@metronome/api/useSelf";
import { NotesTemplate } from "./NotesTemplate";

export const NotesTab: React.FC<{
	context: BaseParams["context"];
	contextId: BaseParams["contextId"];
}> = ({ context, contextId }) => {
	const { data: user } = useSelf();
	const { data: notes } = useNotes(context, contextId);

	if (!notes) return null;

	return (
		<div className="flex justify-center items-center">
			<NotesTemplate
				notes={notes}
				userId={user?.id || ""}
				context={context}
				contextId={contextId}
			/>
		</div>
	);
};
