import React from "react";
import { IFormDefinition } from "@metronome/types/FormDefinition";
import { ControlElement } from "@jsonforms/core";

import { FormAnswer } from "./FormAnswer";

export const FormAnswers: React.FC<{
	form: IFormDefinition;
}> = ({ form }) => {
	const propertyKeys = form?.uiSchema?.elements.map((ele) =>
		(ele as ControlElement).scope.slice(13),
	);

	const answers = form.answers.map((a) => (
		<FormAnswer
			form={form}
			answer={a}
			propertyKeys={propertyKeys}
			key={`form-answer-${form.id}`}
		/>
	));

	if (form.answers.length) return <div>{answers}</div>;
	return null;
};
