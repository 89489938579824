import { UpdateIcon } from "@radix-ui/react-icons";
import { XTimeIcon } from "./XTimeIcon/XTimeIcon";

type RecurrenceProps = {
	recurrence: string;
};

export const RecurrenceIcon: React.FC<RecurrenceProps> = ({ recurrence }) => {
	switch (recurrence) {
		case "Portfolio":
		case "No Recurrence":
			return <XTimeIcon times="1" />;

		default:
			return <UpdateIcon />;
	}
};
