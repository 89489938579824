import { z } from "zod";

export const IModelReference = z.object({
	id: z.string().min(1),
	name: z.string().min(1),
});

export type IModelReference = z.infer<typeof IModelReference>;

export const IRankedModelReference = z.object({
	id: z.string().min(1),
	name: z.string().min(1),
	rank: z.number(),
});

export type IRankedModelReference = z.infer<typeof IRankedModelReference>;

export const IPartyModelReference = z.object({
	id: z.string().min(1),
	name: z.string().min(1),
	firstName: z.string().min(1),
	lastName: z.string().min(1),
	profilePictureUri: z.string().optional(),
});

export type IPartyModelReference = z.infer<typeof IPartyModelReference>;
