import highlightWords from "highlight-words";

export const HighlightWords = ({
	content = "",
	query,
}: {
	content: string | number;
	query: string;
}): JSX.Element => {
	const chunks = highlightWords({
		text: String(content),
		query,
	});
	return (
		<>
			{chunks.map(({ text, match, key }) =>
				match ? <mark key={key}>{text}</mark> : <span key={key}>{text}</span>,
			)}
		</>
	);
};
