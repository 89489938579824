import { createFileRoute } from "@tanstack/react-router";
import * as v from "valibot";

const OnboardingPageParams = v.object({
	from: v.optional(v.string()),
});

export const Route = createFileRoute("/onboarding")({
	validateSearch: (searchParams) => v.parse(OnboardingPageParams, searchParams),
});
