import React from "react";
import { ControlProps } from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { InputError } from "./InputError";
import { InputInstructions } from "./InputInstructions";
import { Label } from "../ui/label";
import { Input } from "../ui/input";

const DateControl = (props: ControlProps): JSX.Element => {
	const { errors, id, label, handleChange, path, data, required, schema } =
		props;
	return (
		<div className="has-validation mb-3">
			<div className="grid w-full max-w-sm items-center gap-1.5">
				<Label htmlFor={`${id}-input`}>
					{label} {!!required && "*"}
				</Label>
				<Input
					type="date"
					onChange={(ev) => handleChange(path, ev.target.value)}
					placeholder={label}
					value={data}
					{...props}
					id={`${id}-input`}
				/>
				<InputInstructions format={schema.format} />
			</div>
			<span className="text-red-600">
				{!!errors && !!data && <InputError error={errors} />}
			</span>
		</div>
	);
};

export default withJsonFormsControlProps(DateControl);
