import type { ColumnFiltersState, FilterFn } from "@tanstack/react-table";

import { rankItem } from "@tanstack/match-sorter-utils";
import type { IFilters } from "@metronome/types/StepInstance";
import {
	LABELS,
	NAME,
	PROCESS_STREAM_NAME,
} from "@metronome/constants/stepInstanceColumns";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
	// Rank the item
	const itemRank = rankItem(row.getValue(columnId), value);
	// Store the ranking info
	addMeta(itemRank);
	// Return if the item should be filtered in/out
	return itemRank.passed;
};

export const updateColumnFilters = (
	columnFilters: ColumnFiltersState,
	filters?: IFilters,
): ColumnFiltersState => {
	const updatedColumnFilters = columnFilters.map((filter) => {
		// Better using a switch
		if (filter.id === PROCESS_STREAM_NAME) {
			const needReplace = filters?.processStreams.find(
				(ps) => ps.name === filter.value,
			);
			if (needReplace) {
				return {
					...filter,
					value: needReplace.id,
				};
			}
		}
		if (filter.id === NAME) {
			const needReplace = filters?.stepTemplates.find(
				(st) => st.name === filter.value,
			);
			if (needReplace) {
				return {
					...filter,
					value: needReplace.ids,
				};
			}
		}
		if (filter.id === LABELS) {
			const needReplace = filters?.labels.find((l) => l.name === filter.value);
			if (needReplace) {
				return {
					...filter,
					value: needReplace.id,
				};
			}
		}
		return filter;
	});
	return updatedColumnFilters;
};
