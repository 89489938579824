import React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./dropdown.module.scss";

export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

export const DropdownMenuContent = React.forwardRef<
	HTMLDivElement,
	DropdownMenuPrimitive.DropdownMenuContentProps
>(({ children, ...props }, forwardedRef) => (
	<DropdownMenuPrimitive.Portal>
		<DropdownMenuPrimitive.Content
			{...props}
			ref={forwardedRef}
			className={styles.dropdown_content}
		>
			{children}
			<DropdownMenuPrimitive.Arrow className={styles.dropdownMenuArrow} />
		</DropdownMenuPrimitive.Content>
	</DropdownMenuPrimitive.Portal>
));

DropdownMenuContent.displayName = "DropdownMenuContent";

export const DropdownMenuLabel = DropdownMenuPrimitive.Label;
export const DropdownMenuItem = DropdownMenuPrimitive.Item;
export const DropdownMenuGroup = DropdownMenuPrimitive.Group;

export const DropdownMenuCheckboxItem = React.forwardRef<
	HTMLDivElement,
	DropdownMenuPrimitive.DropdownMenuCheckboxItemProps
>(({ children, ...props }, forwardedRef) => (
	<DropdownMenuPrimitive.CheckboxItem {...props} ref={forwardedRef}>
		{children}
		<DropdownMenuPrimitive.ItemIndicator>
			{props.checked === "indeterminate" && (
				<FontAwesomeIcon icon={["fas", "divide"]} />
			)}
			{props.checked === true && "-"}
		</DropdownMenuPrimitive.ItemIndicator>
	</DropdownMenuPrimitive.CheckboxItem>
));
DropdownMenuCheckboxItem.displayName = "DropdownMenuCheckboxItem";

export const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup;

export const DropdownMenuRadioItem = React.forwardRef<
	HTMLDivElement,
	DropdownMenuPrimitive.DropdownMenuRadioItemProps
>(({ children, ...props }, forwardedRef) => (
	<DropdownMenuPrimitive.RadioItem {...props} ref={forwardedRef}>
		{children}
		<DropdownMenuPrimitive.ItemIndicator>
			<FontAwesomeIcon icon={["fas", "circle-check"]} />
		</DropdownMenuPrimitive.ItemIndicator>
	</DropdownMenuPrimitive.RadioItem>
));

DropdownMenuRadioItem.displayName = "DropdownMenuRadioItem";

export const DropdownMenuSeparator = DropdownMenuPrimitive.Separator;
