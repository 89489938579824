import { useMetadataDefinitions } from "@metronome/api/useMetadataDefinitions";
import type { ILightBusinessDimensionNode } from "@metronome/types/BusinessDimension";
import type { Context } from "@metronome/types/Context";
import { FormattedMessage } from "react-intl";
import { EditableMetadataValues } from "../MetadataList";
import { DisplayMetadataValue } from "../MetadataList/DisplayMetadataValues";
import styles from "./featuredMetadata.module.scss";

type FeaturedMetadataProps = {
	contextId: string;
	context: Context;
	businessDimension: ILightBusinessDimensionNode;
};

export const FeaturedMetadata: React.FC<FeaturedMetadataProps> = ({
	contextId,
	context,
	businessDimension,
}) => {
	const { data: metaDataDefinitions, isLoading } = useMetadataDefinitions({
		nodeIds: [businessDimension.id],
	});

	if (isLoading) return null;

	if (metaDataDefinitions?.length === 0) return null;

	const featuredMetadataDefs = metaDataDefinitions?.filter(
		(def) => def.isFeatured,
	);

	if (
		featuredMetadataDefs?.length === 0 ||
		!Array.isArray(featuredMetadataDefs)
	)
		return null;

	return (
		<div>
			<strong>
				<FormattedMessage id="FEATURED_METADATA" />
			</strong>
			<div className={styles.gridContainerMetadata}>
				{featuredMetadataDefs.map((def) => {
					const values = businessDimension.metadataValues.find(
						(value) => value.definition.id === def.id,
					);
					if (def.canBeEdited)
						return (
							<EditableMetadataValues
								nodeId={businessDimension.id}
								key={def.id}
								contextId={contextId}
								context={context}
								definition={def}
								values={values}
							/>
						);

					if (values)
						return (
							<DisplayMetadataValue
								key={def.id}
								metadataValue={values}
								definition={def}
							/>
						);
				})}
			</div>
		</div>
	);
};
