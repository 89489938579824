import { EResolution } from "@metronome/types/Resolution";
import { EStepTimeliness } from "@metronome/types/Timeliness";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const ActivitiesSearchParams = z.object({
	search: z.string().optional(),
	stepId: z.string().optional(), // this optional step id is used for modal view
	page: z.number().optional().catch(1),
	pageSize: z.number().optional().catch(25),
	sortOrder: z.union([z.literal("asc"), z.literal("desc")]).optional(),
	sortBy: z.string().optional(),
	startFrom: z.string().optional(),
	startTo: z.string().optional(),
	deadlineFrom: z.string().optional(),
	deadlineTo: z.string().optional(),
	completedFrom: z.string().optional(),
	completedTo: z.string().optional(),
	timeliness: z.array(EStepTimeliness).optional(),
	resolution: z.array(EResolution).optional(),
	responsibleIds: z.array(z.string()).optional(),
	processIds: z.array(z.string()).optional(),
	labelIds: z.array(z.string()).optional(),
	stepTemplateIds: z.array(z.string()).optional(),
	nodeIds: z.array(z.string()).optional(),
	nodeTypeIds: z.array(z.string()).optional(),
});

export const Route = createFileRoute("/$workspaceId/activities")({
	validateSearch: (searchParams) => ActivitiesSearchParams.parse(searchParams),
});
