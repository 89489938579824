import { useUpdateResolution } from "@metronome/api/useStepInstance";
import { ConfirmationModal } from "@metronome/components/ConfirmationModal";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@metronome/components/Dropdown";
import type { EResolution } from "@metronome/types/Resolution";
import type { IStepInstance } from "@metronome/types/StepInstance";
import { getResolutionOptions } from "@metronome/utils/resolution";
import axios from "axios";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "@tanstack/react-router";
import styles from "./updateInstanceResolution.module.scss";
import { isProcessActive } from "@metronome/utils/isProcessActive";
import clsx from "clsx";
import {
	ChevronDownIcon,
	ChevronUpIcon,
	UpdateIcon,
} from "@radix-ui/react-icons";

export const UpdateInstanceResolution = ({
	stepInstance,
	useSyncUpdate,
}: {
	stepInstance: IStepInstance;
	useSyncUpdate?: boolean;
}): JSX.Element => {
	const intl = useIntl();
	const [open, setOpen] = useState<boolean>(false);
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [selectedAction, setSelectedAction] = useState<EResolution | undefined>(
		stepInstance.resolution,
	);

	const isFromAnActiveProcess = isProcessActive(
		stepInstance.processInstance.state,
	);

	const availableActions = getResolutionOptions(stepInstance);

	const { processId } = useParams({
		strict: false,
	});

	const {
		mutateAsync: updateResolution,
		mutate: updateResolutionSync,
		isPending,
	} = useUpdateResolution();

	const onActionSelected = async (action: EResolution): Promise<void> => {
		setSelectedAction(action);
		try {
			await updateResolution({
				resolution: action,
				isForced: false,
				processInstanceId: stepInstance.processInstance?.id ?? processId,
				stepInstanceId: stepInstance.id,
			});
		} catch (e: unknown) {
			if (axios.isAxiosError(e)) {
				if (e.response?.status === 400) return setShowConfirmation(true);
			}
		}
	};

	const handleConfirmation = (): void => {
		updateResolutionSync({
			resolution: selectedAction as EResolution,
			isForced: true,
			processInstanceId: stepInstance.processInstance?.id ?? processId,
			stepInstanceId: stepInstance.id,
		});
		setShowConfirmation(false);
	};

	return (
		<React.Fragment key={stepInstance.id}>
			<ConfirmationModal
				loading={isPending}
				show={showConfirmation}
				onClose={() => setShowConfirmation(false)}
				onConfirm={handleConfirmation}
				message={intl.formatMessage(
					{ id: "RESOLUTION.CONFIRM_MESSAGE" },
					{
						resolution: intl.formatMessage({
							id: `RESOLUTION.${selectedAction}`,
						}),
					},
				)}
			/>

			<DropdownMenu open={open} onOpenChange={setOpen}>
				<DropdownMenuTrigger
					disabled={!isFromAnActiveProcess}
					className={clsx({
						[styles.trigger]: true,
						"opacity-30": !isFromAnActiveProcess,
					})}
				>
					{!!isPending && <UpdateIcon className="inline-block animate-spin" />}
					{intl.formatMessage({
						id: `RESOLUTION.${stepInstance.type}.${
							useSyncUpdate ? selectedAction : stepInstance.resolution
						}`,
					})}
					{open ? (
						<ChevronUpIcon className="inline ms-1" />
					) : (
						<ChevronDownIcon className="inline ms-1" />
					)}
				</DropdownMenuTrigger>
				<DropdownMenuContent align="center" side="top">
					{availableActions.map((action) => (
						<DropdownMenuItem
							className={styles.item}
							key={action}
							onSelect={() => {
								onActionSelected(action);
							}}
						>
							{intl.formatMessage({
								id: `RESOLUTION.${stepInstance.type}.${action}`,
							})}
						</DropdownMenuItem>
					))}
				</DropdownMenuContent>
			</DropdownMenu>
		</React.Fragment>
	);
};
