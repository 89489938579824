import { Link2Icon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "sonner";
import { DoneIcon } from "./DoneIcon";
import { Tooltip } from "./Tooltip";

type LinkProps = {
	link: string;
	className?: string;
};
export const CopyLinkButton: React.FC<LinkProps> = ({
	link,
	className,
}): JSX.Element => {
	const intl = useIntl();
	const [visible, setVisible] = useState(false);

	const handleCopyClick = (): void => {
		if ("clipboard" in navigator) {
			navigator.clipboard
				.writeText(link)
				.then(() => {
					setVisible(true);
					const timeout = setTimeout(() => {
						setVisible(false);
						clearTimeout(timeout);
					}, 3000);
				})
				.catch(() => toast.error(intl.formatMessage({ id: "COPIED_FAIL" })));
		} else {
			toast.error(intl.formatMessage({ id: "COPIED_FAIL" }));
		}
	};

	return (
		<>
			<Tooltip content={intl.formatMessage({ id: "COPY_LINK" })}>
				<button
					type="button"
					onClick={handleCopyClick}
					className={clsx(
						"bg-transparent aspect-square h-4 w-4 pt-2 ps-1 -translate-x-2 opacity-0 transition-all",
						className,
					)}
				>
					{visible ? <DoneIcon /> : <Link2Icon />}
				</button>
			</Tooltip>
		</>
	);
};
