import React from "react";
import * as AccordionRadix from "@radix-ui/react-accordion";
import type {
	AccordionTriggerProps,
	AccordionContentProps,
} from "@radix-ui/react-accordion";

export const Accordion = AccordionRadix.Root;

export const AccordionItem = AccordionRadix.Item;

export const AccordionTrigger = React.forwardRef<
	HTMLButtonElement,
	AccordionTriggerProps
>(({ children, ...props }, forwardRef) => (
	<AccordionRadix.Header asChild>
		<AccordionRadix.Trigger ref={forwardRef} {...props} asChild>
			{children}
		</AccordionRadix.Trigger>
	</AccordionRadix.Header>
));

AccordionTrigger.displayName = "AccordionTrigger";

export const AccordionContent = React.forwardRef<
	HTMLDivElement,
	AccordionContentProps
>(({ children, ...props }, forwardRef) => (
	<AccordionRadix.Content ref={forwardRef} {...props} asChild>
		{children}
	</AccordionRadix.Content>
));

AccordionContent.displayName = "Accordion";

export default Accordion;
