import type React from "react";
import { useState } from "react";
import type { IFormDefinition } from "@metronome/types/FormDefinition";
import { FormattedMessage, useIntl } from "react-intl";
import {
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "@metronome/components/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useParams } from "@tanstack/react-router";
import { useAddFormAnswer } from "@metronome/api/useForms";

import { SectionMessage } from "@metronome/components/SectionMessage";
import { Button } from "@metronome/components/ui/button";
import { APPEARANCES } from "@metronome/constants/theme";

import { FormAnswers } from "./FormAnswers";
import { FormSubmitModal } from "./FormSubmitModal";

type FormData = { [name: string]: object } | undefined;

export const FormDefinition: React.FC<{
	form: IFormDefinition;
	isRequiredAndMissing?: boolean;
}> = ({ form, isRequiredAndMissing }) => {
	const { stepId: stepInstanceId } = useParams({
		strict: false,
	});

	const intl = useIntl();
	const [showFormModal, setShowFormModal] = useState<boolean>(false);

	const addFormAnswer = useAddFormAnswer(stepInstanceId as string, form.id);

	const closeModal = (): void => {
		setShowFormModal(false);
	};

	const handleSubmit = async (formData: FormData): Promise<void> => {
		if (formData) {
			try {
				await addFormAnswer.mutateAsync(formData);
				closeModal();
			} catch {
				setShowFormModal(true);
			}
		}
	};

	const handleAddAnswer = (e: React.MouseEvent<HTMLElement>): void => {
		e.preventDefault();
		setShowFormModal(true);
	};

	return (
		<AccordionItem value={form.jsonSchema.title ?? "accordion"}>
			{showFormModal && (
				<FormSubmitModal
					show={showFormModal}
					onClose={closeModal}
					onConfirm={handleSubmit}
					title={intl.formatMessage({ id: "FORM_MODAL.TITLE" })}
					form={form}
				/>
			)}
			{!!addFormAnswer.isError && (
				<SectionMessage
					onClose={addFormAnswer.reset}
					appearance={APPEARANCES.danger}
					title={intl.formatMessage({ id: "FORM.FAILURE" })}
				>
					{addFormAnswer.error.message}
				</SectionMessage>
			)}
			{!!addFormAnswer.isSuccess && (
				<SectionMessage
					onClose={addFormAnswer.reset}
					appearance={APPEARANCES.success}
					title={intl.formatMessage({ id: "FORM.SUCCESS" })}
				>
					{intl.formatMessage({ id: "FORM.SUCCESS.MESSAGE" })}
				</SectionMessage>
			)}
			<div className="relative mt-2 mb-4">
				<AccordionTrigger asChild>
					<div className="flex flex-row items-center max-w-lg	bg-white shadow-sm rounded p-1.5 gap-2 w-auto justify-between">
						<div className="flex flex-col">
							<div className="flex flex-row gap-2 items-center truncate">
								<FontAwesomeIcon icon={["far", "file-lines"]} />
								<span className="truncate">{`${form.jsonSchema.title}`}</span>
								{isRequiredAndMissing && (
									<span className="text-red-500">
										<FormattedMessage id="REQUIRED" />
									</span>
								)}
							</div>
							{form.jsonSchema?.description && (
								<span className="text-slate-500 ms-4">{`${form.jsonSchema?.description}`}</span>
							)}
						</div>
						<div className="flex flex-row flex-wrap gap-2">
							<Button size={"sm"} variant={"outline"} onClick={handleAddAnswer}>
								{intl.formatMessage({ id: "SUBMIT" })}
							</Button>
							{!!form.answers.length && (
								<Button variant={"secondary"} onClick={() => null}>
									{intl.formatMessage({ id: "FORM_BUTTON.VIEW" })}
									{` (${form.answers.length})`}
								</Button>
							)}
						</div>
					</div>
				</AccordionTrigger>
				<AccordionContent>
					<FormAnswers form={form} />
				</AccordionContent>
			</div>
		</AccordionItem>
	);
};
