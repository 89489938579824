import type {
	ISchedule,
	IStepInstance,
	StepInstanceCompletion,
} from "@metronome/types/StepInstance";
import type { ITimeline } from "@metronome/types/Timeline";
import { EStepTimeliness, ETimeliness } from "@metronome/types/Timeliness";

export function getTimelinessFromStepInstance(
	schedule: ISchedule,
	completion?: StepInstanceCompletion,
	type?: IStepInstance["type"],
): EStepTimeliness | undefined {
	if (!schedule.scheduleLowerBand) return;

	const timestampDeadline = Date.parse(
		type === "milestone"
			? schedule.scheduleLowerBand
			: schedule.scheduleUpperBand,
	);
	if (Number.isNaN(timestampDeadline)) return;

	if (completion?.performedAt) {
		if (completion.performedAt > timestampDeadline)
			return EStepTimeliness.enum.late;
		return EStepTimeliness.enum.onTrack;
	}

	if (schedule?.resolvedAt) {
		if (Date.parse(schedule.resolvedAt) > timestampDeadline)
			return EStepTimeliness.enum.late;
		return EStepTimeliness.enum.onTrack;
	}

	if (Date.now() > timestampDeadline) return EStepTimeliness.enum.late;

	return EStepTimeliness.enum.onTrack;
}

export function getTimelinessFromTimeline(timeline: ITimeline): ETimeliness {
	const now = Date.now();
	// if the lowerTimeBand is later than today we're onTrack no matter what
	if (Date.parse(timeline.lowerTimeBand) > now) {
		return ETimeliness.enum.onTrack;
	}

	if (timeline?.closedAt && timeline?.upperTimeBand) {
		// if it was closed later than the upperTimeBand we're late
		if (timeline.closedAt > timeline?.upperTimeBand) {
			return ETimeliness.enum.late;
		}
		// otherwise we were on track
		return ETimeliness.enum.onTrack;
	}

	if (!timeline?.closedAt && Date.parse(timeline.upperTimeBand) < now) {
		return ETimeliness.enum.late;
	}

	if (timeline?.startedAt) {
		if (now > Date.parse(timeline.lowerTimeBand)) return ETimeliness.enum.late;
		return ETimeliness.enum.onTrack;
	}
	return ETimeliness.enum.atRisk;
}
