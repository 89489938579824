import { FormattedMessage } from "react-intl";

export const NotFound: React.FC = () => (
	<div className="flex flex-col items-center justify-center mt-12">
		<h1 className="text-6xl font-bold text-gray-800">404</h1>
		<p className="mt-4 text-xl text-gray-600">
			<FormattedMessage id="PAGE_NOT_FOUND" />
		</p>
		<p className="mt-2 text-gray-500">
			<FormattedMessage id="PAGE_NOT_FOUND_DESC" />
		</p>
	</div>
);
